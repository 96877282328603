import React from "react";
import Image from "../../assets/plant-day-care.png";
import classes from "./ServiceCommon.module.css";
import { Link } from "react-router-dom";

const PlantDayCare = () => {
  return (
    <div className={classes.parentContainer}>
      <div className={classes.mediaContainer}>
        <img src={Image} alt="" />
      </div>
      <div className={classes.infoContainer}>
        <h3>Plant Day Care</h3>
        <p className={classes.startingPrice}>Starting from &#8377; 17</p>
        <p>
          The Chaperone team believes that plants require close supervision just
          like children and pets. So, why worry about your houseplants and
          garden when you are heading out for a tour or trip?.
        </p>

        <ul>
          <li>
            Drop your plants by or contact us for plant pick-up services .
          </li>
          <li>
            We will keep your plants well-hydrated and nourished until your
            return.
          </li>
          <li>
            You can pick them up once you are home or we can drop them off at
            your doorstep
          </li>
        </ul>

        <div className={classes.actions_btns}>
          <Link to="/plant_day_care_form">
            <button className={classes["book-your-slot_btn"]}>
              Book your slot now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PlantDayCare;
