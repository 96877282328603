// Footer.js
import React from "react";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link } from "react-router-dom";
import Logo from "../../assets/footer-logo.png";

const Footer = () => {
  return (
    <div
      className="footer-area"
      data-bg-image="/assets/images/footer/bg/1-1920x465.jpg"
    >
      <div className="footer-top section-space-top-100 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="footer-widget-item">
                <div className="footer-widget-logo">
                  <Link className="app-logo" to="/">
                    <img
                      className="logo-icon me-2"
                      width="150px"
                      height="60px"
                      src={Logo}
                      alt="logo"
                    />
                    <hr />
                  </Link>
                </div>
                <p className="footer-widget-desc">
                  Address: F-262, First Floor, Sushant Lok Phase-III, Sector-57,
                  Gurgaon, Haryana, India 122003
                </p>
                <p className="footer-widget-desc">Call: +919958287272</p>
                <p className>Email: chaperoneplants@gmail.com</p>
                <hr />

                <div className="social-link with-border">
                  <ul>
                    {/* WhatsApp  */}
                    <li>
                      <Link
                        to="https://wa.me/9958287272 "
                        target="link"
                        data-tippy="WhatsApp"
                        data-tippy-inertia="true"
                        data-tippy-animation="shift-away"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-theme="sharpborder"
                      >
                        <i className="wa wa-WhatsApp">
                          <WhatsAppIcon />
                        </i>
                      </Link>
                    </li>

                    {/*  Facebook   */}
                    <li>
                      <Link
                        to="https://www.facebook.com/ChaperonePlants/"
                        target="link"
                        data-tippy="Facebook"
                        data-tippy-inertia="true"
                        data-tippy-animation="shift-away"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-theme="sharpborder"
                      >
                        <i className="fa fa-facebook">
                          <FacebookIcon />
                        </i>
                      </Link>
                    </li>

                    {/* Instagram   */}
                    <li>
                      <Link
                        to="https://www.instagram.com/chaperoneplants/"
                        target="link"
                        data-tippy="Instagram"
                        data-tippy-inertia="true"
                        data-tippy-animation="shift-away"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-theme="sharpborder"
                      >
                        <i className="in in-instagram">
                          <InstagramIcon />
                        </i>
                      </Link>
                    </li>

                    {/* Youtube */}
                    <li>
                      <Link
                        to="https://www.youtube.com/@chaperoneplants9828"
                        target="link"
                        data-tippy="Twitter"
                        data-tippy-inertia="true"
                        data-tippy-animation="shift-away"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-theme="sharpborder"
                      >
                        <i className="yo yo-youtube">
                          <YouTubeIcon />
                        </i>
                      </Link>
                    </li>

                    {/* Twitter */}
                    <li>
                      <Link
                        to="https://twitter.com/ChaperonePlants"
                        target="link"
                        data-tippy="Twitter"
                        data-tippy-inertia="true"
                        data-tippy-animation="shift-away"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-theme="sharpborder"
                      >
                        <i className="tw rw-Twitter">
                          <TwitterIcon />
                        </i>
                      </Link>
                    </li>

                    {/* Linked */}
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/chaperone-plants/people/"
                        target="link"
                        data-tippy="LinkedIn"
                        data-tippy-inertia="true"
                        data-tippy-animation="shift-away"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-theme="sharpborder"
                      >
                        <i className="li li-LinkedIn">
                          <LinkedInIcon />
                        </i>
                      </Link>
                    </li>

                    {/* Repeat similar blocks for other social media links  */}
                  </ul>
                </div>
              </div>
            </div>

            {/* Quick Links  */}
            <div className="col-lg-2 col-md-4 pt-40">
              <div className="footer-widget-item">
                <h3 className="footer-widget-title">Quick Links</h3>
                <hr />
                <ul className="footer-widget-list-item">
                  <li>
                    <Link
                      to="/"
                      className="nav-link active"
                      aria-current="page"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about_us"
                      className="nav-link active"
                      aria-current="page"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/admin/login"
                      className="nav-link active"
                      aria-current="page"
                    >
                      Admin
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/contact-us"
                      className="nav-link active"
                      aria-current="page"
                    >
                      Contact Us
                    </Link>
                  </li> */}

                  {/* Repeat similar list items */}
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 pt-40">
              <div className="footer-widget-item">
                <h3 className="footer-widget-title">My Account</h3> <hr />
                <ul className="footer-widget-list-item">
                  {/* My Profile  */}
                  {/* <Link
                    to="/my_profile"
                    className="nav-link active"
                    aria-current="page"
                  >
                    My Profile
                  </Link> */}

                  {/* View Cart  */}
                  <Link
                    to="/cart"
                    className="nav-link active"
                    aria-current="page"
                  >
                    Cart
                  </Link>

                  {/* My Wishlist  */}

                  {/* <Link
                    to="/wishlist"
                    className="nav-link active"
                    aria-current="page"
                  >
                    Wishlist
                  </Link> */}

                  {/* Track My Order */}
                  {/* <Link
                    to="/track_order"
                    className="nav-link active"
                    aria-current="page"
                  >
                    Track My Order
                  </Link> */}

                  {/* Help */}
                  {/* <Link
                    to="/help"
                    className="nav-link active"
                    aria-current="page"
                  >
                    Help
                  </Link> */}
                  {/* Repeat similar list items */}
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 pt-40">
              <div className="footer-widget-item">
                <h3 className="footer-widget-title">Our Services</h3> <hr />
                <ul className="footer-widget-list-item">
                  <li>
                    <Link to="/hire_maali" className="nav-link active">
                      Book a Maali
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/rent_a_plant" className="nav-link active">
                      Rent-A-Plant
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/plant_day_care" className="nav-link active">
                      Plant Day Care
                    </Link>
                  </li>
                  {/* Repeat similar list items */}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 pt-40">
              <div className="footer-contact-info">
                <h3 className="footer-widget-title">Got Question? Call Us</h3>{" "}
                <hr />
                <i className="pe-7s-call"></i>
                {/* Service-Center number */}
                <button className="button">
                  <Link to="tel://+91-9958287272">+91-9958287272</Link>
                </button>
                <hr />
                <div className="address">
                  {/* <li> */}
                  {/* Service-Center Email Id  */}
                  <button className="button">
                    <Link to="mailto:chaperoneplants@gmail.com">Email</Link>
                  </button>
                  {/* </li> */}
                </div>
              </div>
              {/* <div className="payment-method">
                <Link to="#">
                  <img src="/assets/images/payment/1.png" alt="Payment Method" />
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="copyright">
                <span className="copyright-text">
                  © Copyright ©2023 All rights reserved |<FavoriteIcon />
                  {/* <i className="fa fa-heart text-danger"></i> */}
                  by
                  {/* Add webLink for home page later  */}
                  <Link to="Chaperone Plants" rel="noopener" target="_blank">
                    Chaperone Plants
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
