import React from 'react'

const Button = (props) => {
    
  return (
    <button className={props.className} id={props.id} onClick={props.onClick} style={{padding:props.padding,background:props.background,borderRadius:props.borderRadius,color:props.color}}>
        {props.label}
    </button>
  )
}

export default Button