import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "./utils/AdminHeader";
import AdminSideNav from "./utils/AdminSideNav";
import classes from "./Common.module.css";
import styles from "./Services.module.css";
import useApi from "../hooks/useapi";
import { modifyDate } from "../util/helper";

import { API_URL } from "../global-variables";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

const OrdersPage = () => {
  const [ordersVisible, setOrdersVisible] = useState("orders");
  const [ordersHeading, setOrdersHeading] = useState("Orders");

  const { data: orders, isLoading: isLoadingOrders } = useApi(
    `${API_URL}/admin/get-orders`,true
  );
  const { data: completedOrders, isLoading: isLoadingOrdersC } = useApi(
    `${API_URL}/admin/get-complete-orders`,true
  );
  const { data: pendingOrders, isLoading: isLoadingOrdersP } = useApi(
    `${API_URL}/admin/get-pending-orders`,true
  );

  if (!isLoadingOrders) {
    orders.orders.forEach((order) => {
      order.date = modifyDate(order.createdAt);
    });
  }

  const completedOrdersHandler = () => {
    setOrdersHeading("Completed Orders");
    setOrdersVisible("completedOrders");
  };
  const pendingOrdersHandler = () => {
    setOrdersHeading("Pending Orders");
    setOrdersVisible("pendingOrders");
  };

  let tableData = (
    <>
      {isLoadingOrders && <h3>Loading...</h3>}
      {orders.orders &&
        orders.orders.map((order) => (
          <tr>
            <td>{order._id}</td>
            <td>{order.date}</td>

            <td>{order.orderValue}</td>
            <td>{order.status}</td>
            <td>
              <Link to={`/admin/orders/${order._id}`}>
                <button type="button" className="btn btn-success">
                  View Order
                </button>
              </Link>
            </td>
          </tr>
        ))}
    </>
  );

  if (ordersVisible === "completedOrders") {
    tableData = (
      <>
        {completedOrders.orders.length === 0 && (
          <h3 style={{ textAlign: "center" }}>No orders are completed</h3>
        )}
        {isLoadingOrdersC && <h3>Loading...</h3>}
        {completedOrders.orders &&
          completedOrders.orders.map((order) => (
            <tr>
              <td>{order._id}</td>
              <td>{order.date}</td>

              <td>{order.orderValue}</td>
              <td>{order.status}</td>
              <td>
                <Link to={`/admin/orders/${order._id}`}>
                  <button type="button" className="btn btn-success">
                    View Order
                  </button>
                </Link>
              </td>
            </tr>
          ))}
      </>
    );
  }

  if (ordersVisible === "pendingOrders") {
    tableData = (
      <>
        {pendingOrders.orders.length === 0 && (
          <h3 style={{ textAlign: "center" }}>No orders are completed</h3>
        )}
        {isLoadingOrdersP && <h3>Loading...</h3>}
        {pendingOrders.orders &&
          pendingOrders.orders.map((order) => (
            <tr>
              <td>{order._id}</td>
              <td>{order.date}</td>

              <td>{order.orderValue}</td>
              <td>{order.status}</td>
              <td>
                <Link to={`/admin/orders/${order._id}`}>
                  <button type="button" className="btn btn-success">
                    View Order
                  </button>
                </Link>
              </td>
            </tr>
          ))}
      </>
    );
  }
  return (
    <AdminPanelWrapper>
      <div className={styles.main_container}>
        <div
          className="d-flex justify-content-around mt-3
          "
        >
          <div
            className={`${classes.box} ${classes.box2}`}
            onClick={completedOrdersHandler}
          >
            <div className={classes.text}>
              <h2 className={classes["topic-heading"]}>150</h2>
              <h2 className={classes.topic}>Completed Orders</h2>
            </div>

            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210185030/14.png"
              alt="likes"
            />
          </div>

          <div
            className={`${classes.box} ${classes.box3}`}
            onClick={pendingOrdersHandler}
          >
            <div className={classes.text}>
              <h2 className={classes["topic-heading"]}>320</h2>
              <h2 className={classes.topic}>Pending Orders</h2>
            </div>

            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210184645/Untitled-design-(32).png"
              alt="comments"
            />
          </div>
        </div>
        <div className={classes["report-container"]}>
          <div className={classes["report-header"]}>
            <h1 className={classes["recent-Articles"]}>{ordersHeading}</h1>
          </div>

          <div className={classes.services_data_container}>
            <table>
              <tr>
                <th>Order Id</th>
                <th>Order Date</th>
                <th>Order Value</th>
                <th> Status</th>
              </tr>

              {tableData}
            </table>
          </div>
        </div>
      </div>
    </AdminPanelWrapper>
  );
};

export default OrdersPage;
