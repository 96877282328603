import React, { useState, useEffect } from "react";
import classes from "./MaaliForm.module.css";
import {
  postApiRequest,
  changeDateFormat,
  getNextDate,
} from "../../util/helper";
import { useNavigate } from "react-router-dom";
import useGst from "../../hooks/useGst";

import { API_URL } from "../../global-variables";
import ErrorModal from "../../components/utils/UI/ErrorModal";

const PlantDayCareForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    quantity: "",
    size: "",
    date: "",
    time: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pincode: "",
    noOfDays: "",
  });

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalNoOfDays, setTotalNoOfDays] = useState(1);
  const [showValidationMessage, setShowValidationMessage] = useState(null);
  const minDate = getNextDate();

  const [plants, setPlants] = useState([]);

  const onChangeHandler = (type, value) => {
    if (type === "phone") {
      setFormData((prev) => {
        return {
          ...prev,
          phone: value,
        };
      });
    }
    if (type === "name") {
      setFormData((prev) => {
        return {
          ...prev,
          name: value,
        };
      });
    }
    if (type === "quantity") {
      setFormData((prev) => {
        return {
          ...prev,
          quantity: value,
        };
      });
    }

    if (type === "noOfDays") {
      setTotalNoOfDays(value);
      setFormData((prev) => {
        return {
          ...prev,
          noOfDays: value,
        };
      });
    }

    if (type === "size") {
      setFormData((prev) => {
        return {
          ...prev,
          size: value,
        };
      });
    }
    if (type === "date") {
      setFormData((prev) => {
        return {
          ...prev,
          date: value,
        };
      });
    }
    if (type === "time") {
      setFormData((prev) => {
        return {
          ...prev,
          time: value,
        };
      });
    }
    if (type === "addressLine1") {
      setFormData((prev) => {
        return {
          ...prev,
          addressLine1: value,
        };
      });
    }

    if (type === "addressLine2") {
      setFormData((prev) => {
        return {
          ...prev,
          addressLine2: value,
        };
      });
    }
    if (type === "city") {
      setFormData((prev) => {
        return {
          ...prev,
          city: value,
        };
      });
    }

    if (type === "state") {
      setFormData((prev) => {
        return {
          ...prev,
          state: value,
        };
      });
    }

    if (type === "pincode") {
      setFormData((prev) => {
        return {
          ...prev,
          pincode: value,
        };
      });
    }
  };

  const addPlant = () => {
    let { quantity, size } = formData;
    console.log("quantity on adding plant", quantity);
    console.log(typeof quantity);
    quantity = +quantity;

    if (quantity < 1) {
      setShowValidationMessage("Quantity should be greater than one");
      return;
    }
    console.log(quantity, size);

    setTotalQuantity((prev) => prev + quantity);
    console.log("totalQuantity", totalQuantity);

    // calcuating total

    let total = totalAmount;

    if (size === "small") {
      total = total + quantity * 17;
    }
    if (size === "medium") {
      total = total + quantity * 37;
    }
    if (size === "large") {
      total = total + quantity * 57;
    }
    if (size === "x-large") {
      total = total + quantity * 77;
    }
    setTotalAmount(total);

    formData.quantity = "";
    formData.size = "";

    setPlants((prev) => {
      const arr = [...prev];
      arr.push({
        quantity,
        size,
      });
      return [...arr];
    });
    console.log(plants);
    console.log("quantity on adding plant at end", quantity);
  };

  const value = ((totalAmount / 100) * 18 + totalAmount) * totalNoOfDays;
  const { totalGSTAmount } = useGst(value);

  const subitForm = async (e) => {
    e.preventDefault();
    if (totalQuantity < 5) {
      console.log("totalQuantity", totalQuantity);
      // return alert("Please select atleast 5 plants");
      setShowValidationMessage("Please select atleast 5 plants");
      return;
    }

    if (totalNoOfDays < 5) {
      // alert("Number of days should be equal or greater than 5");
      // return;
      setShowValidationMessage(
        "Number of days should be equal or greater than 5"
      );
      return;
    }

    if (formData.phone.length < 10) {
      // alert("Please provide valid phone number");
      // return;
      setShowValidationMessage("Please provide valid phone number");
      return;
    }

    if (formData.pincode.length < 6) {
      // alert("Please provide valid pin code");
      // return;
      setShowValidationMessage("Please provide valid pin code");
      return;
    }

    const enteredTime = new Date(`1970-01-01T${formData.time}`);
    const minTime = new Date(`1970-01-01T10:00`);
    const maxTime = new Date(`1970-01-01T19:00`);

    if (enteredTime < minTime || enteredTime > maxTime) {
      // alert("Please provide time from 10:00 AM to 07:00 PM");
      // return;
      setShowValidationMessage("Please provide time from 10:00 AM to 07:00 PM");
      return;
    }

    let finalAmount = ((totalAmount / 100) * 18 + totalAmount) * totalNoOfDays;

    const data = {
      ...formData,
      totalvalue: finalAmount,
      plants: plants,
      
    };

    // if (!userId) {
    //   alert("You are not logged in, please login");
    //   navigate("/login");
    // }

    console.log(data);
    const response = await postApiRequest(
      `${API_URL}/services/post-plant-day-care-service`,
      data
    );

    console.log("...", response);

    if (response.plantDaycare) {
      navigate(`/service-booked/daycare/${finalAmount}`);
    }
  };

  const decreaseQty = (index) => {
    setTotalQuantity((prev) => prev - 1);
    console.log("totalQuantity", totalQuantity);
    let total = totalAmount;
    const plantsArr = [...plants];

    if (plantsArr[index].size === "small") {
      total = total - 17;
    }
    if (plantsArr[index].size === "medium") {
      total = total - 37;
    }
    if (plantsArr[index].size === "large") {
      total = total - 57;
    }
    if (plantsArr[index].size === "x-large") {
      total = total - 77;
    }
    setTotalAmount(total);

    console.log("plantsArr", plantsArr);
    if (plantsArr[index].quantity === 1) {
      plantsArr.splice(index, 1);
      setPlants(plantsArr);
      return;
    }
    plantsArr[index].quantity--;
    setPlants(plantsArr);
  };

  const increaseQty = (index) => {
    setTotalQuantity((prev) => prev + 1);
    console.log("totalQuantity", totalQuantity);
    let total = totalAmount;
    const plantsArr = [...plants];

    if (plantsArr[index].size === "small") {
      total = total + 17;
    }
    if (plantsArr[index].size === "medium") {
      total = total + 37;
    }
    if (plantsArr[index].size === "large") {
      total = total + 57;
    }
    if (plantsArr[index].size === "x-large") {
      total = total + 77;
    }
    setTotalAmount(total);
    plantsArr[index].quantity++;
    setPlants(plantsArr);
  };

  const closeMessageModal = () => {
    setShowValidationMessage(null);
  };

  return (
    <>
      <ErrorModal
        isOpen={!!showValidationMessage}
        message={showValidationMessage}
        onClose={closeMessageModal}
      />
      <div className={classes.container}>
        <form>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="size" className={classes.label}>
                Plant Size
              </label>
            </div>
            <div className={classes["col-75"]}>
              <select
                onChange={(e) => onChangeHandler("size", e.target.value)}
                className={classes.text}
                value={formData.size}
              >
                <option value="none">Select size</option>
                <option value="small">4 to 12 inch</option>

                <option value="medium">14 to 24 inch</option>
                <option value="large">26 to 40 inch</option>
                <option value="x-large">Greater than 40 inch</option>
              </select>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="quantity" className={classes.label}>
                Quantity
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="number"
                className={classes.text}
                id="quantity"
                name="quantity"
                value={formData.quantity}
                min={1}
                onChange={(e) => onChangeHandler("quantity", e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-end py-3">
            <button
              type="button"
              className="btn btn-success"
              onClick={addPlant}
            >
              Add Plant
            </button>
          </div>

          <div className={classes.plantSectionWrapper}>
            {" "}
            {plants.length !== 0 &&
              plants.map((plant, i) => (
                <div
                  key={i}
                  className={`d-flex justify-content-around align-items-center ${classes["plants-section"]}`}
                >
                  <h6>
                    <b>Size</b>: {plant.size}
                  </h6>
                  <h6>
                    <b>Quantity</b>: {plant.quantity}
                  </h6>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => increaseQty(i)}
                  >
                    +
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => decreaseQty(i)}
                  >
                    -
                  </button>
                </div>
              ))}
          </div>

          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="name2" className={classes.label}>
                Name
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="text"
                className={classes.text}
                id="name2"
                name="name2"
                onChange={(e) => onChangeHandler("name", e.target.value)}
              />
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="phone" className={classes.label}>
                Phone Number
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="number"
                className={classes.text}
                id="phone"
                name="phone"
                onChange={(e) => onChangeHandler("phone", e.target.value)}
              />
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="noOfDays" className={classes.label}>
                No of Days
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="number"
                className={classes.text}
                id="noOfDays"
                name="noOfDays"
                onChange={(e) => onChangeHandler("noOfDays", e.target.value)}
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="date" className={classes.label}>
                Schedule Date
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="date"
                className={classes.text}
                id="date"
                name="date"
                min={minDate}
                onChange={(e) => onChangeHandler("date", e.target.value)}
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="time" className={classes.label}>
                Schedule Time
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="time"
                className={classes.text}
                id="appt"
                name="appt"
                min="10:00"
                max="19:00"
                required
                onChange={(e) => onChangeHandler("time", e.target.value)}
              />
              <small>Choose time between 10:00 AM to 07:00 PM</small>
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="addressline1" className={classes.label}>
                Address Line 1
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="text"
                id="addressline1"
                name="addressline1"
                className={classes.text}
                placeholder="Address Line 1.."
                onChange={(e) =>
                  onChangeHandler("addressLine1", e.target.value)
                }
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="addressline2" className={classes.label}>
                Address Line 2
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="text"
                id="addressline2"
                name="addressline2"
                className={classes.text}
                placeholder="Address Line 2.."
                onChange={(e) =>
                  onChangeHandler("addressLine2", e.target.value)
                }
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="city" className={classes.label}>
                City
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="text"
                id="city"
                name="city"
                className={classes.text}
                placeholder="city.."
                onChange={(e) => onChangeHandler("city", e.target.value)}
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="state" className={classes.label}>
                State
              </label>
            </div>
            <div className={classes["col-75"]}>
              <select
                aria-label="Select State"
                className={classes.text}
                onChange={(e) => onChangeHandler("state", e.target.value)}
                required
              >
                <option value="none">Select State</option>

                <option value="Delhi">Delhi</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Haryana">Haryana</option>
              </select>
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="pin" className={classes.label}>
                Pincode
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="number"
                id="pin"
                name="pin"
                className={classes.text}
                placeholder="pin.."
                onChange={(e) => onChangeHandler("pincode", e.target.value)}
                required
              />
            </div>
          </div>

          {/* <div className={classes.row}>
          <h3>Total Amount: Rs {calculateTotalAmount()}</h3>
        </div> */}
          <div className={classes.row}>
            <input
              type="submit"
              value="Submit"
              className={classes["submit-btn"]}
              onClick={(e) => subitForm(e)}
            />
          </div>

          <div className="d-flex align-items-center justify-content-end py-3">
            <h6>
              {" "}
              <b>Per Day Cost (including GST): </b>
              <span style={{ color: "green", margin: "0 5px" }}>
                ₹ {(totalAmount / 100) * 18 + totalAmount}
              </span>
            </h6>
          </div>
          <div className="d-flex align-items-center justify-content-end py-3">
            <h5>
              {" "}
              <b>Total Price (including GST):</b>
              <span style={{ color: "green", margin: "0 5px" }}>
                ₹{totalGSTAmount}
              </span>
            </h5>
          </div>
        </form>
      </div>
    </>
  );
};

export default PlantDayCareForm;
