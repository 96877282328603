import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import AdminPanelWrapper from './utils/AdminPanelWrapper'
import { useParams, useNavigate } from "react-router-dom";
// import useApi from "../../axios/useapi";
import axios from "axios";
import { API_URL, API_URL_HOST } from "../global-variables";

import classes from "./Common.module.css";

const NurseryPotPage = () => {
  const nurseryId = useParams().nurseryId;
  const navigate = useNavigate();
    const ux = localStorage.getItem("ux");
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImage] = useState("");
  const [potFormData, setPotFormData] = useState({
    name: "",
    price: "",
    description: "",
    size: "",
    imageURL: "",
    category: "",
    nurseryId: "",
  });

  const plantFormHandler = (type, val) => {
    if (type === "size") {
      setPotFormData((prevState) => {
        return {
          ...prevState,
          size: val,
        };
      });
    }
    if (type === "name") {
      setPotFormData((prevState) => {
        return {
          ...prevState,
          name: val,
        };
      });
    }
    if (type === "price") {
      setPotFormData((prevState) => {
        return {
          ...prevState,
          price: val,
        };
      });
    }
    if (type === "description") {
      setPotFormData((prevState) => {
        return {
          ...prevState,
          description: val,
        };
      });
    }
    if (type === "category") {
      setPotFormData((prevState) => {
        return {
          ...prevState,
          category: val,
        };
      });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };


  // Adding new pot function

  const potAddSubmitFormHandler = () => {
    const data = { ...potFormData };
    data.image = imageFile;
    data.nurseryId = nurseryId;

    // APi calling
    const sendData = async () => {
      try {
                if (!ux) {
                  navigate("/admin/login");
                  return;
                }
        const url = `${API_URL}/admin/add-pot`;
        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": ux,
          },
        });
        console.log("[[", response);
        if (response.status !== 200) {
          alert("Something went wrong...:(");
        }
        // console.log("pott..", response);

        alert("Pot was successfully added.");
        window.location.reload();
      } catch (err) {
        console.log("There was an error while making api call", err);
        alert(err.message);
      }
    };
    sendData();
  };

  // fetching plants and nursery
  const [potList, setPotList] = useState([]);
  const [nurseryInfo, setNurseryInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPotsWithNurseryId = async () => {
      try {
        // fetching plants with nurseryId
                if (!ux) {
                  navigate("/admin/login");
                  return;
        }
         const headers = {
           Authorization: ux,
         };
        const response = await axios.get(
          `${API_URL}/admin/get-pots/${nurseryId}`,{headers}
        );
        // console.log("pot list", response);
        if (response.status !== 200) {
          alert("Something went wrong...:(");
        }
        setPotList(response.data.pots);

        // fetching info about nursery
                if (!ux) {
                  navigate("/admin/login");
                  return;
        }
        
        const response2 = await axios.get(
          `${API_URL}/admin/get-nursery/${nurseryId}`,{headers}
        );
        if (response2.status !== 200) {
          alert("Something went wrong...:(");
        }
        setNurseryInfo(response2.data);

        setIsLoading(false);
      } catch (err) {
        console.log("error occured...", err);
        alert(err.message);
        setIsLoading(false);
      }
    };
    getPotsWithNurseryId();
  }, [nurseryId]);

  // function for deleting a plant with nurseryId
  const deletePotHandler = (id) => {
            if (!ux) {
              navigate("/admin/login");
              return;
    }
      const headers = {
        Authorization: ux,
      };
    try {
      const response = axios
        .post(`${API_URL}/admin/delete-pot/${id}`,{headers})
        .then((res) => {
          console.log(res);
          window.location.reload();
        });
    } catch (err) {
      console.log("error occured...", err.message);
      alert(err.message);
    }
  };

  return (
 <AdminPanelWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "space-between",
          }}
        >
          {isLoading && <p>Loading...</p>}
          {!isLoading && (
            <React.Fragment>
              <div className={classes.nursery_heading_btn}>
                <h2>{nurseryInfo.nursery.name}</h2>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addplantform"
                >
                  Add Pot
                </button>
              </div>

              <div
                style={{
                  width: "90%",
                  margin: "0 auto",
                  marginTop: "30px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {potList.length === 0 && (
                  <h2 style={{ textAlign: "center" }}>
                    No pots in this nursery
                  </h2>
                )}
                {potList &&
                  potList.map((pot) => (
                    <div
                      className="card m-2 p-3"
                      key={pot._id}
                      style={{ width: "350px", maxHeight: "400px" }}
                    >
                      <img
                        src={`${API_URL_HOST}/${pot.imageUrl}`}
                        className="card-img-top"
                        width={200}
                        height={200}
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">{pot.name}</h5>
                        <p className="card-text">{pot.description}</p>
                        <div
                          className="d-flex justify-content-between p-2"
                          // style={{ border: "2px solid" }}
                        >
                          <button type="button" className="btn btn-info mx-2">
                            <Link to={`${pot._id}`}>Edit/View</Link>
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => deletePotHandler(pot._id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </React.Fragment>
          )}
        </div>

      {/* Modal for adding a pot to nursery */}
      <div
        className="modal fade modal-dialog-scrollable modal-fullscreen"
        id="addplantform"
        tabIndex="-1"
        aria-labelledby="addplantformLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addplantformLabel">
                Add Pot to Nursery
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className={classes["add-nursery-form"]}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    onChange={(e) => plantFormHandler("name", e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Choose an image
                  </label>
                  <input
                    type="file"
                    name="plant-image"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="form-control"
                    id="image"
                  />
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="preivew"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id="description"
                    rows="3"
                    onChange={(e) =>
                      plantFormHandler("description", e.target.value)
                    }
                  ></textarea>
                </div>

                <div className="mb-3">
                  <label htmlFor="price" className="form-label">
                    Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    onChange={(e) => plantFormHandler("price", e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="category" className="form-label">
                    Select category
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) =>
                      plantFormHandler("category", e.target.value)
                    }
                    value={potFormData.category}
                  >
                    <option>Select category</option>
                    <option value="Hannging Plants">Hanging Plants</option>
                    <option value="Aquatic Plants">Aquatic Plants</option>
                    <option value="Small Plants">Small Plants</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="size" className="form-label">
                    Select size
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => plantFormHandler("size", e.target.value)}
                    value={potFormData.size}
                  >
                    <option>Select size</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={potAddSubmitFormHandler}
              >
                Add Pot
              </button>
            </div>
          </div>
        </div>
      </div>
      </AdminPanelWrapper>
  );
};

export default NurseryPotPage;
