import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useSearchParams, Link } from "react-router-dom";
const PaymentSuccess = () => {
  const seachQuery = useSearchParams()[0];

  const referenceNum = seachQuery.get("reference");
  console.log("refernce", referenceNum);
  return (
    <Box boxShadow="dark-lg" p="6" rounded="md" bg="white">
      <VStack h="100vh" justifyContent={"center"}>
        <Heading textTransform={"uppercase"}> Order Successfull</Heading>

        <Text>Reference No.{referenceNum}</Text>
        <Link to="/">
          <button type="button" className="btn btn-success">
            Go Back To Home
          </button>
        </Link>
      </VStack>
    </Box>
  );
};

export default PaymentSuccess;
