import React from "react";
import { API_URL_HOST } from "../../global-variables";
import TrashIcon from "../../assets/delete.png";

import classes from "./CartItem.module.css";
const CartItem = (props) => {
  return (
    <div key={props.product.plantId._id} className={classes.cartItem}>
      <div className={classes.cartItemImgDiv}>
        <img
          src={`${API_URL_HOST}/${props.product.plantId.imageUrl}`}
          className={classes}
          alt="..."
        />
      </div>
      <div className={classes.infoDiv}>
        <div className={classes.headingDivParent}>
          <div className={classes.headingDiv}>
            <h5>{props.product.plantId.name}</h5>
            {/* <h5>Price: Rs {props.product.plantId.price} </h5> */}
          </div>
          {/* <div>
            <img src={TrashIcon} alt="trash" className={classes.trashIcon} />
          </div> */}
        </div>

        <div className={classes.infoDiv_detail_item}>
          <h6>Category:</h6>
          <h6>{props.product.plantId.category}</h6>
        </div>
        <div className={classes.infoDiv_detail_item}>
          <h6>Price:</h6>
          <h6>₹ {props.product.plantId.price}</h6>
        </div>
        <div className={classes.infoDiv_detail_item}>
          <h6>Size:</h6>
          <h6>{props.product.plantId.size} inch</h6>
        </div>
        <div className={classes.infoDiv_detail_item}>
          <h6>Available In:</h6>
          <h6>{props.product.plantId.availableIn}</h6>
        </div>
        <div className={classes.infoDiv_detail_item}>
          <h6>Total:</h6>
          <h6>₹ {props.product.quantity * props.product.plantId.price}</h6>
        </div>
        <div className={classes.infoDiv_detail_item}>
          <h6>Quantity:</h6>
          <div className={classes.quantityParent}>
            <div
              className={`btn-group ${classes.quantityDiv}`}
              role="group"
              aria-label="Basic mixed styles example"
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => props.onDelete(props.product.plantId._id)}
              >
                -
              </button>
              <button type="button" className="btn btn-light">
                {props.product.quantity}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => props.onAddItem(props.product)}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
