import React from "react";
import "./AdminFormStyle.css";
import { Link } from "react-router-dom";

function AdminPanelForm() {
  return (
    <div>
      <form
        className="Input"
        action=""
        method="post"
        encType="multipart/form-data"
      >
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <div className="input-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" required className="input" />
              </div>
              <div className="input-group">
                <label htmlFor="image">Image:</label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  required className="input"
                />
              </div>
              <div className="col">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email"  className="input"/>
              </div>
              <div className="input-group">
                <label htmlFor="password">Password:</label>
                <input type="password" id="password" name="password" required className="input" />
              </div>

              <div className="input-group">
                <label htmlFor="mobile">Contact Number:</label>
                <input type="number" id="mobile" name="mobile" required className="input" />
              </div>
            </div>

            <div className="col">
              <div className="input-group">
                <label htmlFor="city">City:</label>
                <input type="text" id="city" name="city" required  className="input" />
              </div>
              <div className="input-group">
                <label htmlFor="pincode">Pincode:</label>
                <input type="number" id="pincode" name="pincode" required className="input" />
              </div>
              <div className="input-group">
                <label htmlFor="state">State:</label>
                <input type="text" id="state" name="state" required className="input" />
              </div>
              <div className="input-group">
                <label htmlFor="locality">Locality:</label>
                <input type="text" id="locality" name="locality" required className="input" />
              </div>
              <div className="input-group">
                <label htmlFor="role">Role:</label>
                <input type="text" id="role" name="role" required className="input" />
              </div>
            </div>
          </div>
        </div>

        <div className="button-container">
          <button type="submit" className="submit-button button">
            Submit
          </button>
          <Link
            to="/admin_panel_form"
            className="list-group-item list-group-item-action"
          >
            <button type="button" className="close button">
              Close
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default AdminPanelForm;
