import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../global-variables";
import { postApiRequest } from "../../util/helper";
import classes from "./Registration.module.css";
import { updateLengthNonLoggedInUser } from "../../store/NonLoggedInUser";
import { updateLength } from "../../store/LoggedInUser";
import { useDispatch } from "react-redux";
import logo from "../../assets/logo.png";
import ErrorModal from "../utils/UI/ErrorModal";

const Login = () => {
  const dispatch = useDispatch();
  const phoneRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);

  const handleUserLogin = async (e) => {
    e.preventDefault();
    const phone = phoneRef.current.value;
    const password = passwordRef.current.value;
    const url = `${API_URL}/auth/login`;
    const previousLocation = localStorage.getItem("previousLocation");
    const cartId = localStorage.getItem("cartId");

    let emptyCart = cartId ? false : true;

    // handling case when an unlogged in user add items to cart and then move to /login route to login, in such as the emptyCart is set to true, but it should be

    if (previousLocation === "/cart" || previousLocation === "/") {
      emptyCart = true;
    }

    const data = { phone, password, cartId, emptyCart };
    // make an api call
    try {
      const response = await postApiRequest(url, data, setError);
      console.log(response);
      if (response.user === null) {
        setModalMessage("No user found");
      }
      if (response.user) {
        const cartLength = await postApiRequest(
          `${API_URL}/shop/get-cart-length`,
          {
            userId: response.user._id,
            cartId: null,
          }
        );
        console.log("cvvvv", cartLength.length);

        dispatch(updateLength(cartLength.length));

        // Store the userId in localStorage
        localStorage.setItem("userId", response.user._id);
        localStorage.removeItem("cartId");
        console.log("yeah here reached...");
        setModalMessage("User successfully logged...");
      }
    } catch (err) {
      console.log("error catched", err);
      setError(err.response.data.message);
    }
  };
  const closeErrorModal = () => {
    setError(null);
  };
  const closeMessageModal = () => {
    setModalMessage(null);
  };

  const onClickOk = () => {
    console.log("inside onclickon");
    const previousLocation = localStorage.getItem("previousLocation");
    if (previousLocation) {
      navigate(previousLocation);
    } else {
      navigate("/"); // Default redirect
    }
  };

  return (
    <>
      <ErrorModal
        isOpen={!!modalMessage}
        message={modalMessage}
        onClose={closeMessageModal}
        onClickOk={onClickOk}
      />
      <ErrorModal isOpen={!!error} message={error} onClose={closeErrorModal} />

      <div className={classes["login-container"]}>
        <form
          className={`${classes["login-form"]} shadow-lg p-3 mb-5 bg-body-tertiary rounded`}
          onSubmit={(e) => handleUserLogin(e)}
        >
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/">
              <img src={logo} alt="logo" width={250} />
            </Link>
          </div>
          <h2 className="d-flex justify-content-center align-items-center">
            User Login
          </h2>

          <div className="mb-3">
            <label for="phone" className="form-label">
              Phone
            </label>
            <input
              type="number"
              className="form-control"
              id="phone"
              ref={phoneRef}
              required
            />
          </div>
          <div className="mb-3">
            <label for="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="pin"
              ref={passwordRef}
              required
            />
          </div>

          <Link
            to="/signup"
            style={{ color: "blue", marginRight: "10px", fontWeight: "bold" }}
          >
            New User? Create a new account
          </Link>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default Login;
