import React, { useState } from "react";
import "./Blog.css";


const Blog = () => {
  const [cart, setCart] = useState([]);

  const addToCart = (plant) => {
    setCart([...cart, plant]);
  };

  const plantsData = [
    {
      id: 1,
      name: "Blog1",
      image: "/image2.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 2,
      name: "Blog2",
      image: "/image2.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 3,
      name: "Blog3",
      image: "/image2.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 4,
      name: "Blog4",
      image: "/image2.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 5,
      name: "Blog5",
      image: "/image2.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 6,
      name: "Blog6",
      image: "/image2.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
  ];

  const floweringData = [
    {
      id: 7,
      name: "FlowerBlog1",
      image: "/image1.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 8,
      name: "FlowerBlog2",
      image: "/image1.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 9,
      name: "FlowerBlog3",
      image: "/image1.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 10,
      name: "FlowerBlog4",
      image: "/image1.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 11,
      name: "FlowerBlog5",
      image: "/image1.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    {
      id: 12,
      name: "FlowerBlog6",
      image: "/image1.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste obcaecati aspernatur vel maxime voluptates possimus reprehenderit facere quasi iusto earum sint explicabo modi ullam, at mollitia molestias debitis nihil corrupti eum animi enim nemo!",
    },
    // ... Add more products here
  ];

  return (
    <div className="blog-container">
      <div className="Welcome-Blog">
        <div className="subscribe">
          <h4>Sign-In for NewsLetter</h4>
          <input type="email" placeholder="Enter your Email" />
          <button>Subscribe</button>
        </div>
        <br />
        <br />
        <h1>Welcome To Our Blog</h1>
      </div>
      <div>
        <h2>Gardening Basics</h2>
      </div>

      <div className="blog-list">
        {plantsData.map((plant) => (
          <div key={plant.id} className="plant">
            <img src={plant.image} alt={plant.name} />
            <h2>{plant.name}</h2>
            <p>{plant.description}</p>

            <button onClick={() => addToCart(plant)}>Read More</button>
          </div>
        ))}
      </div>

      <div>
        <h2>Flower Maintenance</h2>
      </div>

      <div className="plant-list">
        {floweringData.map((plant) => (
          <div key={plant.id} className="plant">
            <img src={plant.image} alt={plant.name} />
            <h2>{plant.name}</h2>
            <p>{plant.description}</p>

            <button onClick={() => addToCart(plant)}>Read More</button>
          </div>
        ))}
      </div>

    </div>
  );
};

export default Blog;
