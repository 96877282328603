import React from "react";
import Image from "../../assets/rent-plant.png";
import classes from "./ServiceCommon.module.css";
import { Link } from "react-router-dom";

const RentPlant = () => {
  return (
    <div className={classes.parentContainer}>
      <div className={classes.infoContainer}>
        <h3>Rent Plants at your Door step</h3>
        <p className={classes.startingPrice}>Starting from &#8377; 299</p>
        <p>
          Do you dream about a home garden or a touch of greenery indoors?
          Indoor plants for home spaces can make your dream come true. Do not
          believe us? Try renting a plant online from Chaperone to test your
          plant caregiving skills.
        </p>
        <p>Choose an indoor plant with a planter or potted plant online.</p>

        <ul>
          <li>Give us a call or leave us a message online.</li>
          <li>Choose a duration of the plant rental .</li>
          <li>Receive a detail of plant rental prices from us.</li>
          <li>Pick up the plant and planter or get it home delivered.</li>
        </ul>

        <div className={classes.actions_btns}>
          <Link to="/rent_plant_form">
            <button className={classes["book-your-slot_btn"]}>
              Book your slot now
            </button>
          </Link>

          <button className={classes["learn-more_btn"]}>
            Learn more&gt;&gt;
          </button>
        </div>
      </div>
      <div className={classes.mediaContainer}>
        <img src={Image} alt="" />
      </div>
    </div>
  );
};

export default RentPlant;
