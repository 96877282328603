import React from "react";
import classes from "../Common.module.css";
import styles from "../Services.module.css";
import AdminPanelWrapper from "../utils/AdminPanelWrapper";

const PlantServices = () => {
  return (
    <AdminPanelWrapper>
      <div className={styles.main_container}>
        <div className={classes["report-container"]}>
          <div className={classes["report-header"]}>
            <h1 className={classes["recent-Articles"]}>
              Recent Plants Ordered
            </h1>
            <button className={classes.view}>View All</button>
          </div>

          <div className={classes.services_data_container}>
            <table>
              <tr>
                <th>Order Date</th>
                <th>Name</th>
                <th>Plant Name</th>
                <th>Size</th>
                <th>Delivery Date</th>
                <th> Status</th>
                <th>Total</th>
                <th>Pincode</th>
              </tr>
              <tr>
                <td>10-08-23</td>
                <td>Manish</td>
                <td>Plant name1</td>
                <td>small</td>
                <td>15-08-23</td>
                <td>Completed</td>
                <td>1499</td>
                <td>20820</td>
              </tr>
              <tr>
                <td>10-08-23</td>
                <td>Manish</td>
                <td>Plant name1</td>
                <td>small</td>
                <td>15-08-23</td>
                <td>Completed</td>
                <td>1499</td>
                <td>20820</td>
              </tr>
              <tr>
                <td>10-08-23</td>
                <td>Manish</td>
                <td>Plant name1</td>
                <td>small</td>
                <td>15-08-23</td>
                <td>Completed</td>
                <td>1499</td>
                <td>20820</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </AdminPanelWrapper>
  );
};

export default PlantServices;
