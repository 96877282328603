import React,{useEffect} from "react";
import SinglePlant from "../../components/utils/EachPlantComp";
import useApi from '../../hooks/useapi';
import { Link } from "react-router-dom";
import "./BestSeller.css";
import { API_URL } from "../../global-variables";

import CarouselComponent from "../../components/CarouselComponent/CarouselComponent";
const BestSeller = () => {
  const url = `${API_URL}/shop/get-bestseller-plants`;
  const { data, isLoading } = useApi(url);

  if (!isLoading) {
    // console.log("newarr", data);
  }
  return (
    <React.Fragment>

      <div id="best-seller" className="r-wrapper desktop-view__best-sellers" style={{marginTop:'35px'}}>
        <div
          className=" innerWidth r-container media-query"
          style={{ width: "90%", margin: "0 auto" }}
        >
          <div className="flexColStart r-head">
            <h5 style={{ marginLeft: "55px" }}>
              <b>BEST SELLERS</b>
            </h5>
          </div>
          {data.plants &&
            (<div className="map-card">
              <CarouselComponent>
                {data.plants.map((card, i) => (
                  <SinglePlant data={card} key={card._id} />
                ))}
              </CarouselComponent>
            </div>)
          }
        </div>
      </div>
      {/* mobile view  */}
      <div className="mobile-view__best-sellers">
        {/* <h4 style={{ textAlign: "center", marginTop: "20px" }}>Best Sellers</h4> */}
        <h5 style={{ marginLeft: "55px" }}>
          <b>BEST SELLERS</b>
        </h5>
        <div
          className="d-flex justify-content-center align-items-center flex-wrap"
          style={{
            gap: "0px",
            width: "100%",
            margin: "0 auto",
            marginLeft: "12px",
          }}
        >
          {isLoading && <h4>Loading...</h4>}
          {data.plants &&
            data.plants.map((card, i) => <SinglePlant data={card} />)}
        </div>
      </div>

    </React.Fragment>
  );
};
export default BestSeller;







