import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

import axios from "axios";

import { API_URL, API_URL_HOST } from "../global-variables";

import classes from "./Common.module.css";
import useApi from "../hooks/useapi";

const NurseryPlantPage = () => {
  const nurseryId = useParams().nurseryId;
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImage] = useState("");
    const navigate = useNavigate();
    const ux = localStorage.getItem("ux");
  const [plantFormData, setPlantFormData] = useState({
    name: "",
    price: "",
    description: "",
    imageURL: "",
    category: "",
    nurseryId: "",
    size: "",
    availableIn: "",
  });

  const plantFormHandler = (type, val) => {
    if (type === "name") {
      setPlantFormData((prevState) => {
        return {
          ...prevState,
          name: val,
        };
      });
    }
    if (type === "price") {
      setPlantFormData((prevState) => {
        return {
          ...prevState,
          price: val,
        };
      });
    }
    if (type === "description") {
      setPlantFormData((prevState) => {
        return {
          ...prevState,
          description: val,
        };
      });
    }
    if (type === "category") {
      setPlantFormData((prevState) => {
        return {
          ...prevState,
          category: val,
        };
      });
    }
    if (type === "size") {
      setPlantFormData((prevState) => {
        return {
          ...prevState,
          size: val,
        };
      });
    }
    if (type === "availableIn") {
      setPlantFormData((prevState) => {
        return {
          ...prevState,
          availableIn: val,
        };
      });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  // Adding new plant function

  const plantAddSubmitFormHandler = () => {
    const data = { ...plantFormData };

    data.image = imageFile;
    data.nurseryId = nurseryId;

    // APi calling
    const sendData = async () => {
      try {
                if (!ux) {
                  navigate("/admin/login");
                  return;
                }
        const url = `${API_URL}/admin/add-plant`;
        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": ux,
          },
        });

        console.log("aaaaa", response);
        if (response.status !== 200) {
          alert("Something went wrong...:(");
        }

        alert("Plant was successfully added.");
        window.location.reload();
      } catch (err) {
        console.log("There was an error while making api call", err);
        alert(err.message);
      }
    };
    sendData();
  };

  // fetching plants and nursery

  // const [nurseryInfo, setNurseryInfo] = useState({});
  // const [isLoading, setIsLoading] = useState(true);

  const { data: nurseryPlants, isLoading } = useApi(
    `${API_URL}/admin/get-plants/${nurseryId}`,true
  );
  const { data: nurseryInfo, isLoading: isLoadingNursery } = useApi(
    `${API_URL}/admin/get-nursery/${nurseryId}`,true
  );
  console.log("nurseryInfo", nurseryInfo);

  // function for deleting a plant with nurseryId
  const deletePlantHandler = (id) => {
    try {
      if (!ux) {
        navigate("/admin/login");
        return;
      }
      const headers = {
        Authorization: ux,
      };
      const response = axios
        .post(`${API_URL}/admin/delete-plant/${id}`,{headers})
        .then((res) => {
          console.log("while deleting...", res);
          window.location.reload();
        });
    } catch (err) {
      console.log("error occured...", err.message);
      alert(err.message);
    }
  };

  return (
    <>
      <AdminPanelWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "space-between",
          }}
        >
          {isLoading && <p>Loading...</p>}
          {!isLoading && (
            <React.Fragment>
              <div className={classes.nursery_heading_btn}>
                {!isLoadingNursery && <h2>{nurseryInfo.nursery.name}</h2>}
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addplantform"
                >
                  Add Plant
                </button>
              </div>

              <div
                style={{
                  width: "90%",
                  margin: "0 auto",
                  marginTop: "30px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {nurseryPlants.plants.length === 0 && (
                  <h2>No plants in this nursery</h2>
                )}
                {nurseryPlants.plants.map((plant) => (
                  <div
                    className="card m-2 p-3"
                    key={plant._id}
                    style={{ width: "350px", maxHeight: "450px" }}
                  >
                    <img
                      src={`${API_URL_HOST}/${plant.imageUrl}`}
                      className="card-img-top"
                      width={200}
                      height={200}
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title">{plant.name}</h5>
                      <p
                        className="card-text"
                        style={{ height: "60px", overflow: "hidden" }}
                      >
                        {plant.description}
                      </p>
                      <div
                        className="d-flex justify-content-between p-2"
                        // style={{ border: "2px solid" }}
                      >
                        <button type="button" className="btn btn-info mx-2">
                          <Link to={`${plant._id}`}>Edit/View</Link>
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => deletePlantHandler(plant._id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
      </AdminPanelWrapper>
      {/* Modal for adding a plant to nursery */}
      <div
        className="modal fade modal-dialog-scrollable modal-fullscreen"
        id="addplantform"
        tabIndex="-1"
        aria-labelledby="addplantformLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addplantformLabel">
                Add Plant to Nursery
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className={classes["add-nursery-form"]}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    onChange={(e) => plantFormHandler("name", e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Choose an image
                  </label>
                  <input
                    type="file"
                    name="plant-image"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="form-control"
                    id="image"
                  />
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="preivew"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id="description"
                    rows="3"
                    onChange={(e) =>
                      plantFormHandler("description", e.target.value)
                    }
                  ></textarea>
                </div>

                <div className="mb-3">
                  <label htmlFor="price" className="form-label">
                    Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    onChange={(e) => plantFormHandler("price", e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="size" className="form-label">
                    Size
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="size"
                    onChange={(e) => plantFormHandler("size", e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="availableIn" className="form-label">
                    Available in
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) =>
                      plantFormHandler("availableIn", e.target.value)
                    }
                  >
                    <option>Select an option</option>
                    <option value="Nursery Bag">Nursery Bag</option>
                    <option value="Plastic Pot">Plastic Pot</option>
                    <option value="Cement Pot">Cement Pot</option>
                    <option value="Fiber Pot">Fiber Pot</option>
                    <option value="Ceramic Pot">Ceramic Pot</option>
                    <option value="Hanging Basket">Hanging Basket</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="category" className="form-label">
                    Select category
                  </label>
                  <select
                    className="form-select"
                    aria-label="Select category"
                    onChange={(e) =>
                      plantFormHandler("category", e.target.value)
                    }
                    value={plantFormData.category}
                  >
                    <option>Select category</option>
                    <option value="Hanging Plants">Hanging Plants</option>
                    <option value="Aquatic Plants">Aquatic Plants</option>
                    <option value="Indoor Plants">Indoor Plants</option>
                    <option value="Outdoor Plants">Outdoor Plants</option>
                    <option value="Monsoon Plants">Monsoon Plants</option>
                    <option value="Flowering Plants">Flowering Plants</option>
                    <option value="Fruit Plants">Fruit Plants</option>
                    <option value="Airpurifying Plants">Air Purifying</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={plantAddSubmitFormHandler}
              >
                Add Plant
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NurseryPlantPage;
