import React from "react";
import RentPlant from "../../components/Services/RentPlant";
import { Helmet } from "react-helmet";

const RentPlantPage = () => {
  return (
    <>
      <Helmet>
        <title>Rent Plants Online | Chaperone</title>
        <meta
          name="description"
          content="Rent plants online with convenient home delivery options. Choose from a
wide variety of plant from Chaperone:Choose your plant, rental duration, and delivery options"
        />
      </Helmet>
      <RentPlant />
    </>
  );
};

export default RentPlantPage;
