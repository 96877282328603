import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../../Admin Panal/utils/AdminHeader";
import AdminSideNav from "../../Admin Panal/utils/AdminSideNav";

import classes from "../Common.module.css";

const AdminPanelWrapper = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenuHandler = () => {
    setShowMenu((prev) => !prev);
  };
  return (
    <div className={classes["admin_panel-wrapper"]}>
      <AdminHeader onMenuToggle={toggleMenuHandler} />
      <div className={classes["main-container"]}>
        <AdminSideNav showMenu={showMenu} />
        {props.children}
      </div>
    </div>
  );
};

export default AdminPanelWrapper;
