import React, { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import classes from "./Common.module.css";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";
import axios from "axios";
import { postApiRequest } from "../util/helper";
import { Link } from "react-router-dom";
import { API_URL, API_URL_HOST } from "../global-variables";

const EditPlantPage = () => {
  const plantId = useParams().plantId;
    const navigate = useNavigate();
  console.log(plantId);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImage] = useState("");
  const [updatedPlant, setUpdatedPlant] = useState({
    name: "",
    price: "",
    description: "",
    category: "",
    size: "",
    availableIn: "",
  });

  const onChange = (type, val) => {
    if (type === "name") {
      setUpdatedPlant((prev) => {
        return {
          ...prev,
          name: val,
        };
      });
    }
    if (type === "price") {
      setUpdatedPlant((prev) => {
        return {
          ...prev,
          price: val,
        };
      });
    }
    if (type === "description") {
      setUpdatedPlant((prev) => {
        return {
          ...prev,
          description: val,
        };
      });
    }
    if (type === "category") {
      setUpdatedPlant((prev) => {
        return {
          ...prev,
          category: val,
        };
      });
    }
    if (type === "size") {
      setUpdatedPlant((prev) => {
        return {
          ...prev,
          size: val,
        };
      });
    }
    if (type === "availableIn") {
      setUpdatedPlant((prev) => {
        return {
          ...prev,
          availableIn: val,
        };
      });
    }
  };

    const ux = localStorage.getItem("ux");

  useEffect(() => {
    const getPlant = async () => {
      try {
                if (!ux) {
                  navigate("/admin/login");
                  return;
        }
         const headers = {
           Authorization: ux,
         };
        const response = await axios.get(
          `${API_URL}/admin/get-plant/${plantId}`,{headers}
        );
        console.log("-------", response.data.plant);
        setUpdatedPlant(response.data.plant);
        console.log("...........", updatedPlant);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };

    getPlant();
  }, [plantId]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    console.log(file);

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const updatePlantDetails = async () => {
    console.log("from function.......", imageFile);
    const data = {
      ...updatedPlant,
      plantId: plantId,
      image: imageFile,
    };

    try {
       if (!ux) {
         navigate("/admin/login");
         return;
      }

      const response = await axios.post(
        `${API_URL}/admin/edit-plant/${plantId}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization":ux
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AdminPanelWrapper>
      <div className={classes.main}>
        {!updatedPlant && <p>Hold on...Loading</p>}
        {updatedPlant && (
          <div
            // style={{ border: "2px solid" }}
            className="p-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded"
            key={updatedPlant._id}
          >
            <div className="d-flex justify-content-around">
              <div>
                <img
                  src={`${API_URL_HOST}/${updatedPlant.imageUrl}`}
                  alt=""
                  width={800}
                />
              </div>
              <div
                //   style={{ border: "2px solid" }}
                className="d-flex flex-column p-5 "
              >
                <div>
                  <label for="name">Name: </label>
                  <input
                    type="text"
                    id="name"
                    value={updatedPlant.name}
                    onChange={(e) => onChange("name", e.target.value)}
                  />

                  <p className="my-3"> Id: {updatedPlant._id}</p>
                  <p className="my-3">NurseryId: {updatedPlant.nurseryId}</p>

                  <label for="size">Size: </label>
                  <input
                    type="text"
                    id="size"
                    value={updatedPlant.size}
                    onChange={(e) => onChange("size", e.target.value)}
                  />

                  <label for="price">Price: </label>
                  <input
                    type="text"
                    id="price"
                    value={updatedPlant.price}
                    onChange={(e) => onChange("price", e.target.value)}
                  />

                  <label for="availableIn">Available In: </label>
                  <input
                    type="text"
                    id="availableIn"
                    onChange={(e) => onChange("availableIn", e.target.value)}
                    value={updatedPlant.availableIn}
                  />
                </div>

                <div>
                  <label for="category">Category: </label>
                  <input
                    type="text"
                    id="category"
                    onChange={(e) => onChange("category", e.target.value)}
                    value={updatedPlant.category}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Choose an image
                  </label>
                  <input
                    type="file"
                    name="plant-image"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="form-control"
                    id="image"
                  />
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="preivew"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  )}
                </div>
                <div>
                  <label for="description">Description: </label>
                  <textarea
                    rows="10"
                    cols="30"
                    id="description"
                    onChange={(e) => onChange("description", e.target.value)}
                    value={updatedPlant.description}
                  />
                </div>
                <div className="d-flex  justify-content-between my-4">
                  <button
                    type="button"
                    className="btn btn-success btn-lg"
                    onClick={updatePlantDetails}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AdminPanelWrapper>
  );
};

export default EditPlantPage;
