import React from "react";
import { useParams } from "react-router-dom";

import useApi from "../../hooks/useapi";
import { modifyDate } from "../../util/helper";
import { API_URL, API_URL_HOST } from "../../global-variables";

const OrderDetailsPageUser = () => {
  const orderId = useParams().orderId;

  const { data, isLoading } = useApi(
    `${API_URL}/shop/get-order-details/${orderId}`
  );
  if (!isLoading) {
    if (!isLoading) {
      data.order.date = modifyDate(data.order.createdAt);
      console.log(data.order.products);
    }
  }

  return (
    <section className=" d-flex  justify-content-around">
      <div className="col-lg-10 col-xl-8">
        {isLoading && <h3 style={{ textAlign: "center" }}>Loading...</h3>}
        {data.order && (
          <div
            className="card"
            style={{
              borderRadius: "10px",
              marginBottom: "1.5rem",
              padding: "0.75rem",
            }}
          >
            <div>
              <h2 style={{ textAlign: "center" }}>Order Details</h2>
            </div>

            <div className="d-flex justify-content-between py-2">
              <h5 className="text-muted mb-0">Order Id: {data.order._id}</h5>
            </div>

            <div className="d-flex justify-content-between py-2">
              <h5 className="text-muted mb-0">Date : {data.order.date}</h5>
            </div>

            <div className="d-flex justify-content-between mb-5">
              <h5 className="text-muted mb-0">Status: {data.order.status}</h5>
            </div>
            <p className="text-muted mb-0">
              <span className="fw-bold me-4">Total</span> ₹{" "}
              {data.order.orderValue}
            </p>

            {/* <div className="mt-3">
              <button type="button" className="btn btn-danger">
                Cancel Your Order
              </button>
            </div> */}

            <div className="card-body p-4">
              <div>
                <h3 style={{ color: " #a8729a" }}>Products</h3>
              </div>
              <div className="d-flex align-content-center justify-content-center flex-column">
                {data.order.products.map((pro) => (
                  <div className="card mb-3" style={{ maxWidth: "540px" }}>
                    <div className="row g-0">
                      <div className="col-md-4">
                        <img
                          src={`${API_URL_HOST}/${pro.product.imageUrl}`}
                          className="img-fluid rounded-start"
                          alt="..."
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="card-body">
                          <h5 className="card-title">{pro.product.name}</h5>
                          <p>
                            type: <b>{pro.type}</b>
                          </p>
                          <h6 className="card-title">
                            Category: {pro.product.category}
                          </h6>
                          <h5 className="card-title">
                            Price: {pro.product.price}
                          </h5>
                          <p>
                            Quantity: <b>{pro.quantity}</b>
                          </p>
                          <p>Size: {pro.product.size}</p>
                          {/* <p className="card-text">
                            Description: {pro.product.description}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="card-footer border-0 px-4 py-5"
              style={{
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                backgroundColor: "#165315",
              }}
            >
              <h5 className="d-flex align-items-center justify-content-end text-white text-uppercase mb-0">
                Total paid:{" "}
                <span className="h2 mb-0 ms-2">₹{data.order.orderValue}</span>
              </h5>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default OrderDetailsPageUser;
