import React from "react";
import "./ContactHome.css";
import { MdCall } from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs";
import {Link} from 'react-router-dom'
import { HiChatBubbleBottomCenter } from "react-icons/hi2";
const Contact_home = () => {
  return (
    <div id="contact-us" className="c-wrapper">
      <div className="paddings innerWidth flexCenter c-container">
        {/* left side */}
        <div className="flexColStart c-left">
          <h3 style={{ textAlign: "center" }}>Contact Us</h3>

          <h6 style={{ textAlign: "center" }}>
          Your feedback matters! Reach out to our team for any inquiries or assistance — we are committed to making your experience exceptional..
          </h6>

          <div
            style={{ width: "75%", margin: "1rem auto" }}
            className="d-flex align-items-center justify-content-around contact-info_section"
          >
            {/* first row */}
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <MdCall size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Call</span>
                    <span className="secondaryText">+91-9958287272</span>
                  </div>
                </div>
                <div className="flexCenter button">
                  <Link to="tel://+91-9958287272">Call now</Link>
                </div>
              </div>

              {/* <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Chat</span>
                    <span className="secondaryText">+91-9958287272</span>
                  </div>
                </div>
                <div className="flexCenter button">
                  <Link to="https://wa.me/9958287272" target="blank">
                    Chat now
                  </Link>
                </div>
              </div> */}
            </div>

            {/* second row */}

            <div className="flexColCenter mode">
              <div className="flexStart">
                <div className="flexCenter icon">
                  <BsFillChatDotsFill size={25} />
                </div>
                <div className="flexColStart detail">
                  <span className="primaryText">Chat</span>
                  <span className="secondaryText">+91-9958287272</span>
                </div>
              </div>
              <div className="flexCenter button">
                <Link to="https://wa.me/9958287272" target="blank">
                  Chat now
                </Link>
              </div>
            </div>
            {/* <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Video Call</span>
                    <span className="secondaryText">+91-9958287272</span>
                  </div>
                </div>
                <div className="flexCenter button">
                  <Link to="https://wa.me/9958287272" target="blank">
                    Video Call now
                  </Link>
                </div>
              </div>

              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <HiChatBubbleBottomCenter size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Message</span>
                    <span className="secondaryText">+91-9958287272</span>
                  </div>
                </div>
                <div className="flexCenter button">
                  <Link to="sms:+91-9958287272">Message now</Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {/* right side */}
        {/* <div className="flexEnd c-right">
          <div className="image-container">
            <img src="./contact.png" alt="" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Contact_home;
