import React from "react";
import { Helmet } from "react-helmet";
import PlantDayCare from "../../components/Services/PlantDayCare";

const PlantDayCarePage = () => {
  return (
    <>
      <Helmet>
        <title>Plant Day Care Services | Chaperone</title>
        <meta
          name="description"
          content=" Leave your plants in safe hands with Chaperone while you're away. We offer
Plants Day Care Services in Delhi, Gurgaon, Noida .Contact us today for worry-free plant care
solutions."
        />
      </Helmet>
      <PlantDayCare />
    </>
  );
};

export default PlantDayCarePage;
