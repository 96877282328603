import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import Layout from "./util/Layout";
import Home from "./components/Navbar/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contacts/Contact";
import SignUp from "./components/registration/SignUp";
import Cart from "./components/Cart/Cart";

// payments page

import PaymentSuccess from "./pages/PaymentSuccess";

// Plants

import All from "./All/All";

// Services

import MaaliServicePage from "./pages/Services/MaaliServicePage";
import PlantDayCarePage from "./pages/Services/PlantDayCarePage";
import RentPlantPage from "./pages/Services/RentPlantPage";

import Blog from "./pages/Blog/Blog";

import Orders from "./pages/User/Orders";

import Checkout from "./pages/Checkout/Checkout";

// Admin Form
import AdminPanelForm from "./Admin Panal/Form/AdminPanelForm";
import MaaliForm from "./Admin Panal/Form/MaaliForm";
import NursearyForm from "./Admin Panal/Form/NursearyForm";
import PlantDayCareForm from "./Admin Panal/Form/PlantDayCareForm";
import RentPlantForm from "./Admin Panal/Form/RentPlantForm";

// View Product
import ViewProduct from "./pages/View Product/ViewProduct";

// admin panel imports
import Dashboard from "./Admin Panal/Dashboard";
import Nurseries from "./Admin Panal/Nurseries";
import NurseryPage from "./Admin Panal/NurseryPage";
import NurseryDetailsPage from "./Admin Panal/NurseryDetailsPage";
import NurseryPlantPage from "./Admin Panal/NurseryPlantPage";

import NurseryPotPage from "./Admin Panal/NurseryPotPage";

import PlantPage from "./Admin Panal/PlantPage";
import PotPage from "./Admin Panal/PotPage";

import Services from "./Admin Panal/Services";
import Payments from "./Admin Panal/Payments";
import Settings from "./Admin Panal/Settings";

import MaaliServices from "./Admin Panal/Services/MaaliServices";
import DayCareServices from "./Admin Panal/Services/DayCareServices";
import PlantServices from "./Admin Panal/Services/PlantServices";
import Login from "./components/registration/Login";

import PlantsByCategory from "./Plants/PlantsByCategory";
import OrderDetailPage from "./Admin Panal/OrderDetailPage";
import OrdersPage from "./Admin Panal/OrdersPage";
import OrderDetailsPageUser from "./pages/User/OrderDetailsPageUser";
import MaaliServiceDetailsPage from "./Admin Panal/Services/MaaliServiceDetailsPage";
import PlantDayCareServiceDetailsPage from "./Admin Panal/Services/PlantDayCareServiceDetailsPage";
import EditNuseryDetails from "./Admin Panal/EditNuseryDetails";
import ServiceSuccess from "./Admin Panal/Services/ServiceSuccess";
import CodOrderSuccess from "./pages/Checkout/CodOrderSuccess";
import Profile from "./pages/User/Profile";
import EditPlantPage from "./Admin Panal/EditPlantPage";
import AdminLogin from "./components/registration/AdminLogin";

function App() {
  console.log("THIS IS ME");
  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about_us" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact-us" element={<Contact />} />
            {/* payment success route */}

            <Route path="/paymentsuccess" element={<PaymentSuccess />} />
            <Route
              path="/order-successful/cod/:orderId"
              element={<CodOrderSuccess />}
            />
            <Route
              path="/service-booked/:serviceType/:total"
              element={<ServiceSuccess />}
            />

            {/* admin panel routes */}
            <Route path='/admin/login' element={<AdminLogin/>}/>
            <Route path="/admin" element={<Dashboard />} />
            <Route path="/admin/orders" element={<OrdersPage />} />
            <Route
              path="/admin/orders/:orderId"
              element={<OrderDetailPage />}
            />
            <Route path="/admin/nurseries" element={<Nurseries />} />
            <Route
              path="/admin/nurseries/:nurseryId"
              element={<NurseryPage />}
            />

            <Route
              path="/admin/nurseries/:nurseryId/edit"
              element={<EditNuseryDetails />}
            />

            <Route
              path="/admin/nurseries/:nurseryId/details"
              element={<NurseryDetailsPage />}
            />
            <Route
              path="/admin/nurseries/:nurseryId/plants"
              element={<NurseryPlantPage />}
            />

            <Route
              path="/admin/nurseries/:nurseryId/plants/:plantId"
              element={<PlantPage />}
            />

            <Route
              path="/admin/nurseries/:nurseryId/plants/:plantId/edit"
              element={<EditPlantPage />}
            />

            <Route
              path="/admin/nurseries/:nurseryId/pots"
              element={<NurseryPotPage />}
            />

            <Route
              path="/admin/nurseries/:nurseryId/pots/:potId"
              element={<PotPage />}
            />

            <Route path="/admin/services" element={<Services />} />
            <Route
              path="/admin/services/rent-plants"
              element={<PlantServices />}
            />
            <Route
              path="/admin/services/daycare"
              element={<DayCareServices />}
            />
            <Route
              path="/admin/services/daycare/:daycareId"
              element={<PlantDayCareServiceDetailsPage />}
            />
            <Route path="/admin/services/maali" element={<MaaliServices />} />
            <Route
              path="/admin/services/maali/:maaliId"
              element={<MaaliServiceDetailsPage />}
            />

            <Route path="/admin/payments" element={<Payments />} />
            <Route path="/admin/settings" element={<Settings />} />

            {/* Forms */}
            <Route path="/admin_panel_form" element={<AdminPanelForm />} />

            <Route path="/maali_form" element={<MaaliForm />} />
            <Route path="/plant_day_care_form" element={<PlantDayCareForm />} />
            <Route path="/rent_plant_form" element={<RentPlantForm />} />
            <Route path="/nurseary_form" element={<NursearyForm />} />

            <Route path="/signup" element={<SignUp />} />

            <Route path="/login" element={<Login />} />

            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />

            {/* Plants Types */}

            <Route
              path="/indoor_plants"
              element={<PlantsByCategory category="indoor-plants" />}
            />
            <Route
              path="/hanging_plants"
              element={<PlantsByCategory category="hanging-plants" />}
            />
            <Route
              path="/aquatic_plants"
              element={<PlantsByCategory category="aquatic-plants" />}
            />
            <Route
              path="/monsoon_plants"
              element={<PlantsByCategory category="monsoon-plants" />}
            />
            <Route
              path="/flowering_plants"
              element={<PlantsByCategory category="flowering-plants" />}
            />
            <Route
              path="/fruit_plants"
              element={<PlantsByCategory category="fruit-plants" />}
            />
            <Route
              path="/air_purifying_plants"
              element={<PlantsByCategory category="airpurifying-plants" />}
            />

            <Route
              path="/outdoor_plants"
              element={<PlantsByCategory category="outdoor-plants" />}
            />

            <Route path="/all" element={<All />} />

            {/* Services  */}
            <Route path="/hire_maali" element={<MaaliServicePage />} />
            <Route path="/plant_day_care" element={<PlantDayCarePage />} />
            <Route path="/rent_plant" element={<RentPlantPage />} />

            {/* View Product Dynamic Page  */}
            <Route path="/view_product/:productId" element={<ViewProduct />} />

            {/* Routes  */}
            {/* <Route path="/dashboard" element={<PrivateRoute />}> */}
            {/* <Route path="user" element={<Dashboard />} /> */}
            {/* <Route path="user/profile" element={<Profile />} /> */}
            <Route path="user/orders" element={<Orders />} />
            <Route
              path="user/orders/:orderId"
              element={<OrderDetailsPageUser />}
            />

            {/* </Route> */}
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;

// cd chaperone_plant
