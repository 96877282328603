import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { onAddItemToCart } from "../../util/helper";
import "react-toastify/dist/ReactToastify.css";
import classes from "./EachPlantComp.module.css";
import Button from "./UI/Button";

import { useDispatch } from "react-redux";

import { API_URL_HOST } from "../../global-variables";

const EachPlantComp = (props) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      setIsUserLoggedIn(true);
    }
  }, [userId]);

  const addItemToCartHandler = () => {
    onAddItemToCart(userId, isUserLoggedIn, dispatch, props.data, true);
  };

  const MobileViewPlant = (props) => {
    return (
      <Link
        to={`/view_product/${props.data._id}`}
        className={`${classes["pic-plant--EachPlantComp"]} ${classes.mobilePlantView}`}
      >
        <div className={classes.images}>
          <img
            src={`${API_URL_HOST}/${props.data.imageUrl}`}
            alt="bb"
            width={50}
            height={50}
          />
          {/* <svg
                className={classes["like-btn-EachPlantComp"]}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M11.8396 22.1204L11.8411 22.1215C12.0354 22.2541 12.2651 22.3253 12.5003 22.3257C12.7354 22.3253 12.9649 22.2543 13.1592 22.1219L13.1604 22.121C17.8281 18.9524 19.8185 16.802 20.8974 15.4874L11.8396 22.1204ZM11.8396 22.1204C7.17179 18.9518 5.18141 16.8013 4.10252 15.4868M11.8396 22.1204L4.10252 15.4868M24.2494 6.77648C24.2155 3.43772 21.539 0.75 18.3242 0.75C15.9824 0.75 14.3371 2.06401 13.3412 3.22351L13.3385 3.22662C13.2342 3.34665 13.1054 3.4429 12.9608 3.50887C12.8161 3.57483 12.659 3.60896 12.5 3.60896C12.341 3.60896 12.1839 3.57483 12.0392 3.50887C11.8946 3.44291 11.7658 3.34666 11.6615 3.22662L11.6591 3.22379L11.6591 3.22378C10.6628 2.065 9.01745 0.75 6.67579 0.75C3.46101 0.75 0.784561 3.43769 0.75063 6.77574L24.2494 6.77648ZM24.2494 6.77648C24.2798 9.83004 23.2074 12.6712 20.8975 15.4873L24.2494 6.77648ZM4.10252 15.4868C1.79151 12.6702 0.720242 9.82901 0.750629 6.77588L4.10252 15.4868Z"
                  stroke="#575757"
                  stroke-width="1.5"
                />
              </svg> */}
        </div>
        <div className={classes["price-EachPlantComp"]}>
          <p>{props.data.name}</p>
          <p>₹ {props.data.price}</p>
        </div>
      </Link>
    );
  };
  const DesktopViewPlant = (props) => {
    return (
      <div
        className={`${classes["pic-plant--EachPlantComp"]} ${classes.desktopPlantView}`}
      >
        <div className={classes.images}>
          <img
            src={`${API_URL_HOST}/${props.data.imageUrl}`}
            alt="bb"
            width={50}
            height={50}
          />
          {/* <svg
                className={classes["like-btn-EachPlantComp"]}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M11.8396 22.1204L11.8411 22.1215C12.0354 22.2541 12.2651 22.3253 12.5003 22.3257C12.7354 22.3253 12.9649 22.2543 13.1592 22.1219L13.1604 22.121C17.8281 18.9524 19.8185 16.802 20.8974 15.4874L11.8396 22.1204ZM11.8396 22.1204C7.17179 18.9518 5.18141 16.8013 4.10252 15.4868M11.8396 22.1204L4.10252 15.4868M24.2494 6.77648C24.2155 3.43772 21.539 0.75 18.3242 0.75C15.9824 0.75 14.3371 2.06401 13.3412 3.22351L13.3385 3.22662C13.2342 3.34665 13.1054 3.4429 12.9608 3.50887C12.8161 3.57483 12.659 3.60896 12.5 3.60896C12.341 3.60896 12.1839 3.57483 12.0392 3.50887C11.8946 3.44291 11.7658 3.34666 11.6615 3.22662L11.6591 3.22379L11.6591 3.22378C10.6628 2.065 9.01745 0.75 6.67579 0.75C3.46101 0.75 0.784561 3.43769 0.75063 6.77574L24.2494 6.77648ZM24.2494 6.77648C24.2798 9.83004 23.2074 12.6712 20.8975 15.4873L24.2494 6.77648ZM4.10252 15.4868C1.79151 12.6702 0.720242 9.82901 0.750629 6.77588L4.10252 15.4868Z"
                  stroke="#575757"
                  stroke-width="1.5"
                />
              </svg> */}
        </div>
        <div className={classes["price-EachPlantComp"]}>
          <p>{props.data.name}</p>
          <p>₹ {props.data.price}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <div className={classes["main-EachPlantComp"]}>
          <div className={classes["inside-EachPlantComp"]}>
            <MobileViewPlant data={props.data} />
            <DesktopViewPlant data={props.data} />
            {/* <div className={classes["rating-EachPlantComp"]}>
            <img src="2d5f9291095d1210262f4a0d5f4c77e7.png" alt="" />
            <p>219 reviews</p>
          </div> */}
            <div className={classes["btns-EachPlantComp"]}>
              <Link to={`/view_product/${props.data._id}`}>
                <Button
                  label="View Product"
                  padding="5px"
                  background="transparent"
                  className={classes.display}
                  borderRadius="5px"
                />
              </Link>

              <Button
                label="Add to Cart"
                padding="5px"
                background="#144D14"
                borderRadius="5px"
                color="white"
                onClick={addItemToCartHandler}
              />
            </div>

            {/* <div className={classes["BuyOnRent-EachPlantComp"]}>
            <p>Buy on Rent</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
            >
              <path
                d="M14.3536 4.35355C14.5488 4.15829 14.5488 3.84171 14.3536 3.64645L11.1716 0.464466C10.9763 0.269204 10.6597 0.269204 10.4645 0.464466C10.2692 0.659728 10.2692 0.976311 10.4645 1.17157L13.2929 4L10.4645 6.82843C10.2692 7.02369 10.2692 7.34027 10.4645 7.53553C10.6597 7.7308 10.9763 7.7308 11.1716 7.53553L14.3536 4.35355ZM0 4.5H14V3.5H0V4.5Z"
                fill="#165315"
              />
            </svg>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EachPlantComp;
