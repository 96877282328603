import React from "react";
import { Link } from "react-router-dom";

import classes from "../Common.module.css";
const AdminSideNav = (props) => {
  return (
    <div
      className={`${classes.navcontainer} ${
        props.showMenu ? classes.navclose : ""
      }`}
    >
      <nav className={classes["nav"]}>
        <div className={classes["nav-upper-options"]}>
          <Link
            to="/admin"
            className={`${classes["nav-option"]} ${classes.option1}`}
          >
            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210182148/Untitled-design-(29).png"
              className={classes["nav-img"]}
              alt="dashboard"
            />
            <h6> Dashboard</h6>
          </Link>

          <Link
            to="/admin/services"
            className={`${classes["nav-option"]} ${classes.option2}`}
          >
            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210183322/9.png"
              className={classes["nav-img"]}
              alt="articles"
            />
            <h6> Services</h6>
          </Link>

          <Link
            to="/admin/nurseries"
            className={`${classes["nav-option"]} ${classes.option3}`}
          >
            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210183320/5.png"
              className={classes["nav-img"]}
              alt="report"
            />
            <h6> Nurseries</h6>
          </Link>

          <Link
            to="/admin/orders"
            className={`${classes["nav-option"]} ${classes.option4}`}
          >
            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210183320/5.png"
              className={classes["nav-img"]}
              alt="report"
            />
            <h6> Orders</h6>
          </Link>

          <Link
            to="/admin/payments"
            className={`${classes["nav-option"]} ${classes.option5}`}
          >
            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210183321/6.png"
              className={classes["nav-img"]}
              alt="institution"
            />
            <h6> Payments</h6>
          </Link>

          <Link
            to="/admin/settings"
            className={`${classes["nav-option"]} ${classes.option6}`}
          >
            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210183320/4.png"
              className={classes["nav-img"]}
              alt="settings"
            />
            <h6> Settings</h6>
          </Link>

          <Link className={`${classes["nav-option"]} ${classes.logout}`}>
            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210183321/7.png"
              className={classes["nav-img"]}
              alt="logout"
            />
            <h6>Logout</h6>
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default AdminSideNav;
