import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import axios from "axios";
import { API_URL, API_URL_HOST } from "../../global-variables";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLength } from "../../store/LoggedInUser";
import ErrorModal from "../../components/utils/UI/ErrorModal";
import classes from "./Checkout.module.css";
import LoginAuth from "../../components/utils/LoginAuth";

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [showValidationMessage, setShowValidationMessage] = useState(null);
  const [showError, setError] = useState(null);
  const [totalValue, setTotalValue] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [paymentOption, setPaymentOption] = useState("online");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log("userid", userId);

  if (userId) {
    localStorage.removeItem("previousLocation");
  }

  if (!userId) {
    localStorage.setItem("previousLocation", window.location.pathname);
  }

  const paymentOptionChangeHandler = (value) => {
    console.log(value);
    setPaymentOption(value);
  };

  // getting cart items and user
  useEffect(() => {
    console.log("inside useeffect");
    const getCartItems = async () => {
      if (userId) {
        console.log("got userId", userId);
        try {
          const response1 = await axios.get(
            `${API_URL}/shop/get-user-cart/${userId}`
          );

          const response2 = await axios.get(
            `${API_URL}/auth/get-user/${userId}`
          );

          setUserInfo(response2.data.user);
          setIsLoading(false);
          const { plants } = response1.data.cart.items;
          console.log("itemsss", plants);
          const items = [...plants];

          setCartItems(items);

          setTotalValue(response1.data.cart.totalValue);
        } catch (err) {
          console.log("ERROR", err.message);
          setError(err.message);
        }
      }

      const cartId = localStorage.getItem("cartId");
      if (!userId && cartId) {
        console.log("no userid if");

        try {
          const response = await axios.get(
            `${API_URL}/shop/get-cart-by-id/${cartId}`
          );
          console.log(
            "not logged in user cart in checkout",
            response.data.cart.items.plants
          );

          setCartItems(response.data.cart.items.plants);
          setTotalValue(response.data.cart.totalValue);
          setIsLoading(false);
        } catch (err) {
          console.log("ERROR", err.message);
          setError(err.message);
          // alert(err.message);
        }
      }
    };
    getCartItems();
  }, [userId]);

  const codCheckoutHandler = async () => {
    try {
      const {
        data: { order },
      } = await axios.post(`${API_URL}/payments/cod-checkout/${userId}`, {
        totalValue,
      });

      if (order._id) {
        navigate(`/order-successful/cod/${order._id}`);
      }

      dispatch(updateLength(0));
    } catch (err) {
      console.log(err);
      // alert(err.message);
      setError(err.message);
    }
  };

  // handling checkout
  const checkoutHandler = async (amount) => {
    if (!userId) {
      setShowValidationMessage(
        "You are not logged in, login to continue with checkout"
      );
      // alert();
      return;
    }
    if (totalValue < 199) {
      setShowValidationMessage("Total Amount must be greater than 199");
      return;
    }



    if (paymentOption === "cod") {
      codCheckoutHandler();
      return;
    }
    try {
      const {
        data: { key },
      } = await axios.get(`${API_URL}/payments/get-payment-key`);

      const {
        data: { order },
      } = await axios.post(`${API_URL}/payments/checkout`, {
        totalValue,
        userId,
      });

      const options = {
        key,
        amount: order.amount,
        // amount:totalValue,
        currency: "INR",
        name: "Chapreone",
        description: "Testing...",
        image: logo,
        order_id: order.id,
        callback_url: `${API_URL}/payments/payment-verification`,
        prefill: {
          name: userInfo.name,
          contact: userInfo.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#121212",
        },
      };
      // console.log(window)

      dispatch(updateLength(0));

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (err) {
      console.log("ERROR...", err.message);
      setError(err.message);
    }
  };

  const closeValidationModal = () => {
    setShowValidationMessage(null);
  };
  const closeErrorModal = () => {
    setError(null);
  };

  return (
    <>
      <ErrorModal
        isOpen={!!showValidationMessage}
        message={showValidationMessage}
        onClose={closeValidationModal}
      />
      <ErrorModal
        isOpen={!!showError}
        message={showError}
        onClose={closeErrorModal}
      />
      <div className={classes.checkout_wrapper}>
        <div className=" container-fluid my-5 ">
          <div className="row justify-content-center ">
            <div className="col-xl-10">
              <div
                className={`${classes["checkout_container"]} card shadow-lg `}
              >
                <div className="row p-2 mt-3 justify-content-between mx-sm-2"></div>

                <div className="row justify-content-around">
                  <div className="col-md-5">
                    <div className="card border-0">
                      <div className="card-header pb-0">
                        <h2 className="card-title space ">Checkout</h2>
                        <p className="card-text text-muted mt-4  space">
                          SHIPPING DETAILS
                        </p>
                        <hr className="my-0" />
                      </div>

                      <div className="card-body">
                        <div className="row justify-content-between">
                          {!isLoading && userId && (
                            <div className="col-auto mt-0">
                              <p>
                                <b>
                                  <span>{userInfo.adress.addressLine}, </span>
                                  <span>{userInfo.adress.city}, </span>
                                  <span>{userInfo.adress.state}, </span>
                                  <span>{userInfo.adress.pincode} </span>
                                </b>
                              </p>
                            </div>
                          )}

                          {!userId && <LoginAuth />}
                        </div>

                        <div
                          style={{
                            border: "1px solid grey",
                            padding: "12px",
                            borderRadius: "5px",
                          }}
                        >
                          <legend>Select Payment Option:</legend>
                          <div>
                            <input
                              type="radio"
                              id="online"
                              name="payment-type"
                              value="online"
                              style={{ border: "2px solid" }}
                              onChange={(e) =>
                                paymentOptionChangeHandler(e.target.value)
                              }
                            />
                            <label
                              for="online"
                              style={{
                                display: "inline-block",
                                marginLeft: "1rem",
                              }}
                            >
                              Online Payment
                            </label>
                          </div>

                          <div>
                            <input
                              type="radio"
                              id="cod"
                              name="payment-type"
                              value="cod"
                              onChange={(e) =>
                                paymentOptionChangeHandler(e.target.value)
                              }
                            />
                            <label
                              for="cod"
                              style={{
                                display: "inline-block",
                                marginLeft: "1rem",
                              }}
                            >
                              Cash on Delivery
                            </label>
                          </div>
                        </div>

                        <div className="row mb-md-5">
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-success px-5 mt-5"
                              onClick={checkoutHandler}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="card border-0 ">
                      <div className="card-header card-2">
                        <p className="card-text text-muted mt-md-4  mb-2 space">
                          YOUR ORDER{" "}
                          <span className=" small text-muted ml-2 cursor-pointer">
                            EDIT SHOPPING BAG
                          </span>{" "}
                        </p>
                        <hr className="my-2" />
                      </div>
                      <div className="card-body pt-0">
                        {/* Cart-Items starts here */}
                        {!cartItems && (
                          <h3 style={{ textAlign: "center" }}>
                            Loading items...
                          </h3>
                        )}
                        {cartItems &&
                          cartItems.map((item) => (
                            <div key={item._id}>
                              <div className="row  justify-content-between">
                                <div className="col-auto col-md-7">
                                  <div className="media flex-column flex-sm-row">
                                    <img
                                      className=" img-fluid"
                                      src={`${API_URL_HOST}/${item.plantId.imageUrl}`}
                                      width="82"
                                      height="82"
                                      alt="product"
                                    />
                                    <div className="media-body  my-auto">
                                      <div className="row ">
                                        <div className="col-auto">
                                          <p className="mb-0">
                                            <b>{item.plantId.name}</b>
                                          </p>
                                          <small className="text-muted">
                                            {item.plantId.category}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className=" pl-0 flex-sm-col col-auto  my-auto">
                                  {" "}
                                  <p className="boxed-1">{item.quantity}</p>
                                </div>
                                <div className=" pl-0 flex-sm-col col-auto  my-auto ">
                                  <p>
                                    <b>{item.plantId.price}</b>
                                  </p>
                                </div>
                              </div>
                              <hr className="my-2" />
                            </div>
                          ))}

                        {/* Cart-items ends here */}
                        <hr className="my-2" />
                        <div className="row ">
                          <div className="col">
                            {/* <div className="row justify-content-between">
                                <div className="col">
                                  <p className="mb-1">
                                    <b>Shipping</b>
                                  </p>
                                </div>
                                <div className="flex-sm-col col-auto">
                                  <p className="mb-1">
                                    <b>₹900</b>
                                  </p>
                                </div>
                              </div> */}
                            <div className="row justify-content-between">
                              <div className="col-4">
                                <p>
                                  <b>Total</b>
                                </p>
                              </div>
                              <div className="flex-sm-col col-auto">
                                <p className="mb-1">
                                  <b>₹{totalValue}</b>
                                </p>{" "}
                              </div>
                            </div>
                            <hr className="my-0" />
                          </div>
                        </div>
                        <div className="row mb-5 mt-4 ">
                          <div className="col-md-7 col-lg-6 mx-auto">
                            {/* <button
                                type="button"
                                className="btn btn-block btn-outline-primary btn-lg"
                              >
                                ADD COUPON CODE
                              </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
