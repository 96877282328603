import React from "react";
import { useParams } from "react-router-dom";
import classes from "./Common.module.css";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";
import useApi from "../hooks/useapi";

import { Link } from "react-router-dom";
import { API_URL, API_URL_HOST } from "../global-variables";

const PlantPage = () => {
  const plantId = useParams().plantId;

  const { data, isLoading } = useApi(`${API_URL}/admin/get-plant/${plantId}`,true);

  if (!isLoading) {
    console.log("data here", data.plant);
  }
  return (
    <AdminPanelWrapper>
      <div className={classes.main}>
        {isLoading && <p>Hold on...Loading</p>}
        {data.plant && (
          <div
            // style={{ border: "2px solid" }}
            className="p-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded"
            key={data.plant._id}
          >
            <div className="d-flex justify-content-around">
              <div>
                <img
                  src={`${API_URL_HOST}/${data.plant.imageUrl}`}
                  alt=""
                  width={800}
                />
              </div>
              <div
                //   style={{ border: "2px solid" }}
                className="d-flex flex-column p-5 "
              >
                <div className="">
                  <h2 className="my-3">Name: {data.plant.name}</h2>
                  <p className="my-3"> Id: {data.plant._id}</p>
                  <p className="my-3">NurseryId: {data.plant.nurseryId}</p>
                  <p className="my-3">Size: {data.plant.size}</p>
                  <p className="my-3">Avaiable In: {data.plant.availableIn}</p>
                  <h6 className="my-3">Price: ₹ {data.plant.price}</h6>
                </div>

                <div className="">
                  <h4 className="my-3">Category: {data.plant.category}</h4>
                </div>
                <div className="">
                  <p className="my-3">Description: {data.plant.description}</p>
                </div>
                <div className="d-flex  justify-content-between my-4">
                  {/* <Link to="edit">
                    <button type="button" className="btn btn-info btn-lg">
                      Edit
                    </button>
                  </Link> */}

                  <button type="button" className="btn btn-danger btn-lg">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AdminPanelWrapper>
  );
};

export default PlantPage;
