import React from "react";
import Header from "../components/header/Header";
import Footer from '../components/footer/Footer'
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
const Layout = (props) => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;

  if (currentPath.startsWith("/admin") || currentPath === "/checkout" || currentPath==='/login' || currentPath==='/signup') {
    return <div>{props.children}</div>;
  }
  return (
    <div>
      <Header />
      {props.children}
      <ToastContainer />
      <Footer/>
    </div>
  );
};

export default Layout;
