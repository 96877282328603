import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [], //array of plant/pot ids
  total: 0,
  length: 0,
};

export const NonLoggedInUser = createSlice({
  name: "NonLoggedInUserState",
  initialState,
  reducers: {
    addItemToRedux: (state, action) => {
      console.log("adding item to redux");
      console.log("redux state items", state.items);

      // console.log("id", action.payload.id);
      const existingItem = state.items.find(
        (item) => item._id === action.payload._id
      );
      console.log("ei", existingItem);
      let updatedItems;
      let total;

      if (existingItem) {
        updatedItems = [...state.items];
        // console.log('inside existing item')
        const index = updatedItems.findIndex(
          (item) => item._id === existingItem._id
        );

        // console.log('eii',index)
        updatedItems[index] = {
          ...updatedItems[index],
          quantity: updatedItems[index].quantity + 1,
        };

        total = state.total + updatedItems[index].price;
        //   console.log(total)

        // console.log('ui',updatedItems)
      } else {
        updatedItems = [...state.items, action.payload];
        // console.log('ui2',updatedItems)
        total = state.total + action.payload.price;
        //   console.log(total)
      }
      return {
        ...state,
        total: total,
        items: [...updatedItems],
      };
    },
    removeItemFromRedux: (state, action) => {
      console.log("action object", action);
      const itemToBeRemoved = state.items.find(
        (item) => item._id === action.payload
      );

      console.log("removeeeee", itemToBeRemoved);

      let updatedItems = [...state.items];
      let total;
      if (itemToBeRemoved.quantity === 1) {
        updatedItems = updatedItems.filter(
          (item) => item._id !== itemToBeRemoved._id
        );
        total = state.total - itemToBeRemoved.price;
        //   console.log(total)
      } else {
        const index = state.items.findIndex(
          (item) => item._id === itemToBeRemoved._id
        );
        updatedItems[index] = {
          ...updatedItems[index],
          quantity: updatedItems[index].quantity - 1,
        };
        total = state.total - itemToBeRemoved.price;
        //   console.log(total)
      }
      return {
        ...state,
        total: total,
        items: updatedItems,
      };
    },

    deleteCart: (state, action) => {
      state.items.length = 0;
    },

    updateLengthNonLoggedInUser: (state, action) => {
      return {
        ...state,
        length: action.payload,
      };
    },
  },
});

export const {
  addItemToRedux,
  removeItemFromRedux,
  deleteCart,
  updateLengthNonLoggedInUser,
} = NonLoggedInUser.actions;
export default NonLoggedInUser.reducer;

// const NonLoggedInUser = (state = initialState, action) => {
//   console.log("reducer", action.payload);

//   if (action.type === "ADD_ITEM") {
//     console.log("adding item to redux");
//     console.log("redux state items", state.items);

//     // console.log("id", action.payload.id);
//     const existingItem = state.items.find(
//       (item) => item._id === action.payload._id
//     );
//     console.log("ei", existingItem);
//     let updatedItems;
//     let total;

//     if (existingItem) {
//       updatedItems = [...state.items];
//       // console.log('inside existing item')
//       const index = updatedItems.findIndex(
//         (item) => item._id === existingItem._id
//       );

//       // console.log('eii',index)
//       updatedItems[index] = {
//         ...updatedItems[index],
//         quantity: updatedItems[index].quantity + 1,
//       };

//       total = state.total + updatedItems[index].price;
//       //   console.log(total)

//       // console.log('ui',updatedItems)
//     } else {
//       updatedItems = [...state.items, action.payload];
//       // console.log('ui2',updatedItems)
//       total = state.total + action.payload.price;
//       //   console.log(total)
//     }
//     return {
//       ...state,
//       total: total,
//       items: [...updatedItems],
//     };
//   }

//   if (action.type === "DECREASE_QUANTITY") {
//     // console.log("remove", action.id);
//     const itemToBeRemoved = state.items.find((item) => item._id === action._id);

//     console.log("removeeeee", itemToBeRemoved);

//     let updatedItems = [...state.items];
//     let total;
//     if (itemToBeRemoved.quantity === 1) {
//       updatedItems = updatedItems.filter(
//         (item) => item._id !== itemToBeRemoved._id
//       );
//       total = state.total - itemToBeRemoved.price;
//       //   console.log(total)
//     } else {
//       const index = state.items.findIndex(
//         (item) => item._id === itemToBeRemoved._id
//       );
//       updatedItems[index] = {
//         ...updatedItems[index],
//         quantity: updatedItems[index].quantity - 1,
//       };
//       total = state.total - itemToBeRemoved.price;
//       //   console.log(total)
//     }
//     return {
//       ...state,
//       total: total,
//       items: updatedItems,
//     };
//   }

//   // if(action.type==='UPDATE')

//   return state;
// };

// export default NonLoggedInUser;
