import React, { useRef } from "react";
import classes from "../Common.module.css";
import styles from "../Services.module.css";
import { useParams, useNavigate } from "react-router-dom";
import useApi from "../../hooks/useapi";
import { API_URL } from "../../global-variables";
import { postApiRequest } from "../../util/helper";
import { modifyDate } from "../../util/helper";
import AdminPanelWrapper from "../utils/AdminPanelWrapper";

const PlantDayCareServiceDetailsPage = () => {
  const daycareId = useParams().daycareId;
    const navigate = useNavigate();
  console.log(daycareId);
  const statusRef = useRef();

  const { data, isLoading } = useApi(
    `${API_URL}/admin/get-plant-day-care-service-detail/${daycareId}`,true
  );

  if (!isLoading) {
    console.log(data);
    const date = modifyDate(data.service.createdAt);
    data.service.serviceBookedOn = date;

    const date2=modifyDate(data.service.date);
    data.service.formattedDate=date2;
  }
  const ux = localStorage.getItem("ux");
  const updateOrderHandler = async () => {
    console.log(statusRef.current.value);
    const status = statusRef.current.value;
    const body = { status };
            if (!ux) {
              navigate("/admin/login");
              return;
    }
            const headers = {
              Authorization: ux,
            };
    const response = await postApiRequest(
      `${API_URL}/admin/post-update-plant-day-care-service/${daycareId}`,
      body,{headers}
    );

    console.log("status", response.service);
    if (response.service) {
      window.location.reload();
    }
  };
  return (
    <AdminPanelWrapper>
      <div className={styles.main_container}>
        {isLoading && <h3>Loading...</h3>}

        {data.service && (
          <section className=" d-flex  justify-content-around">
            <div className="col-lg-10 col-xl-8">
              <div className="card" style={{ borderRadius: "10px" }}>
                <div className="d-flex justify-content-between pt-2"></div>

                <div className="d-flex justify-content-between pt-2">
                  <p className="text-muted mb-0">
                    Service Id: {data.service._id}
                  </p>
                </div>

                <div
                  className={`${
                    data.service.status === "pending"
                      ? classes["status-pending"]
                      : classes["status-completed"]
                  } my-3`}
                >
                  <p className="text-muted">
                    Booked On: {data.service.serviceBookedOn}
                  </p>

                  <h4>
                    <b>Status</b>: {data.service.status}
                  </h4>
                </div>

                <div className="card-body p-3">
                  <h5 style={{ textAlign: "center" }}>Service Details</h5>
                  <div
                    style={{ border: "1px solid grey" }}
                    className="shadow-sm p-3 mb-5 bg-body-tertiary rounded"
                  >
                    <div className="d-flex justify-content-around">
                      <p>
                        <b>Date: {data.service.formattedDate}</b>
                      </p>
                      <p>
                        <b>Time: {data.service.time}</b>
                      </p>
                    </div>

                    <div className="d-flex justify-content-around">
                      <p>
                        <b>Total</b> : ₹ {data.service.totalvalue}
                      </p>
                      <p>
                        <b>Number of days</b> : {data.service.noOfDays}
                      </p>
                    </div>
                  </div>
                  <h5 style={{ textAlign: "center" }}>Customer Details</h5>
                  <div
                    style={{ border: "1px solid grey" }}
                    className="shadow-sm p-3 mb-5 bg-body-tertiary rounded"
                  >
                    <div className="d-flex justify-content-around">
                      <p>
                        {" "}
                        <b>Name</b>: {data.service.name}
                      </p>
                      <p>
                        {" "}
                        <b>Phone</b>: {data.service.phone}
                      </p>
                      <p>
                        {" "}
                        <b>Pincode</b>: {data.service.address.pincode}
                      </p>
                    </div>
                    <div className="d-flex justify-content-around">
                      <p>
                        <b>Address Line 1</b>:{" "}
                        {data.service.address.addressLine1}
                      </p>
                      <p>
                        <b>Address Line 2</b>:{" "}
                        {data.service.address.addressLine2}
                      </p>
                      <p>
                        {" "}
                        <b>City</b>: {data.service.address.city}
                      </p>
                    </div>
                    <div className="d-flex justify-content-around">
                      {" "}
                      <p>
                        {" "}
                        <b>State</b>: {data.service.address.state}
                      </p>
                    </div>
                  </div>
                  <div></div>

                  <div className="mt-2">
                    <h4 style={{ textAlign: "center" }}>Plants</h4>
                    <div>
                      {data.service.plants.length === 0 && (
                        <h4 style={{ textAlign: "center", color: "red" }}>
                          No plants
                        </h4>
                      )}
                      {data.service.plants &&
                        data.service.plants.map((plant) => (
                          <div
                            style={{
                              border: "1px solid grey",
                            }}
                            className="shadow p-3 mb-5 bg-body-tertiary rounded"
                          >
                            <p>
                              <b>Plant Id</b>: {plant._id}
                            </p>

                            <p>
                              <b>Quantity</b>: {plant.quantity}
                            </p>
                            <p>
                              <b>Size</b>: {plant.size}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="shadow p-3 mb-5 bg-body-tertiary rounded p-5 "
              style={{
                border: "1px solid rgba(0,0,0,0.175)",
                maxHeight: "400px",
              }}
            >
              <h4>Change Status</h4>
              <select className="px-3 py-2 w-100 my-3" ref={statusRef}>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
              </select>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={updateOrderHandler}
                >
                  Update
                </button>
              </div>
            </div>
          </section>
        )}
      </div>
    </AdminPanelWrapper>
  );
};

export default PlantDayCareServiceDetailsPage;
