import React, { useEffect, useState } from "react";
import { sliderSettings } from "../../util/common";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { Link, useParams } from "react-router-dom";
import Product from "./Product";
import axios from "axios";
import { API_URL, API_URL_HOST } from "../../global-variables";

import classes from "./ViewProduct.module.css";

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <div className={`${classes.flexCenter} ${classes["r-buttons"]}`}>
      <button
        onClick={() => swiper.slidePrev()}
        className={classes["r-prevButton"]}
      >
        &lt;
      </button>
      <button
        onClick={() => swiper.slideNext()}
        className={classes["r-nextButton"]}
      >
        &gt;
      </button>
    </div>
  );
};

function ViewProduct(props) {
  const productId = useParams().productId;

  return (
    <div>
      <h1 className={`${classes.greenText} ${classes["text-center"]}`}>
        Product{" "}
      </h1>
      <Product id={productId} />
    </div>
  );
}

export default ViewProduct;
