// ErrorModal.js
import React from "react";
import classes from "./ErrorModal.module.css";

const ErrorModal = ({ isOpen, message, onClose, onClickOk, FromHeader }) => {
  return (
    isOpen && (
      <div className={classes["modal-backdrop"]} onClick={onClose}>
        <div className={classes["error-modal"]}>
          <div className={classes["modal-content"]}>
            <div>
              <button
                type="button"
                className={`btn-close ${classes.close}`}
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="d-flex justify-content-center flex-column">
              <h5 style={{ textAlign: "center" }}>{message}</h5>
              <div
                className={`d-flex mt-3 ${
                  FromHeader
                    ? "justify-content-evenly"
                    : "justify-content-center"
                }`}
              >
                {FromHeader && (
                  <button
                    className="px-2 py-1"
                    onClick={onClose}
                    style={{
                      borderRadius: "5px",
                      border: "none",
                      backgroundColor: "#C9C9C9",
                    }}
                  >
                    Cancel
                  </button>
                )}
                <button
                  className="px-3 py-2"
                  style={{
                    backgroundColor: "#144d14",
                    color: "white",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={onClickOk ? onClickOk : onClose}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ErrorModal;
