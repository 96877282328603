import React, { useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

import { API_URL } from "../global-variables";
import classes from "./Common.module.css";
import useApi from "../hooks/useapi";

const NurseryDetailsPage = () => {
  const nurseryId = useParams().nurseryId;
  const { data, isLoading } = useApi(
    `${API_URL}/admin/get-nursery/${nurseryId}`,true
  );

  if (!isLoading) {
    console.log(data);
  }

  return (
    <AdminPanelWrapper>
      <div className={classes.main}>
        {isLoading && <p>Hold on...Loading</p>}
        {data.nursery && (
          <div
            // style={{ border: "2px solid" }}
            className="p-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded"
            key={data.nursery._id}
          >
            <div className="d-flex justify-content-evenly ">
              <div>
                <h3>{data.nursery.name}</h3>
              </div>

              <p> Id: {data.nursery._id}</p>
            </div>
            <div className="d-flex justify-content-around m-5">
              <div>
                <h5>total Listed plants : {data.nursery.totalListedPlants}</h5>
                <h5>total Listed pots : {data.nursery.totalListedPots}</h5>
              </div>

              <div>
                <p>Phone: {data.nursery.phone}</p>
              </div>
            </div>
            <div className="d-flex justify-content-around m-5">
              <div>
                <h5>Address</h5>
                <p>AddressLine: {data.nursery.address.addressLine}</p>
                <p>City: {data.nursery.address.city}</p>
                <p>State: {data.nursery.address.state}</p>
                <p>Pincode: {data.nursery.address.pincode} </p>
              </div>
              <div>
                <h5>Contact Person details</h5>
                <p>Name: {data.nursery.contactPerson.name}</p>
                <p>Email: {data.nursery.contactPerson.email}</p>
                <p>Phone: {data.nursery.contactPerson.phone} </p>
                <label htmlFor="name">Name</label>
              </div>
            </div>
            <div className="d-flex justify-content-around">
              <Link to={`/admin/nurseries/${nurseryId}/edit`}>
                <button type="button" className="btn btn-info btn-lg">
                  Edit
                </button>
              </Link>

              {/* <button
                  type="button"
                  className="btn btn-danger btn-lg"
                  onClick={() => deleteNurseryHandler(data.nursery._id)}
                >
                  Delete
                </button> */}
            </div>
          </div>
        )}
      </div>
    </AdminPanelWrapper>
  );
};

export default NurseryDetailsPage;
