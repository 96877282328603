import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { API_URL, API_URL_HOST } from "../../global-variables";
import axios from "axios";
import { postApiRequest, onAddItemToCart } from "../../util/helper";
import { updateLengthNonLoggedInUser } from "../../store/NonLoggedInUser";
import { updateLength } from "../../store/LoggedInUser";
import CartItem from "./CartItem";

import classes from "./Cart.module.css";

const Cart = () => {
  const [totalValue, setTotalValue] = useState(0);
  const [plantCartItems, setPlantCartItems] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  if (!userId) {
    localStorage.setItem("previousLocation", window.location.pathname);
  }

  // getting cart items for an logged in user

  useEffect(() => {
    const getCartItems = async () => {
      if (userId) {
        setIsLoading(true);
        setIsUserLoggedIn(true);
        try {
          const response = await axios.get(
            `${API_URL}/shop/get-user-cart/${userId}`
          );
          console.log("user cart", response.data);

          // console.log("gf", response.data.cart.cart.totalValue);
          const { plants } = response.data.cart.items;

          setPlantCartItems(plants);
          setTotalValue(response.data.cart.totalValue);
          setIsLoading(false);
        } catch (err) {
          console.log("ERROR", err.message);
          alert(err.message);
        }
      }

      const cartId = localStorage.getItem("cartId");
      if (!userId && cartId) {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${API_URL}/shop/get-cart-by-id/${cartId}`
          );
          console.log(
            "not logged in user cart",
            response.data.cart.items.plants
          );

          setPlantCartItems(response.data.cart.items.plants);
          setTotalValue(response.data.cart.totalValue);
          setIsLoading(false);
        } catch (err) {
          console.log("ERROR", err.message);
          alert(err.message);
        }
      }
    };
    getCartItems();
  }, [userId]);

  // delete plants cart items

  const deletePlantCartItem = async (plantId) => {
    // console.log("plantId", plantId);
    const itemToBeRemoved = plantCartItems.find(
      (plant) => plant.plantId._id === plantId
    );

    const newTotal = totalValue - itemToBeRemoved.plantId.price;
    setTotalValue(newTotal);

    // console.log("itemToRemove", itemToBeRemoved);

    if (itemToBeRemoved.quantity === 1) {
      let updatedItems = [...plantCartItems];
      // console.log("qua 1");
      updatedItems = updatedItems.filter(
        (plant) => plant.plantId._id !== itemToBeRemoved.plantId._id
      );
      setPlantCartItems(updatedItems);
    } else {
      let updatedItems = [...plantCartItems];
      // console.log("qua greater 1");
      const index = plantCartItems.findIndex(
        (plant) => plant.plantId._id === itemToBeRemoved.plantId._id
      );
      // console.log("updatedItems[index]", updatedItems[index].quantity);
      // console.log("index", index);
      // console.log("plantCartItems222", plantCartItems);
      updatedItems[index] = {
        ...updatedItems[index],
        quantity: updatedItems[index].quantity - 1,
      };
      setPlantCartItems(updatedItems);
    }

    // console.log("inside delete");
    const cartId = localStorage.getItem("cartId");

    if (cartId && !userId) {
      const response = await postApiRequest(
        `${API_URL}/shop/clear-plant-from-cart`,
        { plantId: plantId, cartId: cartId }
      );
      // console.log("ondelete", response.cartlength);/
      dispatch(updateLengthNonLoggedInUser(response.cartlength));
    } else {
      const response = await postApiRequest(
        `${API_URL}/shop/clear-plant-from-cart`,
        { plantId: plantId, userId }
      );
      // console.log("ondelete", response.cartlength);
      dispatch(updateLength(response.cartlength));
    }
  };

  const onAddItem = async (product) => {
    const index = plantCartItems.findIndex(
      (plant) => plant.plantId._id === product.plantId._id
    );
    const updatedItems = [...plantCartItems];
    console.log("item quantity add", updatedItems[index]);

    updatedItems[index] = {
      ...updatedItems[index],
      quantity: updatedItems[index].quantity + 1,
    };

    setPlantCartItems(updatedItems);

    const newTotal = updatedItems[index].plantId.price + totalValue;
    setTotalValue(newTotal);

    // updating the quantity on the server
    onAddItemToCart(userId, isUserLoggedIn, dispatch, product, false, "cart");
  };

  return (
    <div className={classes["cart-container"]}>
      <h2 className={classes.center}>My Cart</h2>
      <div className={classes.horizontalLine}></div>
      <div>
        {isLoading && <h2 style={{ textAlign: "center" }}>Loading cart...</h2>}

        {plantCartItems.length === 0 && (
          <h2 style={{ textAlign: "center" }}>Your cart is empty!</h2>
        )}
        {plantCartItems &&
          plantCartItems.map((product, index) => (
            <>
              <CartItem
                product={product}
                onDelete={deletePlantCartItem}
                onAddItem={onAddItem}
              />
              <div className={classes.horizontalLine}></div>
            </>
          ))}
      </div>

      {plantCartItems.length > 0 && (
        <>
          <div className={classes.cart_summary}>
            <div className={classes.cart_summary_total}>
              <p style={{ fontSize: "1.2rem" }}>Order Value</p>
              <h4>₹ {totalValue}</h4>
            </div>
            <div className={classes.cart_summary_total}>
              <p style={{ fontSize: "1.2rem" }}>Delivery</p>
              <h4>FREE</h4>
            </div>
          </div>
          <div className={classes.horizontalLine}></div>
          <div
            className={`${classes.cart_summary_total} ${classes.totalOrder}`}
          >
            <h3>Total</h3>
            <h3>₹ {totalValue}</h3>
          </div>
        </>
      )}

      <div className={classes.action_btns}>
        <div className={classes.action_btns_col}>
          <button>
            <Link to="/" style={{ color: "white" }}>
              Continue Shopping
            </Link>
          </button>
          <button>
            <Link to="/checkout">Checkout</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
