import React from "react";
import "./LastHomeComponent.css";
import { Link } from "react-router-dom";
const LastHomeComponent = () => {
  return (
    <div id="get-st arted" className="g-wrapper">
      <div className="paddings innerWidth g-container">
        <div className="flexColCenter inner-container">
          <span className="greenText">Why Chaperone ?</span>
          <span className="secondaryText">
            Chaperone is here to give you a chance to make a new friend, and
            feel needed. We are here to give you a reason to love your office
            desk just as much as you will love going back home. We are here to
            build a bespoke snippet of greenery just for you!
            <br />
            Find your plants for your place
          </span>
          <button className="button" style={{ padding: " 1rem 1.5rem" }}>
            <Link to="/all">Explore </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LastHomeComponent;
