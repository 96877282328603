import React from "react";

import ShowPlantByCategory from "./ShowPlantByCategory";

const PlantsByCategory = (props) => {
  let metaTitle;
  let metaDescr;

  if (props.category === "indoor-plants") {
    metaTitle = "Buy Indoor Plants Online for Home Delivery| Chaperone";
    metaDescr =
      "Explore our online collection of indoor plants. Buy indoor plants online for a healthier home. Shop now for home delivery of indoor plants near you.";
  }
  if (props.category === "hanging-plants") {
    metaTitle = "Shop Indoor Hanging Plants Online | Chaperone Home Delivery";
    metaDescr =
      "Buy from a wide selection of hanging plants for home decor. Buy hanging plants in baskets, planters and customisable pots. Home delivery of hanging fern, turtle vine and more";
  }
  // if (props.category === "aquatic-plants") {
  // }

  if (props.category === "monsoon-plants") {
    metaTitle = "Buy Monsoon Plants Online | Chaperone free Home Delivery";
    metaDescr =
      "Buy Monsoon plants for home and garden. Monsoon plants online are perfect for the Indian climate. Get free home delivery.";
  }
  if (props.category === "flowering-plants") {
    metaTitle = "Buy Beautiful Flowering Plants Online | Chaperone";
    metaDescr =
      "Buy beautiful flowering plants online. Home indoor flowering plants or garden flowering plants. Buy Peace Lily, African Violet, Orchid and many more.";
  }
  if (props.category === "fruit-plants") {
    metaTitle = "Buy Fruit Plants Online | Chaperone Home Delivery";
    metaDescr =
      "Buy a variety of fruit plants online for home garden. Plant, grow,and savour homegrown fruits. Organically grown fruit plants near you for home delivery.";
  }
  if (props.category === "airpurifying-plants") {
    metaTitle = "Shop Air Purifying Plants Online | Chaperone";
    metaDescr =
      "Shop online for air-purifying plants near you. Get home delivery on air cleaning indoor plants - snake plant, money plant, spider plant, and more.";
  }
  if (props.category === "outdoor-plants") {
    metaTitle = "Buy Premium Affordable Outdoor Plants Near You | Chaperone";
    metaDescr =
      "Buy premium outdoor plants online at affordable prices in India and get home delivery of plants. Buy garden plants and seedlings for trees near you.";
  }
  return (
    <ShowPlantByCategory
      category={props.category}
      metaTitle={metaTitle}
      metaDescr={metaDescr}
    />
  );
};

export default PlantsByCategory;
