// import React from "react";
// import "./IconImage.css";
// import { Link } from "react-router-dom";

// const Companies = () => {
//   return (
//     <>
//       Icon check
//       <div className="container text-center">
//         <h1>Explore Our Extensive Range</h1>
//         <div className="row">
//           <div className="col">
//             Column 1
//             <Link to="/indoor_plants">
//               <img
//                 src="./Rent_Plants.png"
//                 alt="logo"
//                 width={50}
//                 height={50}
//                 className="pic"
//               />
//               Indoor Plants
//             </Link>
//           </div>
//           <div className="col">
//             Column 2
//             <Link to="/hanging_plants">
//               <img
//                 src="./Rent_Plants.png"
//                 alt="logo"
//                 width={50}
//                 height={50}
//                 className="pic"
//               />
//               Hanging Plants
//             </Link>
//           </div>
//           <div className="col">
//             Column 3
//             <Link to="/flowering_plants">
//               <img
//                 src="./Rent_Plants.png"
//                 alt="logo"
//                 width={100}
//                 height={100}
//                 className="pic"
//               />
//               Flowering Plants
//             </Link>
//           </div>
//           <div className="col">
//             Column 4
//             <Link to="/hire_maali">
//               <img
//                 src="./Rent_Plants.png"
//                 alt="logo"
//                 width={100}
//                 height={100}
//                 className="pic"
//               />
//               Maali Services
//             </Link>
//           </div>
//           <div className="col">
//             Column 5
//             <Link to="/">
//               <img
//                 src="./Rent_Plants.png"
//                 alt="logo"
//                 width={100}
//                 height={100}
//                 className="pic"
//               />
//               Plants Day Care
//             </Link>
//           </div>
//           <div className="col">
//             Column 6
//             <Link to="/moonson_plant">
//               <img
//                 src="./Rent_Plants.png"
//                 alt="logo"
//                 width={100}
//                 height={100}
//                 className="pic"
//               />
//               Moonson Plants
//             </Link>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Companies;

import React from "react";
import "./IconImage.css";
import { Link } from "react-router-dom";

const Companies = () => {
  return (
    <>
      <div className="main-container">
        <h3 className="greenText text-center">Explore Our Lush Collection</h3>
      </div>

      
      <div className="box-container text-center">
        <div className="col-IconImg" id="1">
          <Link to="/indoor_plants">
            <img
              src="./Rent_Plants.png"
              alt="logo"
              width={50}
              height={50}
              className="pic"
            />
            <p>Indoor Plants</p>
          </Link>
        </div>
        <div className="col-IconImg" id="2">
          <Link to="/outdoor_plants">
            <img
              src="./Rent_Plants.png"
              alt="logo"
              width={50}
              height={50}
              className="pic"
            />
            <p>Outdoor Plants</p>
          </Link>
        </div>
        <div className="col-IconImg" id="3">
          <Link to="/flowering_plants">
            <img
              src="./Rent_Plants.png"
              alt="logo"
              width={100}
              height={100}
              className="pic"
            />
            <p>Flowering Plants</p>
          </Link>
        </div>
        <div className="col-IconImg" id="4">
          <Link to="/air_purifying_plants">
            <img
              src="./Rent_Plants.png"
              alt="logo"
              width={100}
              height={100}
              className="pic"
            />
            <p> Air Purifying Plants</p>
          </Link>
        </div>
        {/* <div className="col-IconImg" id="5">
          <Link to="/plant_day_care">
            <img
              src="./Rent_Plants.png"
              alt="logo"
              width={100}
              height={100}
              className="pic"
            />
            <p>Plants Day Care</p>
          </Link>
        </div> */}
        <div className="col-IconImg" id="6">
          <Link to="/monsoon_plants">
            <img
              src="./Rent_Plants.png"
              alt="logo"
              width={100}
              height={100}
              className="pic"
            />
            <p>Monsoon Plants</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Companies;

// import React from "react";
// // import "./IconImage.css";

// function IconImage() {
//   return (
//     <>
//       <div className="container">
//         <div className="card">
//           <h3 className="title">Card 1</h3>
//           <div className="bar">
//             <div className="emptybar" />
//             <div className="filledbar" />
//           </div>
//           <div className="circle">
//             <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
//               <circle className="stroke" cx={60} cy={60} r={50} />
//             </svg>
//           </div>
//         </div>
//         <div className="card">
//           <h3 className="title">Card 2</h3>
//           <div className="bar">
//             <div className="emptybar" />
//             <div className="filledbar" />
//           </div>
//           <div className="circle">
//             <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
//               <circle className="stroke" cx={60} cy={60} r={50} />
//             </svg>
//           </div>
//         </div>
//         <div className="card">
//           <h3 className="title">Card 3</h3>
//           <div className="bar">
//             <div className="emptybar" />
//             <div className="filledbar" />
//           </div>
//           <div className="circle">
//             <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
//               <circle className="stroke" cx={60} cy={60} r={50} />
//             </svg>
//           </div>
//         </div>
//         <div className="card">
//           <h3 className="title">Card 4</h3>
//           <div className="bar">
//             <div className="emptybar" />
//             <div className="filledbar" />
//           </div>
//           <div className="circle">
//             <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
//               <circle className="stroke" cx={60} cy={60} r={50} />
//             </svg>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default IconImage;
