import React from "react";
import { Helmet } from "react-helmet";

import MaaliService from "../../components/Services/MaaliService";

const MaaliServicePage = () => {
  return (
    <>
      <Helmet>
        <title> Professional Maali Services | Chaperone</title>
        <meta
          name="description"
          content="Chaperone Plants offers professional maali/Gardener services to keep
         your indoor plants, small gardens, and office plants healthy."
        />
      </Helmet>
      <MaaliService />
    </>
  );
};

export default MaaliServicePage;
