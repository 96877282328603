import React from "react";
import "./AdminFormStyle.css";
import { Link } from "react-router-dom";

function NursearyForm() {
  return (
    <div>
      <form
        className="Input"
        action=""
        method="post"
        encType="multipart/form-data"
      >
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <div className="input-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="col">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="input-group">
                <label htmlFor="password">Password:</label>
                <input type="password" id="password" name="password" required />
              </div>
              <div className="input-group">
                <label htmlFor="city">City:</label>
                <input type="text" id="city" name="city" required />
              </div>
            </div>

            <div className="col">
              <div className="input-group">
                <label htmlFor="pincode">Pincode:</label>
                <input type="number" id="pincode" name="pincode" required />
              </div>
              <div className="input-group">
                <label htmlFor="state">State:</label>
                <input type="text" id="state" name="state" required />
              </div>
              <div className="input-group">
                <label htmlFor="locality">Locality:</label>
                <input type="text" id="locality" name="locality" required />
              </div>
            </div>
          </div>
        </div>

        <div className="button-container">
          <button type="submit" className="submit-button button">
            Submit
          </button>
          <Link
            to="/nurseary_form"
            className="list-group-item list-group-item-action"
          >
            <button type="button" className="close">
              Close
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default NursearyForm;
