import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "./Product.module.css";
import { onAddItemToCart } from "../../util/helper";
import Button from "../../components/utils/UI/Button";
import { API_URL, API_URL_HOST } from "../../global-variables";
import useApi from "../../hooks/useapi";
import { useDispatch } from "react-redux";

const Product = (props) => {
  const url = `${API_URL}/shop/get-plant/${props.id}`;
  const { data, isLoading } = useApi(url);

  if (data) {
    console.log("product data", data);
  }

  // -----------------------------------------------------------------------------------------------

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userId) {
      setIsUserLoggedIn(true);
    }
  }, [userId]);

  const dispatch = useDispatch();

  const addItemToCartHandler = () => {
    onAddItemToCart(userId, isUserLoggedIn, dispatch, data.plant);
  };

  // --------------------------------------------------------------------------------

  return (
    <>
      {isLoading && <h3>Loading...</h3>}
      {data.plant && (
        <div className={classes["product-container"]}>
          <div className={classes["row-dotd"]}>
            <div className={classes["left-dotd"]}>
              <img src={`${API_URL_HOST}/${data.plant.imageUrl}`} alt="logo" />
            </div>

            <div className={classes["right-dotd"]}>
              <div className={classes.head}>
                <div className={classes["head-row"]}>
                  <div className={classes["head-col"]}>
                    <h1>{data.plant.name}</h1>
                    <p>{data.plant.category}</p>
                    <p>
                      <strong className={classes.orangeText}>₹</strong>

                      <strong className={classes.greenText2}>
                        {" "}
                        {data.plant.price}
                      </strong>
                    </p>
                    <p>
                      <b>Size: </b>
                      {data.plant.size}
                    </p>
                    <p>Available In</p>
                    <button type="button" className="btn btn-success">
                      {data.plant.availableIn}
                    </button>
                  </div>
                </div>

                <p>{data.plant.description}</p>

                {/* <div className={classes["size-dotd"]}>
            <h3>Select Size</h3>
            <button className={classes["size-dotd-btn"]}>Small</button>
            <button className={classes["size-dotd-btn"]}>Medium</button>
            <button className={classes["size-dotd-btn"]}>Large</button>
            <button className={classes["size-dotd-btn"]}>X-Large</button>
            <button className={classes["size-dotd-btn"]}>XX-Large</button>
          </div> */}

                {/* <div className={classes["pot-dotd"]}>
            <h3>Select Pot</h3>
            <div className={classes["form-floating"]}>
              <select
                className={classes["form-select"]}
                id="floatingSelectGrid"
              >
                <option value="1">pot 1</option>
                <option value="2">pot 2</option>
                <option value="3">pot 3</option>
              </select>
            </div>
          </div> */}
                {/* <div className={classes["quantity-dotd"]}>
                  <h3>Select Quantity</h3>
                  <div className={classes["quantity-controls"]}>
                    <button
                      className={classes["quantity-button"]}
                      onClick={() => handleQuantityChange(-1)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className={classes["quantity-input"]}
                      value={quantity}
                      readOnly
                    />
                    <button
                      className={classes["quantity-button"]}
                      onClick={() => handleQuantityChange(1)}
                    >
                      +
                    </button>
                  </div>
                </div> */}
              </div>

              <div className={classes["card-btn"]}>
                <div className={classes["row-cardbtn"]}>
                  <Button
                    label="Add to Cart"
                    padding="15px"
                    background="#144D14"
                    borderRadius="10px"
                    color="white"
                    onClick={addItemToCartHandler}
                  />
                  <Link to="/cart">
                    <Button
                      label="Buy Now"
                      padding="15px"
                      background="#144D14"
                      borderRadius="10px"
                      color="white"
                      onClick={addItemToCartHandler}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
