import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom'

export const useApi = (url,adminRoute=false) => {
  const [data, setData] = useState([]);
  const ux = localStorage.getItem("ux");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()

  
    useEffect(() => {
      const getData = async () => {

        try {
          if (adminRoute && !ux) {
            navigate("/admin/login");
            return
          }

          const headers =
            adminRoute === true
              ? {
                  Authorization: ux,
                }
              : {};
          
          // console.log(headers)
          
          const response = await axios.get(url,{headers});
          if (response.status !== 200) {
            alert("There was some problem");
          }
          setData(response.data);
        } catch (err) {
          console.log("ERROR", err.message);
          alert(err.message);
        } finally {
          setIsLoading(false);
        }
      };
      getData();
    }, [url,adminRoute,ux,navigate]);

  return { data, isLoading };
};
export default useApi;
