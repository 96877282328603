import React, { useState } from "react";
import "./Contact.css";

import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send data to the backend API
    axios
      .post("your_backend_api_endpoint", formData)
      .then((response) => {
        console.log("Data sent successfully:", response.data);
        // Handle success, e.g., show a success message
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        // Handle error, e.g., show an error message
      });
  };

  return (
    <div>
      <div className="contact-us" style={{ minHeight: "80vh" }}>
        <div className="contact-heading">
          <h1 className="greenText">Contact</h1>
        </div>

        <img src="Contact-US.png" alt="Contact" className="contact-image" />

        <div className="contact-form">
          <p>Please fill out the below form, and we'll get back to you.</p>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor="phone">Phone number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />

            <label htmlFor="comment">Comment</label>
            <textarea
              id="comment"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
            ></textarea>

            <div>
              <button className="contactbtn" type="submit">
                Send
              </button>
            </div>
          </form>
        </div>

        {/* Google Maps iframe */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.029880382523!2d77.07108478265849!3d28.41835523134891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2208b02147db%3A0x7135d26aee784f3d!2sChaperone%20Plants!5e0!3m2!1sen!2sin!4v1692187712123!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
