import React, { useEffect, useState } from "react";
import SinglePlant from "../components/utils/EachPlantComp";
import { API_URL } from "../global-variables";
import ReactPaginate from "react-paginate";
import axios from "axios";

import classes from "../../src/Plants/Plants.module.css";

const All = () => {
  const [plantsList, setPlantsList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  let limit = 16;
  useEffect(() => {
    const getPlants = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/shop/get-all-plants?page=${1}&limit=${limit}`
        );
        console.log("ressss", response.data);
        setPageCount(response.data.no_of_plants / limit);
        console.log("pla", response.data.no_of_plants);
        setPlantsList(response.data.plants);
      } catch (err) {
        console.log(err);
      }
    };
    getPlants();
  }, [limit]);

  const fetchPlants = async (currentPage) => {
    const res = await axios.get(
      `${API_URL}/shop/get-all-plants?page=${currentPage}&limit=${limit}`
    );
    return res.data.plants;
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;

    const plantsFromServer = await fetchPlants(currentPage);
    console.log("secod", plantsFromServer);
    setPlantsList(plantsFromServer);
  };

  return (
    <div>
      <div>
        <div className={classes.section_heading}>
          <h2>Plants List</h2>
        </div>
        {!plantsList && (
          <h2 style={{ textAlign: "center" }}>Plants are Loading</h2>
        )}

        <div className={classes.plants_container}>
          {plantsList &&
            plantsList.map((card, i) => (
              <SinglePlant data={card} key={card._id} />
            ))}
        </div>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default All;
