// export const API_URL = "http://62.72.58.132:80/api";

export const API_URL = "https://chaperoneplants.com/api";

// export const API_URL = "http://localhost:5000/api";

// export const API_URL = "http://localhost:3001/api";

// export const API_URL_HOST = "http://62.72.58.132:80";

export const API_URL_HOST = "https://chaperoneplants.com";

// export const API_URL_HOST = "http://localhost:5000";

// export const API_URL_HOST = "http://localhost:3001";


