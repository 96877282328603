import React from "react";
import { Link } from "react-router-dom";

const LoginAuth = () => {
  return (
    <div
      style={{
        width: "50%",
        padding: "30px",
        margin: "0 auto",
        marginTop: "8rem",
      }}
      className="shadow-lg p-3 mb-5 bg-body-tertiary rounded"
    >
      <h4>You are not logged in, please login to continue</h4>
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginTop: "20px" }}
      >
        <Link to="/login">Login/SignUp</Link>
      </button>
    </div>
  );
};

export default LoginAuth;
