import React from "react";
import Hero from "../hero/Hero";

import IconImage from "../../pages/IconImage/IconImage";
import BestSeller from "../../pages/Best Seller/BestSeller";
import DealOTW from "../../pages/Deal of the Week/DealOfTheWeek";

import ServicesSection from "../Services/Services";
import BookMaali from "../Services/MaaliService";
import PlantDayCare from "../Services/PlantDayCare";
import RentPlant from "../Services/RentPlant";
import ContactHome from "../../pages/Contact Home/ContactHome";
import LastHomeComponent from "../../pages/Last Home Component/LastHomeComponent";
import "./Home.css";
import NewArrival from "../../pages/New Arrival/NewArrival";
// import PinCode from "../PinCode/PinCode";
import { Helmet } from "react-helmet";

const Home = () => {
  // const storedPincode = localStorage.getItem("pincode");
  // let hasShownPromptt = JSON.parse(localStorage.getItem("hasShownPromptt"));

  // if (!storedPincode && !hasShownPromptt) {
  //   const pincode = window.prompt("Please enter your pin code");
  //   if (pincode !== null) {
  //     // Check if the user clicked "Cancel"
  //     localStorage.setItem("pincode", pincode);
  //     hasShownPromptt = true; // Set to true only if a pin code was provided
  //     localStorage.setItem("hasShownPromptt", JSON.stringify(hasShownPromptt));
  //   }
  // }

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center p-2"
        style={{
          backgroundColor: "rgb(22, 83, 21)",
          position: "absolute",
          top: "0%",
          width: "100%",
        }}
      >
        <p
          className="mb-0"
          style={{ color: "white", fontWeight: "light", fontSize: "16px" }}
        >
          Free Shipping on orders above 199/-
        </p>
      </div>
      <header className="home" style={{ position: "relative" }}>
        <Helmet>
          <title>
            Online Plants Nursery | Plants, Plant Day care and Maali services
          </title>
          <meta
            name="description"
            content="The best nursery for buying plants online, Plant Day care and Maali services.
Explore the future of indoor Plant shopping, Mali booking and plant care services."
          />
        </Helmet>

        <nav className="navigation">
          <ul className="menu">
            <Hero />
            <IconImage />
            <NewArrival />
            <BestSeller />
            <DealOTW />
            {/* <ServicesSection /> */}
            <BookMaali />
            <PlantDayCare />
            {/* <RentPlant/> */}
            <LastHomeComponent />
            {/* <Value /> */}
            <ContactHome />
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Home;

// const [hasEnteredPinCode, setHasEnteredPinCode] = useState(false);

//   useEffect(() => {

//     const storedPincode = localStorage.getItem("pincode");
//     if (storedPincode) {
//       setHasEnteredPinCode(true);
//     }
//   }, []);

//   const handlePincodeEntered = () => {
//     setHasEnteredPinCode(true);
//   };
