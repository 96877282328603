import React from "react";
import "./About.css";

function About() {
  return (
    <div>
      <div className="container-about">
        <div className="row-about text-center">
          {/* Right Side code  */}
          <div className="col-about-right">
            <div className="about-heading">
              <h1>About Chaperone</h1>
            </div>
            <div className="fontNeo">
              “Keep your life and home evergreen with Chaperone”
            </div>
            <div className="container text-center">
              <div className="row-cote">
                {/* <div className="col-cote">
                  <h1>Who we are</h1>
                  <p>
                    We're passionate chatbot experts dedicated to creating
                    intelligent solutions that help your business succeed.
                  </p>
                </div> */}
                {/* <div className="col-cote">
                  <h1>What we do</h1>
                  <p>
                    We develop innovative chatbot solutions that enhance
                    customer engagement, automate sales, and streamline support.
                  </p>
                </div> */}
              </div>
            </div>
            <div className="mainpara">
              <h1> A Few Words About The Founder of Chaperone</h1>
              <p>
                Shakil had a green thumb ever since he was a child. Whenever he
                would get the chance, he would spend time in their family garden
                and kitchen garden trying to keep their plants healthy. Wherever
                he went, he would riddle his parents with questions about the
                new plants he saw. As he grew up, he realised that people have
                become disconnected from nature. Most people didn’t know the
                very basics about the most common indoor potted plants. People’s
                love for plants became restricted to a small corner in their
                balcony or window ledge. Even then, due to the lack of time and
                knowledge, these plants ended up suffering.
              </p>
            </div>
            <div className="mainpara">
              <h1>Why was Chaperone Founded?</h1>
              <p>
                Shakil saw this as the chance to provide people with an
                opportunity to improve their lives and homes with a touch of
                green - Thus Chaperone was founded. At Chaperone, you will find
                indoor plants, outdoor potted plants and all gardening tools
                necessary to render the garden of your dreams. Any plant lover
                can visit the platform and buy an indoor plant online, or place
                an order for an exotic plant in a special planter for home
                delivery.
              </p>
            </div>
            <div className="mainpara">
              <h1>How Does Chaperone Stand Apart from Other Plant Vendors?</h1>
              <p>
                Chaperone is a small team of plant lovers and enthusiasts who
                know the ins and outs of keeping the greenery alive in people’s
                homes and hearts. From connecting the small plant nurseries to
                potential plant parents, to helping out the mali community by
                connecting them to plant parents - Chaperone is the holistic
                solution to all plant related problems. What’s unique about
                Chaperone is, that where people see problems, our founder,
                Shakil sees inspiration - for busy plant parents who travel a
                lot, Shakil started a plant daycare service. With doorstep
                pickup and drop-off services, it has made the lives of thousands
                of plant owners much easier and less worrisome.
              </p>
            </div>

            <div>
              <h1>What is Chaperone’s Mission and Vision?</h1>
              <p>
                Chaperone looks forward to spearheading a digital revolution in
                the gardening industry in India. Bridging the gap between
                nursery vendors and plant lovers is now a little easier with
                Chaperone’s tech-based approach. Chaperone envisions propagating
                greenery in all spheres of life and bolstering the relationship
                between people and plants.
              </p>
            </div>
            {/* <div className="fontNeo">
            “Keep your life and home evergreen with Chaperone”
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
