import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useParams, Link } from "react-router-dom";
const CodOrderSuccess = () => {
  const orderId = useParams().orderId;
  return (
    <Box boxShadow="dark-lg" p="6" rounded="md" bg="white">
      <VStack h="100vh" justifyContent={"center"}>
        <Heading textTransform={"uppercase"}> Order Successfull</Heading>

        <Text>Order Id.{orderId}</Text>
        <Link to="/">
          <button type="button" className="btn btn-success">
            Go Back To Home
          </button>
        </Link>
      </VStack>
    </Box>
  );
};

export default CodOrderSuccess;
