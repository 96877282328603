import React from "react";
import { Link } from "react-router-dom";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

const NurseryPage = () => {
  return (
    <AdminPanelWrapper>
      <div
        className="d-flex justify-content-around align-items-center"
        style={{ width: "90%", margin: "0 auto" }}
      >
        <div
          className="p-5 mb-2 bg-primary-subtle text-emphasis-primary d-flex justify-content-center align-items-center"
          style={{ width: "400px", height: "400px" }}
        >
          <Link to="plants">
            <h2 style={{ fontSize: "50px", color: "green" }}>Plant</h2>
          </Link>
        </div>

        <div
          className="p-5 mb-2 bg-primary-subtle text-emphasis-primary d-flex justify-content-center align-items-center"
          style={{ width: "400px", height: "400px" }}
        >
          <Link to="pots">
            <h2 style={{ fontSize: "50px", color: "brown" }}>Pot</h2>
          </Link>
        </div>
      </div>
    </AdminPanelWrapper>
  );
};

export default NurseryPage;
