import React from "react";
import { Link } from "react-router-dom";
import classes from "./Common.module.css";
import styles from "./Services.module.css";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

const Services = () => {
  return (
    <AdminPanelWrapper>
      <div className={styles.main_container}>
        <div
          className={`${styles.service_container} shadow p-3 mb-5 bg-body-tertiary rounded `}
        >
          {/* <Link to="rent-plants">
              <h3>Rent Plants Service</h3>
            </Link> */}
          <Link to="maali">
            <h3>Maali Service</h3>
          </Link>
          <Link to="daycare">
            <h3>Day Care Service</h3>
          </Link>
        </div>
        <div className={classes["report-container"]}>
          <div className={classes["report-header"]}>
            <h1 className={classes["recent-Articles"]}>Recent Services List</h1>
            <button className={classes.view}>View All</button>
          </div>

          <div className={classes.services_data_container}>
            <table>
              <tr>
                <th>Order Date</th>

                <th> Status</th>
                <th>Total</th>
                <th>Pincode</th>
              </tr>
              <tr>
                <td>10-08-23</td>
                <td>Completed</td>
                <td>1499</td>
                <td>20820</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </AdminPanelWrapper>
  );
};

export default Services;
