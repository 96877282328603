import { useState,useEffect } from "react";


const useGst=(value)=>{

    const [totalGSTAmount,setTotalGSTAmount]=useState(0)
    useEffect(()=>{
        const calculateGSTAmount=()=>{

         const decimalPart = value % 1;
         if (decimalPart >= 0.5) {
          // If decimal part is greater than or equal to 0.5, round up
          setTotalGSTAmount(Math.ceil(value));
        } else {
          // Otherwise, round down
          setTotalGSTAmount(Math.floor(value));
        }
       }
       calculateGSTAmount()
      },[value])

      return {totalGSTAmount}
}

export default useGst;