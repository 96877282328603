import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  length: 0,
};

export const LoggedInUser = createSlice({
  name: "NonLoggedInUserState",
  initialState,
  reducers: {
    updateUserCart: (state, action) => {
      const items = [...action.payload];
      return {
        cartItems: [...items],
        length: items.length,
      };
    },

    updateLength: (state, action) => {
      console.log("updating length...");
      return {
        ...state,
        length: action.payload,
      };
    },
  },
});

export const { updateUserCart, updateLength } = LoggedInUser.actions;
export default LoggedInUser.reducer;
