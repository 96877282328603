import React, { useState, useEffect } from "react";
import classes from "./DealOfTheWeek.module.css";
import { API_URL, API_URL_HOST } from "../../global-variables";
import useApi from "../../hooks/useapi";
import { Link } from "react-router-dom";
import Button from "../../components/utils/UI/Button";
import { onAddItemToCart } from "../../util/helper";
import { useDispatch } from "react-redux";

const DealOTheWeek = (props) => {
  const { data, isLoading } = useApi(`${API_URL}/shop/get-dealoftheday-plant`);
  if (!isLoading) {
    // console.log("dtd", data.plant);
  }

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userId) {
      setIsUserLoggedIn(true);
    }
  }, [userId]);
  const dispatch = useDispatch();
  const addItemToCartHandler = () => {
    onAddItemToCart(userId, isUserLoggedIn, dispatch, data.plant);
  };
  return (
    <div>
      {data.plant && (
        <div className={classes["main-DealOTheWeek"]}>
          <div className={classes["inside-DealOTheWeek"]}>
            <div className={classes["left-DealOTheWeek"]}>
              <div className={classes["heading-DealOTheWeek"]}>
                {/* <p className={classes["head1-DealOTheWeek"]}>Deal of the Day</p> */}

                <p className={classes["head2-DealOTheWeek"]}>Deal of the Day</p>
              </div>
              <div className={classes["title-plant-DealOTheWeek"]}>
                <div className={classes["prod-name-DealOTheWeek"]}>
                  <p>{data.plant.name}</p>
                </div>
                {/* <div className={classes["ratings-DealOTheWeek"]}>
                  <img src="2d5f9291095d1210262f4a0d5f4c77e7.png" alt="" />
                  <p style={{ paddingTop: "0.3rem" }}>21 ratings</p>
                </div> */}
              </div>
              <div className={classes["price-DealOTheWeek"]}>
                <p className={classes["old-price-DealOTheWeek"]}>₹ 499</p>

                <p className={classes["new-price-DealOTheWeek"]}>
                  ₹ {data.plant.price}
                </p>
              </div>
              <div className={classes["prod-desc-DealOTheWeek"]}>
                <p>
                  Just like our plants, we like to offer something to our
                  patrons at all times. So, we offer amazing discounts on our
                  best-selling plants. We do not consider any plant better than
                  another; we simply want to offer you a chance to adopt and
                  raise a green friend. If you have always dreamt of an indoor
                  garden or a green nook, this is your chance to get started.
                </p>
                <div className={classes["btn-fullDetails-DealOTheWeek"]}>
                  {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="8"
                  viewBox="0 0 28 8"
                  fill="none"
                >
                  <path
                    d="M27.3536 4.35355C27.5488 4.15829 27.5488 3.84171 27.3536 3.64645L24.1716 0.464466C23.9763 0.269204 23.6597 0.269204 23.4645 0.464466C23.2692 0.659728 23.2692 0.976311 23.4645 1.17157L26.2929 4L23.4645 6.82843C23.2692 7.02369 23.2692 7.34027 23.4645 7.53553C23.6597 7.7308 23.9763 7.7308 24.1716 7.53553L27.3536 4.35355ZM0 4.5H27V3.5H0V4.5Z"
                    fill="black"
                  />
                </svg>
                <p>Full Detail</p> */}
                </div>

                {/* <div className={classes["all-dimensions-prod-DealOTheWeek"]}>
                <div className={classes["SelectSize-prod-DealOTheWeek"]}>
                  <p className={classes["head-SelectSize-prod-DealOTheWeek"]}>
                    Select Size
                  </p>
                  <div
                    className={classes["allSize-SelectSize-prod-DealOTheWeek"]}
                  >
                    <div
                      className={
                        classes["eachSize-SelectSize-prod-DealOTheWeek"]
                      }
                    >
                      <p>Small</p>
                    </div>
                    <div
                      className={
                        classes["eachSize-SelectSize-prod-DealOTheWeek"]
                      }
                    >
                      <p>Medium </p>
                    </div>
                    <div
                      className={
                        classes["eachSize-SelectSize-prod-DealOTheWeek"]
                      }
                    >
                      <p>Large </p>
                    </div>
                  </div>
                </div>

                <div className={classes["SelectSize-prod-DealOTheWeek"]}>
                  <p className={classes["head-SelectSize-prod-DealOTheWeek"]}>
                    Color
                  </p>
                  <div
                    className={classes["allSize-SelectSize-prod-DealOTheWeek"]}
                  >
                    <div
                      className={
                        classes["eachSize-SelectColor-prod-DealOTheWeek"]
                      }
                    >
                      <p></p>
                    </div>
                    <div
                      className={
                        classes["eachSize-SelectColor-prod-DealOTheWeek"]
                      }
                    >
                      <p> </p>
                    </div>
                    <div
                      className={
                        classes["eachSize-SelectColor-prod-DealOTheWeek"]
                      }
                    >
                      <p> </p>
                    </div>
                  </div>
                </div>

                <div className={classes["SelectSize-prod-DealOTheWeek"]}>
                  <p className={classes["head-SelectSize-prod-DealOTheWeek"]}>
                    Planters
                  </p>
                  <div
                    className={classes["allSize-SelectSize-prod-DealOTheWeek"]}
                  >
                    <div
                      className={
                        classes["eachSize-SelectColor-prod-DealOTheWeek"]
                      }
                    >
                      <p></p>
                    </div>
                    <div
                      className={
                        classes["eachSize-SelectColor-prod-DealOTheWeek"]
                      }
                    >
                      <p> </p>
                    </div>
                    <div
                      className={
                        classes["eachSize-SelectColor-prod-DealOTheWeek"]
                      }
                    >
                      <p> </p>
                    </div>
                  </div>
                </div>
              </div> */}
                <div className={classes["active-btns-prod-DealOTheWeek"]}>
                  <Button
                    label="Add to Cart"
                    padding="15px"
                    background="#144D14"
                    borderRadius="10px"
                    color="white"
                    onClick={addItemToCartHandler}
                  />
                  <Link to="/cart">
                    <Button
                      label="Buy Now"
                      padding="15px"
                      background="#144D14"
                      borderRadius="10px"
                      color="white"
                      onClick={addItemToCartHandler}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className={classes["right-DealOTheWeek"]}>
              <img src={`${API_URL_HOST}/${data.plant.imageUrl}`} alt="" />
              {/* <svg
                className={classes["like-btn-prod-DealOTheWeek"]}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M11.8396 22.1204L11.8411 22.1215C12.0354 22.2541 12.2651 22.3253 12.5003 22.3257C12.7354 22.3253 12.9649 22.2543 13.1592 22.1219L13.1604 22.121C17.8281 18.9524 19.8185 16.802 20.8974 15.4874L11.8396 22.1204ZM11.8396 22.1204C7.17179 18.9518 5.18141 16.8013 4.10252 15.4868M11.8396 22.1204L4.10252 15.4868M24.2494 6.77648C24.2155 3.43772 21.539 0.75 18.3242 0.75C15.9824 0.75 14.3371 2.06401 13.3412 3.22351L13.3385 3.22662C13.2342 3.34665 13.1054 3.4429 12.9608 3.50887C12.8161 3.57483 12.659 3.60896 12.5 3.60896C12.341 3.60896 12.1839 3.57483 12.0392 3.50887C11.8946 3.44291 11.7658 3.34666 11.6615 3.22662L11.6591 3.22379L11.6591 3.22378C10.6628 2.065 9.01745 0.75 6.67579 0.75C3.46101 0.75 0.784561 3.43769 0.75063 6.77574L24.2494 6.77648ZM24.2494 6.77648C24.2798 9.83004 23.2074 12.6712 20.8975 15.4873L24.2494 6.77648ZM4.10252 15.4868C1.79151 12.6702 0.720242 9.82901 0.750629 6.77588L4.10252 15.4868Z"
                  stroke="#575757"
                  stroke-width="1.5"
                />
              </svg> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealOTheWeek;
