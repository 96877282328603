import React, { useRef } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from "../../assets/logo.png";
import classes from "./Registration.module.css";
import { API_URL } from '../../global-variables';

const AdminLogin = () => {
    const usernameRef = useRef();
    const passwordRef = useRef();

    const navigate = useNavigate()
    
    const handleAdminLogin = async(e) => {
     e.preventDefault();
    const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        
        const data={username,password}
        
        try {
          const response = await axios.post(
            `${API_URL}/admin/get-admin`,
            data
          );
          console.log(response)

          if (response.status === 200) {
            const token = response.data.token;
            localStorage.setItem("ux", token);
            navigate("/admin");
            return;
          }
          else {
            alert('Not Authorized to logged in !')
            return;
          }

            
        } catch (err) {
            alert('Error in logging in')
        }
    }
  return (
    <div className={classes["login-container"]}>
      <form
        className={`${classes["login-form"]} shadow-lg p-3 mb-5 bg-body-tertiary rounded`}
        onSubmit={(e) => handleAdminLogin(e)}
      >
        <div className="d-flex justify-content-center align-items-center">
          <Link to="/">
            <img src={logo} alt="logo" width={250} />
          </Link>
        </div>
        <h2 className="d-flex justify-content-center align-items-center">
          Admin Login
        </h2>

        <div className="mb-3">
          <label for="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-control"
            id="username"
            ref={usernameRef}
            required
          />
        </div>
        <div className="mb-3">
          <label for="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="pin"
            ref={passwordRef}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
}

export default AdminLogin