import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import SinglePlant from "../components/utils/EachPlantComp";
import { API_URL } from "../global-variables";

import classes from "../Plants/Plants.module.css";

const ShowPlantByCategory = (props) => {
  const [plantsList, setPlantsList] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  let limit = 16;

  const temp = props.category.split("-");
  const heading =
    temp[0].charAt(0).toUpperCase() + temp[0].slice(1) + " " + "Plants";

  useEffect(() => {
    const getPlants = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/shop/get-plants-by-category?category=${
            props.category
          }&page=${1}&limit=${limit}`
        );
        console.log("ressss", response.data);
        setPageCount(response.data.no_of_plants / limit);
        console.log("pla", response.data.no_of_plants);
        setPlantsList(response.data.plants);
      } catch (err) {
        console.log(err);
      }
    };
    getPlants();
  }, [props.category, limit]);

  // --------------------------------------------------------

  const fetchPlants = async (currentPage) => {
    const res = await axios.get(
      `${API_URL}/shop/get-plants-by-category?category=${props.category}&page=${currentPage}&limit=${limit}`
    );
    return res.data.plants;
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;

    const plantsFromServer = await fetchPlants(currentPage);
    console.log("secod", plantsFromServer);
    setPlantsList(plantsFromServer);
  };

  return (
    <React.Fragment>
      <div>
        <Helmet>
          <title>{props.metaTitle}</title>
          <meta name="description" content={props.metaDescr} />
        </Helmet>
        <div className={classes.section_heading}>
          <h2>{heading}</h2>
        </div>

        {!plantsList && (
          <h2 style={{ textAlign: "center" }}>No plants found</h2>
        )}

        <div className={classes.plants_container}>
          {plantsList.length === 0 && (
            <h4 style={{ textAlign: "center" }}>No plants to display</h4>
          )}
          {plantsList.map((card, i) => (
            <SinglePlant data={card} key={i} />
          ))}
        </div>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </React.Fragment>
  );
};

export default ShowPlantByCategory;
