import React, { useState, useRef } from "react";
import classes from "./Common.module.css";
import AdminHeader from "../Admin Panal/utils/AdminHeader";
import AdminSideNav from "../Admin Panal/utils/AdminSideNav";
import { Link, useNavigate } from "react-router-dom";

// import CancelOrderImg from "../../assets/cancelled.png";

import { useParams } from "react-router-dom";

import useApi from "../hooks/useapi";
import { modifyDate, postApiRequest } from "../util/helper";

import { API_URL, API_URL_HOST } from "../global-variables";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

const OrderDetailPage = () => {
  const orderId = useParams().orderId;
  const navigate = useNavigate()
  const statusRef = useRef();
  const ux = localStorage.getItem("ux");
  const { data, isLoading } = useApi(`${API_URL}/admin/get-order/${orderId}`, true);
  


  console.log("order", data);

  if (!isLoading) {
    data.order.date = modifyDate(data.order.createdAt);
    console.log(data.order.products);
  }

  const updateOrderHandler = async () => {
    console.log(statusRef.current.value);
    const status = statusRef.current.value;
    const body = { status };
            if (!ux) {
              navigate("/admin/login");
              return;
    }
            const headers = {
              Authorization: ux,
            };
    const response = await postApiRequest(
      `${API_URL}/admin/complete-order/${orderId}`,
      body,{headers}
    );
    console.log("status", response);
  };

  return (
    <AdminPanelWrapper>
      <div className={classes.main}>
        {isLoading && (
          <h3 style={{ textAlign: "center" }}>Loading order details...</h3>
        )}
        {data.order && (
          <section className=" d-flex  justify-content-around">
            <div className="col-lg-10 col-xl-8">
              <div className="card" style={{ borderRadius: "10px" }}>
                <div className="d-flex justify-content-between pt-2">
                  <p className="fw-bold mb-0">Order Details</p>
                </div>

                <div className="d-flex justify-content-between pt-2">
                  <p className="text-muted mb-0">Order Id: {data.order._id}</p>
                  <p className="text-muted mb-0">
                    User Name: {data.order.user.name}
                  </p>
                </div>

                <div className="d-flex justify-content-between">
                  <p className="text-muted mb-0">Date : {data.order.date}</p>
                  <p className="text-muted mb-0">
                    {" "}
                    User Phone: {data.order.user.phone}
                  </p>
                </div>

                <div className="d-flex justify-content-between mb-5">
                  <p className="text-muted mb-0">Status: {data.order.status}</p>
                </div>
                <p className="text-muted mb-2">
                  <span className="fw-bold me-4">Total</span> ₹{" "}
                  {data.order.orderValue}
                </p>
                <div className="text-muted mb-0">
                  <span className="fw-bold me-4">Address:</span>
                  <p className="mb-0">
                    {" "}
                    AddressLine: {data.order.user.address.addressLine}
                  </p>
                  <p className="mb-0">City: {data.order.user.address.city}</p>
                  <p className="mb-0">State: {data.order.user.address.state}</p>
                  <p className="mb-0">
                    Pincode: {data.order.user.address.pincode}
                  </p>
                </div>

                <div className="card-body p-4">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <p
                      className="lead fw-normal mb-0"
                      style={{ color: " #a8729a" }}
                    >
                      Products
                    </p>
                  </div>
                  <div>
                    {data.order.products.map((pro) => (
                      <div className="card mb-3" style={{ maxWidth: "540px" }}>
                        <div className="row g-0">
                          <div className="col-md-4">
                            <img
                              src={`${API_URL_HOST}/${pro.product.imageUrl}`}
                              className="img-fluid rounded-start"
                              alt="..."
                            />
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <h5 className="card-title">{pro.product.name}</h5>
                              <p>
                                type: <b>{pro.type}</b>
                              </p>
                              <h6 className="card-title">
                                Category: {pro.product.category}
                              </h6>
                              <h5 className="card-title">
                                Price: {pro.product.price}
                              </h5>
                              <p>
                                Quantity: <b>{pro.quantity}</b>
                              </p>
                              <p>{pro.product.size}</p>
                              <p className="card-text">
                                Description: {pro.product.description}
                              </p>
                              <Link
                                to={`/admin/nurseries/${pro.product.nurseryId}/details`}
                              >
                                <button type="button" className="btn btn-info">
                                  View Nursery
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="card-footer border-0 px-4 py-5"
                  style={{
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    backgroundColor: "#3f0097",
                  }}
                >
                  <h5 className="d-flex align-items-center justify-content-end text-white text-uppercase mb-0">
                    Total paid:{" "}
                    <span className="h2 mb-0 ms-2">
                      ₹{data.order.orderValue}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="shadow p-3 mb-5 bg-body-tertiary rounded p-5 "
              style={{
                border: "1px solid rgba(0,0,0,0.175)",
                maxHeight: "400px",
              }}
            >
              <h4>Change Status</h4>
              <select className="px-3 py-2 w-100 my-3" ref={statusRef}>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
              </select>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={updateOrderHandler}
                >
                  Update
                </button>
              </div>
            </div>
          </section>
        )}
      </div>
    </AdminPanelWrapper>
  );
};

export default OrderDetailPage;
