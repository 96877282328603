import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { API_URL } from "../global-variables";
import classes from "./Common.module.css";
import { postApiRequest } from "../util/helper";
import useApi from "../hooks/useapi";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

const Nurseries = () => {
  const inputNameRef = useRef();
  const inputPhoneRef = useRef();
  const inputStateRef = useRef();
  const inputCityRef = useRef();
  const inputAddRef = useRef();
  const inputPinRef = useRef();
  const inputPwdRef = useRef();
  const inputCnRef = useRef();
  const inputCpRef = useRef();
  const inputCeRef = useRef();
  const navigate = useNavigate();
    const ux = localStorage.getItem("ux");

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    const name = inputNameRef.current.value;
    const phone = inputPhoneRef.current.value;
    const password = inputPwdRef.current.value;

    const state = inputStateRef.current.value;
    const city = inputCityRef.current.value;
    const addressLine = inputAddRef.current.value;
    const pincode = inputPinRef.current.value;

    const nameC = inputCnRef.current.value;
    const phoneC = inputCpRef.current.value;
    const email = inputCeRef.current.value;

    const data = {
      name,
      phone,
      state,
      city,
      addressLine,
      pincode,
      password,
      personName: nameC,
      personPhone: phoneC,
      personEmail: email,
    };

    const url = `${API_URL}/admin/add-nursery`;
           if (!ux) {
             navigate("/admin/login");
             return;
    }
    const headers = {
      Authorization: ux,
    };
    const response = await postApiRequest(url, data,{headers});

    window.location.reload();
  };

  const { data, isLoading } = useApi(`${API_URL}/admin/get-nurseries`,true);

  return (
    <>
      <AdminPanelWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "space-between",
          }}
        >
          <div className={classes.nursery_heading_btn}>
            <h2>List of Nurseries</h2>
            <button
              type="button"
              className="btn btn-primary btn-lg"
              data-bs-toggle="modal"
              data-bs-target="#nurseryForm"
            >
              Add Nursery
            </button>
          </div>

          <div
            style={{
              width: "100%",
              // margin: "0 auto",
              marginTop: "50px",
              // border: "2px solid",
            }}
          >
            {isLoading && <p>Loading...</p>}

            <div
              className="d-flex flex-wrap"
              style={{ width: "80%", margin: "0 auto" }}
            >
              {data.nurseries &&
                data.nurseries.map((nursery) => (
                  <div
                    style={{ width: "650px" }}
                    className="p-3 m-2 shadow-lg p-3 mb-5 bg-body-tertiary rounded"
                    key={nursery._id}
                  >
                    <div className="d-flex justify-content-between m-2">
                      <h2>{nursery.name}</h2>
                      <div>
                        <button type="button" className="btn btn-success">
                          <Link to={`${nursery._id}/details`}>
                            View Details
                          </Link>
                        </button>
                      </div>
                    </div>
                    <div>{nursery.phone}</div>
                    <div
                      className="d-flex justify-content-between my-2"
                      // style={{ border: "2px solid" }}
                    >
                      <div>
                        <span className="m-1">
                          {nursery.address.addressLine}
                        </span>
                        <span className="m-1">{nursery.address.city}</span>
                        <span className="m-1">{nursery.address.state}</span>
                        <span className="m-1">{nursery.address.pincode}</span>
                      </div>
                      <button type="button" className="btn btn-success">
                        <Link to={`${nursery._id}`}>View Products</Link>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </AdminPanelWrapper>
      {/* Modal for creating a Nursery */}
      <form
        className="modal fade modal-dialog-scrollable modal-lg"
        id="nurseryForm"
        tabIndex="-1"
        aria-labelledby="nurseryFormLabel"
        aria-hidden="true"
        onSubmit={(e) => formSubmitHandler(e)}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="nurseryFormLabel">
                Create a Nursery
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className={classes["add-nursery-form"]}>
                <div className="mb-3">
                  <label htmlFor="nurseryName" className="form-label">
                    Name
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="nurseryName"
                    ref={inputNameRef}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="nurseryPhone" className="form-label">
                    Phone No
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="nurseryPhone"
                    ref={inputPhoneRef}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="nurseryPassword" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="nurseryPassword"
                    ref={inputPwdRef}
                    required
                  />
                </div>
                <div className={classes["heading_line-container"]}>
                  <p>Address</p>
                  <div className={classes.horizontalLine}></div>
                </div>
                <div className="mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    ref={inputCityRef}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <select
                    className="form-select"
                    aria-label="Select State"
                    ref={inputStateRef}
                    required
                  >
                    <option value="none">Select State</option>

                    <option value="Delhi">Delhi</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Haryana">Haryana</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="pincode" className="form-label">
                    Pincode
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="pincode"
                    ref={inputPinRef}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="addressline" className="form-label">
                    Address line
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="addressline"
                    ref={inputAddRef}
                    required
                  />
                </div>

                <div className={classes["heading_line-container"]}>
                  <p>Contact Person</p>
                  <div className={classes.horizontalLine}></div>
                </div>

                <div className="mb-3">
                  <label htmlFor="contactPName" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactPName"
                    ref={inputCnRef}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="contactPPhone" className="form-label">
                    Phone No.
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="contactPPhone"
                    ref={inputCpRef}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="contactPEmail" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="contactPEmail"
                    ref={inputCeRef}
                    required
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Create Nursery
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Nurseries;
