import React from "react";
import { Link } from "react-router-dom";
import "./Hero.css";
import banner1 from "../../assets/banners/banner-1.jpg";
import banner2 from "../../assets/banners/banner-2.jpg";
import banner3 from "../../assets/banners/banner-3.jpg";

// mobile banner

import banner1Mobile from "../../assets/banners/mobile-banner-1.jpg";
import banner2Mobile from "../../assets/banners/mobile-banner-2.jpg";
import banner3Mobile from "../../assets/banners/mobile-banner-3.jpg";

const Hero = () => {
  return (
    <div
      id="carouselExample"
      className="carousel slide min-height"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>

        <div className="carousel-item active">
          <img src={banner2} className="d-block w-100 hide-banner" alt="..." />
          <img
            src={banner2Mobile}
            className="d-block w-100 toggleBanner"
            alt="..."
          />

          <Link to="/all">
            <button className=" common-overlay-btn overlay-button_2">
              Shop Now
            </button>
          </Link>
        </div>

        <div className="carousel-item">
          <img src={banner1} className="d-block w-100 hide-banner" alt="..." />
          <img
            src={banner1Mobile}
            className="d-block w-100 toggleBanner"
            alt="..."
          />
          <Link to="/hire_maali">
            <button className=" common-overlay-btn overlay-button_1">
              Book Now
            </button>
          </Link>
        </div>

        <div className="carousel-item">
          <img src={banner3} className="d-block w-100 hide-banner" alt="..." />
          <img
            src={banner3Mobile}
            className="d-block w-100 toggleBanner"
            alt="..."
          />
          <Link to="/plant_day_care">
            <button className="common-overlay-btn  overlay-button_3">
              Book Now
            </button>
          </Link>
        </div>
      </div>
      {/* <button
        className="carousel-control-prev carousel-custom_style"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next carousel-custom_style"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button> */}
    </div>
  );
};

export default Hero;

//  <Slider {...settings}>
//    {Sdata.map((value, index) => {
//      return (
//        <div className="hero-container text-center" key={index}>
//          <div className="hero-row">
//            <div className="hero-col-left">
//              <h1>{value.title}</h1>
//              <p className="p">{value.desc}</p>

//              <div className="btn-container-hero">
//                <Link to={value.button} className="btn-hero">
//                  {value.buttonText}
//                </Link>
//              </div>

//              <div className="slider-footer">
//                <p className="p">{Sdata[currentSlideIndex].bottomText}</p>
//              </div>
//            </div>

//            <div className="hero-col-right">
//              <img src={value.cover} alt="" className="slider-image" />
//            </div>
//          </div>
//        </div>
//      );
//    })}
//  </Slider>;
