import React, { useEffect, useState } from "react";
import "./Header.css";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import AccountIcn from "../../assets/account.png";
import { useSelector } from "react-redux";
import { API_URL } from "../../global-variables";
import { useDispatch } from "react-redux";
import { updateLength } from "../../store/LoggedInUser";
import { postApiRequest } from "../../util/helper";
import { updateLengthNonLoggedInUser } from "../../store/NonLoggedInUser";

// import Modal from "../utils/Modal";
import ErrorModal from "../utils/UI/ErrorModal";
import CartIcn from "../../assets/cart.png";
const Header = () => {
  // const [logoutClicked, setLogoutClicked] = useState(false);
  const dispatch = useDispatch();
  const itemsLengthForUnloggedUser = useSelector(
    (state) => state.NonLoggedInUser.length
  );

  const itemsLengthForLoggedUser = useSelector(
    (state) => state.LoggedInUser.length
  );
  const [showValidationMessage, setShowValidationMessage] = useState(null);
  const uId = localStorage.getItem("userId");
  const initialState = uId ? uId : null;
  const [userId, setUserId] = useState(initialState);

  const [cartItemsLength, setCartItemsLength] = useState(0);

  useEffect(() => {
    let noOfItems;
    if (!userId) {
      console.log("user not logged in");
      noOfItems = itemsLengthForUnloggedUser;
    } else {
      if (typeof itemsLengthForLoggedUser === Number)
        console.log("------------", itemsLengthForLoggedUser);
      noOfItems = itemsLengthForLoggedUser;
    }
    setCartItemsLength(noOfItems);
  }, [itemsLengthForLoggedUser, itemsLengthForUnloggedUser, userId]);

  useEffect(() => {
    const initializeCartItems = async () => {
      if (userId) {
        const cartLength = await postApiRequest(
          `${API_URL}/shop/get-cart-length`,
          {
            userId: userId,
            cartId: null,
          }
        );

        dispatch(updateLength(cartLength.length));
      }

      const cartId = localStorage.getItem("cartId");

      if (!userId && cartId) {
        const cartLength = await postApiRequest(
          `${API_URL}/shop/get-cart-length`,
          {
            userId: null,
            cartId: cartId,
          }
        );
        dispatch(updateLengthNonLoggedInUser(cartLength.length));
      }
    };

    initializeCartItems();
  });

  const handleLogout = () => {
    setShowValidationMessage("Are you want to logout ?");
  };

  const closeValidationModal = () => {
    setShowValidationMessage(null);
  };

  const onClickOk = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("cartId");
    setUserId(null);
    console.log("USER LOGOUT");
    window.location.reload();
  };

  return (
    <>
      <ErrorModal
        isOpen={!!showValidationMessage}
        message={showValidationMessage}
        onClose={closeValidationModal}
        onClickOk={onClickOk}
        FromHeader={true}
      />
      <nav
        className="navbar navbar-expand-lg bg-body-tertiary"
        style={{ padding: "30px 0px" }}
      >
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            {/* logo */}
            <img src={logo} alt="logo" width={100} />
          </Link>
          <div>
            {/* Cart */}
            {/* <span className="mx-2 mobile-visible">
              <Link to="/cart">
                <ShoppingCartIcon />
              </Link>
            </span> */}
            <div className="cart-icon mx-2 ">
              <Link to="/cart" className="mobile-visible">
                {/* <i className="fas fa-shopping-cart"></i> */}
                <img src={CartIcn} alt="cart" width={40} />
                <span className="badge">
                  {cartItemsLength === 0 ? "" : cartItemsLength}
                </span>
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav justify-content-center me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                {/* Home */}
                <li>
                  <Link to="/" className="nav-link active" aria-current="page">
                    Home
                  </Link>
                </li>
              </li>

              {/* Plants */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Plants
                </Link>

                <ul className="dropdown-menu">
                  {/* <li>
                    <Link to="/all" className="dropdown-item">
                      All Plants
                    </Link>
                  </li> */}
                  {/* <li>
                    <hr className="dropdown-divider" />
                  </li> */}

                  <li>
                    <Link to="/indoor_plants" className="dropdown-item">
                      Indoor Plants
                    </Link>
                  </li>

                  <li>
                    <Link to="/outdoor_plants" className="dropdown-item">
                      Outdoor Plants
                    </Link>
                  </li>
                  <li>
                    <Link to="/hanging_plants" className="dropdown-item">
                      Hanging Plants
                    </Link>
                  </li>

                  <li>
                    <Link to="/monsoon_plants" className="dropdown-item">
                      Monsoon Plants
                    </Link>
                  </li>
                  <li>
                    <Link to="/flowering_plants" className="dropdown-item">
                      Flowering Plants
                    </Link>
                  </li>
                  <li>
                    <Link to="/fruit_plants" className="dropdown-item">
                      Fruit Plants
                    </Link>
                  </li>
                  <li>
                    <Link to="/air_purifying_plants" className="dropdown-item">
                      Air Purifying Plants
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Services */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/hire_maali" className="dropdown-item">
                      Book a Maali
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/rent_plant" className="dropdown-item">
                      Rent-A-Plant
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/plant_day_care" className="dropdown-item">
                      Plant Day Care
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                {/* About Us */}
                <li>
                  <Link
                    to="/about_us"
                    className="nav-link active"
                    aria-current="page"
                  >
                    About Us
                  </Link>
                </li>
              </li>

              {/* Blog */}
              {/* <li className="nav-item">
                
                <li>
                  <Link
                    to="/blog"
                    className="nav-link active"
                    aria-current="page"
                  >
                    Blog
                  </Link>
                </li>
              </li> */}

              {/* <li className="nav-item"> */}
              {/* Contact Us */}
              {/* <li>
                  <Link
                    to="/contact-us"
                    className="nav-link active"
                    aria-current="page"
                  >
                    Contact Us
                  </Link>
                </li> */}
              {/* </li> */}

              {/* {orders} */}
              <li className="nav-item">
                <li>
                  <Link
                    to="/user/orders"
                    className="nav-link active"
                    aria-current="page"
                  >
                    Orders
                  </Link>
                </li>
              </li>

              {/* {admin-panel} */}
              {/* <li className="nav-item">
                <Link
                  to="/admin"
                  className="nav-link active"
                  aria-current="page"
                >
                  Admin
                </Link>
              </li> */}

              {/* logout */}
              {userId && (
                <li className="nav-item">
                  <Link
                    onClick={handleLogout}
                    className="nav-link active"
                    aria-current="page"
                  >
                    Logout
                  </Link>
                </li>
              )}
            </ul>

            {/* Registration */}
            <span className="mx-2">
              <Link to="/login" className="header-account-icon">
                {/* <AccountCircleIcon /> */}
                <img src={AccountIcn} alt="account" width={30} />
              </Link>
            </span>

            {/* Cart */}
            {/* <span className="mx-2">
              <Link to="/cart">
                <ShoppingCartIcon />
              </Link>
            </span> */}
            <div className="cart-icon mx-2">
              <Link to="/cart">
                {/* <i className="fas fa-shopping-cart"></i> */}
                <img src={CartIcn} alt="cart" width={40} />
                <span className="badge">
                  {cartItemsLength === 0 ? "" : cartItemsLength}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Search Nav  */}
      <nav className="mb-3">
        <div className="search container-fluid">
          {/* Search Icon  */}
          <form className="d-flex" role="search">
            <div
              id="category"
              className="dropdown d-flex align-items-center px-2 border-0 m-0"
            >
              <span
                className="dropdown-toggle px-auto   m-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Category
              </span>
              <ul className="dropdown-menu">
                {/* <li>
                  <Link to="/all" className="dropdown-item">
                    All Plants
                  </Link>
                </li> */}
                {/* <li>
                  <hr className="dropdown-divider" />
                </li> */}

                <li>
                  <Link to="/indoor_plants" className="dropdown-item">
                    Indoor Plants
                  </Link>
                </li>
                <li>
                  <Link to="/hanging_plants" className="dropdown-item">
                    Hanging Plants
                  </Link>
                </li>
                {/* <li>
                  <Link to="/aquatic_plants" className="dropdown-item">
                    Aquatic Plants
                  </Link>
                </li> */}
                <li>
                  <Link to="/monsoon_plants" className="dropdown-item">
                    Monsoon Plants
                  </Link>
                </li>
                <li>
                  <Link to="/flowering_plants" className="dropdown-item">
                    Flowering Plants
                  </Link>
                </li>
                <li>
                  <Link to="/fruit_plants" className="dropdown-item">
                    Fruit Plants
                  </Link>
                </li>
                <li>
                  <Link to="/air_purifying_plants" className="dropdown-item">
                    Air Purifying Plants
                  </Link>
                </li>
              </ul>
            </div>
            <input
              className="form-control border-0"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button id="search-icon" className="btn" type="submit">
              <SearchIcon />
            </button>
          </form>
        </div>
      </nav>
    </>
  );
};

export default Header;

//  const dispatch = useDispatch();
//  const [logoutClicked, setLogoutClicked] = useState(false);
//  const itemsLengthForUnloggedUser = useSelector(
//    (state) => state.NonLoggedInUser.length
//  );

//  const itemsLengthForLoggedUser = useSelector(
//    (state) => state.LoggedInUser.length
//  );

//  const uId = localStorage.getItem("userId");
//  const initialState = uId ? uId : null;
//  const [userId, setUserId] = useState(initialState);
//  const [cartItemsLength, setCartItemsLength] = useState(0);

//  useEffect(() => {
//    let noOfItems;
//    if (!userId) {
//      console.log("user not logged in");

//      noOfItems = itemsLengthForUnloggedUser;
//    } else {
//      if (typeof itemsLengthForLoggedUser === Number)
//        // console.log("------------", itemsLengthForLoggedUser);
//        noOfItems = itemsLengthForLoggedUser;
//    }
//    setCartItemsLength(noOfItems);
//  }, [itemsLengthForLoggedUser, itemsLengthForUnloggedUser, userId]);

//  useEffect(() => {
//    const initializeCartItems = async () => {
//      if (userId) {
//        const cartLength = await postApiRequest(
//          `${API_URL}/shop/get-cart-length`,
//          {
//            userId: userId,
//            cartId: null,
//          }
//        );

//        dispatch(updateLength(cartLength.length));
//      }

//      const cartId = localStorage.getItem("cartId");

//      if (!userId && cartId) {
//        const cartLength = await postApiRequest(
//          `${API_URL}/shop/get-cart-length`,
//          {
//            userId: null,
//            cartId: cartId,
//          }
//        );
//        dispatch(updateLengthNonLoggedInUser(cartLength.length));
//      }
//    };

//    initializeCartItems();
//  });

//  const handleLogout = () => {
//    setLogoutClicked(true);
//  };

//  if (logoutClicked) {
//    console.log("inside isUserFound");
//    const performFinalSteps = () => {
//      localStorage.removeItem("userId");
//      localStorage.removeItem("cartId");
//      setUserId(null);
//      setLogoutClicked(false);
//      // window.location.reload();
//    };

//    return (
//      <div style={{ border: "2px solid", padding: "1rem" }}>
//        <Modal>
//          <h4 style={{ textAlign: "center" }}>Are you sure want to logout ?</h4>
//          <div className="d-flex justify-content-around p-3 my-2">
//            <button
//              type="button"
//              class="btn btn-success"
//              onClick={performFinalSteps}
//            >
//              Yes
//            </button>
//            <button
//              type="button"
//              class="btn btn-danger"
//              onClick={() => setLogoutClicked(false)}
//            >
//              No
//            </button>
//          </div>
//        </Modal>
//      </div>
//    );
//  }
