import React from "react";
import classes from "../Common.module.css";
import styles from "../Services.module.css";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useapi";
import { API_URL } from "../../global-variables";
import { modifyDate } from "../../util/helper";
import AdminPanelWrapper from "../utils/AdminPanelWrapper";

const MaaliServices = () => {
  const { data, isLoading } = useApi(`${API_URL}/admin/get-mali-services`,true);

  if (!isLoading) {
    console.log(data);

    data.services.forEach((service) => {
      console.log(service.createdAt);
      const date = modifyDate(service.createdAt);
      console.log(date);
      service.serviceBookedOn = date;
    });
  }

  return (
    <AdminPanelWrapper>
      <div className={styles.main_container}>
        <div className={classes["report-container"]}>
          <div className={classes["report-header"]}>
            <h1 className={classes["recent-Articles"]}>
              Recent Maali Services
            </h1>
          </div>

          <div className={classes.services_data_container}>
            <table>
              <tr>
                <th>Id</th>
                <th> Type</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
              {isLoading && <h3>Loading...</h3>}
              {data.services &&
                data.services.map((service) => (
                  <tr>
                    <td>{service._id}</td>
                    <td>{service.type}</td>
                    <td>{service.serviceBookedOn}</td>
                    <td>{service.status}</td>
                    <td>{}</td>
                    <td>
                      <Link to={`/admin/services/maali/${service._id}`}>
                        <button type="button" className="btn btn-success">
                          View Order
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </AdminPanelWrapper>
  );
};

export default MaaliServices;
