import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../global-variables";

import classes from "./Orders.module.css";
import useApi from "../../hooks/useapi";
import { modifyDate } from "../../util/helper";
import LoginAuth from "../../components/utils/LoginAuth";

const Orders = () => {
  const userId = localStorage.getItem("userId");
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  if (userId) {
    localStorage.removeItem("previousLocation");
  }

  if (!userId) {
    localStorage.setItem("previousLocation", window.location.pathname);
  }

  useEffect(() => {
    if (userId) {
      const url = `${API_URL}/shop/get-orders/${userId}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log("orders", data);
          const myOrders = data.orders;

          myOrders.forEach((order) => {
            const date = modifyDate(order.createdAt);
            order.date = date;
          });

          setOrders(data.orders);
          setIsLoading(false);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      setIsLoading(false);
    }
  }, [userId]);

  return (
    <React.Fragment>
      {!userId && <LoginAuth />}
      {orders && (
        <div className={classes.tableContainer}>
          <h1 style={{ textAlign: "center" }}>My Orders</h1>
          <table className={classes.table}>
            <thead>
              <tr>
                <th scope="col">Order Id</th>
                <th scope="col">Date</th>
                <th scope="col">Order Value</th>
                <th scope="col">Status</th>
                <th scope="col">Details</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <h4>Your orders are on the way....</h4>}
              {orders &&
                orders.map((order) => (
                  <tr key={order.id}>
                    <td data-label="OrderId">{order._id}</td>
                    <td data-label="Date">{order.date}</td>
                    <td data-label="Order Value">{order.orderValue}</td>
                    <td data-label="Status">{order.status}</td>
                    <td>
                      <Link to={`/user/orders/${order._id}`}>
                        <button type="button" className="btn btn-info">
                          View Details
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
};

// const Orders = () => {
//   const userId = localStorage.getItem("userId");

//   const url = `${API_URL}/shop/get-orders/${userId}`;

//   const { data, isLoading } = useApi(url);
//   if (!isLoading && data.orders) {
//     console.log("orders", data);
//     const myOrders = data.orders;

//     myOrders.forEach((order) => {
//       const date = modifyDate(order.createdAt);
//       order.date = date;
//     });
//   }

//   return (
//     <>
//       {!userId ? (
//         <div
//           style={{
//             width: "50%",
//             padding: "30px",
//             margin: "0 auto",
//             marginTop: "8rem",
//           }}
//           className="shadow-lg p-3 mb-5 bg-body-tertiary rounded"
//         >
//           <h4>You are not logged in, please login to continue</h4>
//           <button
//             type="button"
//             className="btn btn-primary"
//             style={{ marginTop: "20px" }}
//           >
//             <Link to="/login">Login/SignUp</Link>
//           </button>
//         </div>
//       ) :  <div className={classes.tableContainer}>
//       <h1 style={{ textAlign: "center" }}>My Orders</h1>
//       <table className={classes.table}>
//         <thead>
//           <tr>
//             <th scope="col">OrderId</th>
//             <th scope="col">Date</th>
//             <th scope="col">Order Value</th>
//             <th scope="col">Status</th>
//             <th scope="col">Details</th>
//           </tr>
//         </thead>
//         <tbody>
//           {isLoading && <h4>Your orders are on the way....</h4>}
//           {data.orders &&
//             data.orders.map((order) => (
//               <tr key={order.id}>
//                 <td data-label="OrderId">{order._id}</td>
//                 <td data-label="Date">{order.date}</td>
//                 <td data-label="Order Value">{order.orderValue}</td>
//                 <td data-label="Status">{order.status}</td>
//                 <td>
//                   <Link to={`/user/orders/${order._id}`}>
//                     <button type="button" className="btn btn-info">
//                       View Details
//                     </button>
//                   </Link>
//                 </td>
//               </tr>
//             ))}
//         </tbody>
//       </table>
//     </div> }

//     </>
//   );
// };

export default Orders;
