import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import classes from "./CarouselComponent.module.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
const CarouselComponent = ({ children }) => {
    console.log(children.props,'hello')
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 550 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 550, min: 0 },
            items: 1
        }
    };
    const ButtonGroup = ({ next, previous, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
            <>
                <button className={currentSlide === 0 ? classes.carousel_button_left_hidden : `${classes.carousel_button} ${classes.carousel_button_left}`} onClick={() => previous()}><IoIosArrowBack size={35} /></button>
                <button className={`${classes.carousel_button} ${classes.carousel_button_right}`} onClick={() => next()} ><IoIosArrowForward size={35} /></button>
            </>
        );
    };
    return (
        <Carousel
        responsive={responsive}
        customButtonGroup={<ButtonGroup />}
        swipeable={false}
        draggable={false}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all ease-in 0.5s"
        transitionDuration={500}
        containerClass="carousel-container-best"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px" >
            {children}
        </Carousel>
        // <div>
        //     <Carousel
        //     responsive={responsive}
        //     containerClass="carousel-container"
        //     itemClass="carousel-item-padding-40-px"
        //     keyBoardControl={true}
        //     customTransition="all ease-in 0.5s"
        //     removeArrowOnDeviceType={["tablet", "mobile"]}
        //     autoPlaySpeed={1000}
        //     swipeable={false}
        //     draggable={false}
        //     showDots={true}
        //     transitionDuration={500}>
        //     <div><img src={PlantImage} alt="plant"/></div>
        //     <div><img src={PlantImage} alt="plant"/></div>
        //     <div><img src={PlantImage} alt="plant"/></div>
        //     <div><img src={PlantImage} alt="plant"/></div>
        //     <div><img src={PlantImage} alt="plant"/></div>
        //     <div><img src={PlantImage} alt="plant"/></div>
        //     <div><img src={PlantImage} alt="plant"/></div>
        //     <div><img src={PlantImage} alt="plant"/></div>
        //     <div><img src={PlantImage} alt="plant"/></div>
        //     </Carousel>
        // </div>
    )
}
export default CarouselComponent







