import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import classes from "./Common.module.css";
import AdminPanelWrapper from './utils/AdminPanelWrapper'
import axios from "axios";

import { API_URL, API_URL_HOST } from "../global-variables";

const PotPage = () => {
  const potId = useParams().potId;
  const [potDetails, setPotDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
  const ux = localStorage.getItem("ux");

  useEffect(() => {
    try {
              if (!ux) {
                navigate("/admin/login");
                return;
      }
              const headers = {
                Authorization: ux,
              };
      const response = axios
        .get(`${API_URL}/admin/get-pot/${potId}`,{headers})
        .then((res) => {
          console.log("pot details", res.data);
          setPotDetails(res.data);
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
      alert(err.message);
      setIsLoading(false);
    }
  }, [potId]);
  return (
 
        <AdminPanelWrapper>
        <div className={classes.main}>
          {isLoading && <p>Hold on...Loading</p>}
          {potDetails && (
            <div
              // style={{ border: "2px solid" }}
              className="p-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded"
              key={potDetails.pot._id}
            >
              <div className="d-flex justify-content-around">
                <div>
                  <img
                    src={`${API_URL_HOST}/${potDetails.pot.imageUrl}`}
                    alt=""
                    width={800}
                  />
                </div>
                <div
                  //   style={{ border: "2px solid" }}
                  className="d-flex flex-column p-5 "
                >
                  <div className="">
                    <h2 className="my-3">Name: {potDetails.pot.name}</h2>
                    <p className="my-3"> Id: {potDetails.pot._id}</p>
                    <p className="my-3">
                      NurseryId: {potDetails.pot.nurseryId}
                    </p>
                  </div>

                  <div className="">
                    <p className="my-3">Category: {potDetails.pot.category}</p>
                    <p className="my-3">Size: {potDetails.pot.size}</p>
                  </div>
                  <div className="">
                    <p className="my-3">
                      Description: {potDetails.pot.description}
                    </p>
                  </div>
                  <div className="d-flex  justify-content-between my-4">
                    <button type="button" className="btn btn-info btn-lg">
                      Edit
                    </button>
                    <button type="button" className="btn btn-danger btn-lg">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        </AdminPanelWrapper>

  );
};

export default PotPage;
