import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./Registration.module.css";
import { API_URL } from "../../global-variables";
import ErrorModal from "../utils/UI/ErrorModal";

import logo from "../../assets/logo.png";
import { postApiRequest } from "../../util/helper";
const SignUp = () => {
  const [error, setError] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const navigate = useNavigate();
  const userNameRef = useRef();
  const phoneRef = useRef();
  const pinRef = useRef();
  const addressRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();

  const handleUserSignUp = async (e) => {
    e.preventDefault();
    const name = userNameRef.current.value;
    const phone = phoneRef.current.value;
    const pincode = pinRef.current.value;
    const addressLine = addressRef.current.value;
    const state = stateRef.current.value;
    const city = cityRef.current.value;

    const cartId = localStorage.getItem("cartId");
    let emptyCart = cartId ? false : true;

    const user = {
      name,
      phone,
      pincode,
      addressLine,
      state,
      city,
      cartId,
      emptyCart,
    };

    const url = `${API_URL}/auth/add-user`;

    try {
      const response = await postApiRequest(url, user);
      console.log("user adding", response.user._id);
      if (response) {
        // alert("User successfully created...");

        // Store the userId in localStorage
        localStorage.setItem("userId", response.user._id);
        localStorage.removeItem("cartId");

        setModalMessage("User successfully created...");
      }
    } catch (err) {
      console.log("error catched", err);
      setError(err.response.data.message);
    }
  };
  const closeErrorModal = () => {
    setError(null);
  };
  const closeMessageModal = () => {
    setModalMessage(null);
  };
  const onClickOk = () => {
    console.log("inside onclickon");
    const previousLocation = localStorage.getItem("previousLocation");
    if (previousLocation) {
      navigate(previousLocation);
    } else {
      navigate("/"); // Default redirect
    }
  };
  return (
    <>
      <ErrorModal
        isOpen={!!modalMessage}
        message={modalMessage}
        onClose={closeMessageModal}
        onClickOk={onClickOk}
      />
      <ErrorModal isOpen={!!error} message={error} onClose={closeErrorModal} />
      <div className={classes["signup-container"]}>
        <form
          className={`${classes["signup-form"]} shadow-lg p-3 mb-5 bg-body-tertiary rounded`}
          onSubmit={(e) => handleUserSignUp(e)}
        >
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/">
              <img src={logo} alt="logo" className={classes.logo} />
            </Link>
          </div>
          <h2 className="d-flex justify-content-center align-items-center">
            User SignUp
          </h2>
          <div className="mb-3">
            <label for="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              ref={userNameRef}
              required
            />
          </div>
          <div className="mb-3">
            <label for="phone" className="form-label">
              Phone
            </label>
            <input
              type="number"
              className="form-control"
              id="phone"
              ref={phoneRef}
              required
            />
          </div>
          <div className="mb-3">
            <label for="pin" className="form-label">
              Pincode
            </label>
            <input
              type="number"
              className="form-control"
              id="pin"
              ref={pinRef}
              required
            />
          </div>
          <div className="mb-3">
            <label for="address" className="form-label">
              Address Line
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              ref={addressRef}
              required
            />
          </div>
          <div className="mb-3">
            <label for="state" className="form-label">
              State
            </label>
            <select
              className="form-select"
              aria-label="Select State"
              ref={stateRef}
              required
            >
              <option value="none">Select State</option>
              <option value="Delhi">Delhi</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Haryana">Haryana</option>
            </select>
          </div>
          <div className="mb-3">
            <label for="city" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              ref={cityRef}
              required
            />
          </div>

          <Link
            to="/login"
            style={{ color: "blue", marginRight: "10px", fontWeight: "bold" }}
          >
            Click Here To Login
          </Link>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default SignUp;
