import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import SinglePlant from "../../components/utils/EachPlantComp";
import { API_URL } from "../../global-variables";
import useApi from '../../hooks/useapi';
import "./NewArrival.css";
import CarouselComponent from "../../components/CarouselComponent/CarouselComponent";
const NewArrival = (props) => {
  const url = `${API_URL}/shop/get-newarival-plants`;
  const { data, isLoading } = useApi(url);

  if (!isLoading) {
    // console.log("newarr", data);
  }
  return (
    <React.Fragment>
      <div className="new-arrivals-container">
        <div
          className=" innerWidth r-container"
          style={{ width: "90%", margin: "0 auto" }}
        >
          <div className="flexColStart r-head">
            {" "}
            <h5 style={{ marginLeft: "55px" }}>
              <b>NEW ARRIVALS</b>
            </h5>
          </div>
          {isLoading && <h4>Loading...</h4>}
          {data.plants && (
            <div>
              <CarouselComponent>
                { data.plants.map((card, i) => (
                  <SinglePlant key={i} data={card} />
                ))}
              </CarouselComponent>
            </div>
          )}
        </div>
      </div>
      {/* mobile view  */}
      <div className="mobile-view__new-arrivals">
        <h5 style={{ marginLeft: "55px" }}>
          <b>NEW ARRIVALS</b>
        </h5>
        <div
          className="d-flex justify-content-center align-items-center flex-wrap"
          style={{
            gap: "0px",
            width: "100%",
            margin: "0 auto",
            marginLeft: "12px",
          }}
        >
           {isLoading && <h4>Loading...</h4>}
          {data.plants &&
            data.plants.map((card, i) => <SinglePlant data={card} />)}
        </div>
      </div>
    </React.Fragment>
  );
};
export default NewArrival;







