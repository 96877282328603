import React, { useRef } from "react";
import classes from "../Common.module.css";
import styles from "../Services.module.css";
import { useParams, useNavigate } from "react-router-dom";
import useApi from "../../hooks/useapi";
import { API_URL } from "../../global-variables";
import { postApiRequest } from "../../util/helper";
import { modifyDate } from "../../util/helper";
import AdminPanelWrapper from '../utils/AdminPanelWrapper'

const MaaliServiceDetailsPage = () => {
  const maaliId = useParams().maaliId;
  console.log(maaliId);
    const navigate = useNavigate();

  const statusRef = useRef();


  const { data, isLoading } = useApi(
    `${API_URL}/admin/get-mali-service-detail/${maaliId}`,true
  );

  if (!isLoading) {
    console.log("mmmmmmmmmmm", data);
    const date = modifyDate(data.service.createdAt);
    data.service.serviceBookedOn = date;

    const date2=modifyDate(data.service.date)
    data.service.formattedDate=date2;
  }
  const ux = localStorage.getItem("ux");
  const updateOrderHandler = async () => {
    console.log(statusRef.current.value);
    const status = statusRef.current.value;
    const body = { status };
            if (!ux) {
              navigate("/admin/login");
              return;
    }
            const headers = {
              Authorization: ux,
            };
    const response = await postApiRequest(
      `${API_URL}/admin/post-update-mali-service/${maaliId}`,
      body,{headers}
    );

    if (response.service) {
      window.location.reload();
    }
    console.log("status", response);
  };
  return (


        <AdminPanelWrapper>
        <div className={styles.main_container}>
          {isLoading && <h3>Loading...</h3>}

          {data.service && (
            <section className=" d-flex  justify-content-around">
              <div className="col-lg-10 col-xl-8">
                <div className="card" style={{ borderRadius: "10px" }}>
                  <div className="d-flex ju/stify-content-between pt-2">
                    <p className="text-muted mb-0">
                      Service Id: {data.service._id}
                    </p>
                  </div>

                  <div
                    className={`${
                      data.service.status === "pending"
                        ? classes["status-pending"]
                        : classes["status-completed"]
                    } my-3`}
                  >
                    <p className="text-muted">
                      Booked On: {data.service.serviceBookedOn}
                    </p>
                    <h4>
                      <b>Status</b>: {data.service.status}
                    </h4>
                  </div>

                  <div className="card-body p-4">
                    <div>
                      <h4 style={{ textAlign: "center" }}>Service Details</h4>
                      <div
                        style={{ border: "1px solid grey" }}
                        className="shadow-sm p-3 mb-5 bg-body-tertiary rounded"
                      >
                        <div className="d-flex justify-content-around">
                          <p>
                            <b>type</b>: {data.service.type}
                          </p>
                          <p>
                            <b>Hours</b>: {data.service.basics}
                          </p>
                          <p>
                            <b>Number Of Weeks</b>:{" "}
                            {data.service.noOfWeeks
                              ? data.service.noOfWeeks
                              : "NA"}
                          </p>
                          <p>
                            <b>Date</b>: {data.service.formattedDate}
                          </p>
                          <p>
                            <b>Time</b>: {data.service.time}
                          </p>
                          <p>
                            <b>Description</b>: {data.service.description?data.service.description:null}
                          </p>
                        </div>
                      </div>
                      <h4 style={{ textAlign: "center" }}>Customer Details</h4>
                      <div
                        style={{ border: "1px solid grey" }}
                        className="shadow-sm p-3 mb-5 bg-body-tertiary rounded"
                      >
                        <div className="d-flex justify-content-around">
                          <p>
                            {" "}
                            <b>Name</b>: {data.service.name}
                          </p>
                          <p>
                            {" "}
                            <b>Phone</b>: {data.service.phone}
                          </p>
                        </div>
                        <div className="d-flex justify-content-around">
                          <p>
                            <b>Address Line 1</b>:{" "}
                            {data.service.address.addressLine1}
                          </p>
                          <p>
                            <b>Address Line 2</b>:{" "}
                            {data.service.address.addressLine2}
                          </p>
                        </div>
                        <div className="d-flex justify-content-around">
                          <p>
                            {" "}
                            <b>City</b>: {data.service.address.city}
                          </p>
                          <p>
                            {" "}
                            <b>State</b>: {data.service.address.state}
                          </p>
                        </div>
                        <div className="d-flex justify-content-around">
                          {" "}
                          <p>
                            {" "}
                            <b>Pincode</b>: {data.service.address.pincode}
                          </p>
                          <p>
                            {" "}
                            <b>Total Amount</b>:₹ {data.service.totalAmount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="shadow p-3 mb-5 bg-body-tertiary rounded p-5 "
                style={{
                  border: "1px solid rgba(0,0,0,0.175)",
                  maxHeight: "400px",
                }}
              >
                <h4>Change Status</h4>
                <select className="px-3 py-2 w-100 my-3" ref={statusRef}>
                  <option value="pending">Pending</option>
                  <option value="completed">Completed</option>
                </select>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={updateOrderHandler}
                  >
                    Update
                  </button>
                </div>
              </div>
            </section>
          )}
        </div>
        </AdminPanelWrapper>

  );
};

export default MaaliServiceDetailsPage;
