import React, { useState, useRef, useEffect } from "react";
import classes from "./MaaliForm.module.css";
import { useNavigate } from "react-router-dom";
import {
  postApiRequest,
  changeDateFormat,
  getNextDate,
} from "../../util/helper";
import ErrorModal from "../../components/utils/UI/ErrorModal";
import { API_URL } from "../../global-variables";
import useGst from "../../hooks/useGst";

function MaaliForm() {
  const navigate = useNavigate();
  const minDate = getNextDate();
  const [showValidationMessage, setShowValidationMessage] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [basics, setBasics] = useState("");
  const [formData, setFormData] = useState({
    type: "",
    basics: "",
    name: "",
    phone: "",
    noOfWeeks: "",
    date: "",
    time: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    city: "",
    state: "",
    description: "",
  });

  const onChangeHandler = (type, value) => {
    if (type === "type") {
      setFormData((prev) => {
        return {
          ...prev,
          type: value,
        };
      });
    }
    if (type === "name") {
      setFormData((prev) => {
        return {
          ...prev,
          name: value,
        };
      });
    }

    if (type === "phone") {
      setFormData((prev) => {
        return {
          ...prev,
          phone: value,
        };
      });
    }
    if (type === "basics") {
      setBasics(value);
      setFormData((prev) => {
        return {
          ...prev,
          basics: value,
        };
      });
    }

    if (type === "noOfWeeks") {
      setFormData((prev) => {
        return {
          ...prev,
          noOfWeeks: value,
        };
      });
    }

    if (type === "date") {
      setFormData((prev) => {
        return {
          ...prev,
          date: value,
        };
      });
    }
    if (type === "time") {
      setFormData((prev) => {
        return {
          ...prev,
          time: value,
        };
      });
    }
    if (type === "addressLine1") {
      setFormData((prev) => {
        return {
          ...prev,
          addressLine1: value,
        };
      });
    }

    if (type === "addressLine2") {
      setFormData((prev) => {
        return {
          ...prev,
          addressLine2: value,
        };
      });
    }
    if (type === "pincode") {
      setFormData((prev) => {
        return {
          ...prev,
          pincode: value,
        };
      });
    }
    if (type === "city") {
      setFormData((prev) => {
        return {
          ...prev,
          city: value,
        };
      });
    }
    if (type === "state") {
      setFormData((prev) => {
        return {
          ...prev,
          state: value,
        };
      });
    }
    if (type === "description") {
      setFormData((prev) => {
        return {
          ...prev,
          description: value,
        };
      });
    }
  };

  const value = (totalAmount / 100) * 18 + totalAmount;
  const { totalGSTAmount } = useGst(value);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (formData.phone.length < 10) {
      // alert("Please provide valid phone number");
      // return;
      setShowValidationMessage("Please provide valid phone number");
      return;
    }

    if (formData.pincode.length < 6) {
      // alert("Please provide valid pin code");
      // return;
      setShowValidationMessage("Please provide valid pin code");
      return;
    }

    if (formData.basics === "") {
      setShowValidationMessage("Please provide valid hours");
      return;
    }

    const enteredTime = new Date(`1970-01-01T${formData.time}`);
    const minTime = new Date(`1970-01-01T7:00`);
    const maxTime = new Date(`1970-01-01T19:00`);

    if (enteredTime < minTime || enteredTime > maxTime) {
      setShowValidationMessage("Please provide time from 07:00 AM to 07:00 PM");
      return;
    }

    let total = Math.ceil((totalAmount * 18) / 100 + totalAmount);
    const data = {
      ...formData,
      totalAmount: total,
      
    };

    const response = await postApiRequest(
      `${API_URL}/services/post-mali-service`,
      data
    );

    if (response.service) {
      navigate(`/service-booked/maali/${total}`);
    }
  };

  useEffect(() => {
    const getPriceHandler = () => {
      console.log("form data submitted", formData);
      let total = totalAmount;

      if (formData.type === "oneTime") {
        const hours = +basics;
        if (hours === 1) {
          total = 249;
        }
        if (hours === 2) {
          total = 449;
        }
        if (hours === 3) {
          total = 649;
        }
        if (hours === 4) {
          total = 849;
        }
        if (hours === 5) {
          total = 1049;
        }
        if (hours === 6) {
          total = 1249;
        }
        if (hours === 7) {
          total = 1449;
        }
        if (hours === 8) {
          total = 1649;
        }
      }

      if (formData.type === "monthly") {
        const hours = +basics;

        if (hours === 32) {
          total = 3499;
        }

        if (hours === 64) {
          total = 9499;
        }

        if (hours === 95) {
          total = 15499;
        }
      }

      if (formData.type === "weekly") {
        const hours = +basics;
        const noOfWeeks = +formData.noOfWeeks;
        if (noOfWeeks === 1) {
          if (hours === 1) {
            total = 249;
          }
          if (hours === 2) {
            total = 449;
          }
          if (hours === 3) {
            total = 699;
          }
          if (hours === 4) {
            total = 899;
          }
          if (hours === 5) {
            total = 1099;
          }
          if (hours === 6) {
            total = 1299;
          }
          if (hours === 7) {
            total = 1499;
          }
          if (hours === 8) {
            total = 1699;
          }
        }
        if (noOfWeeks === 2) {
          if (hours === 1) {
            total = 449;
          }
          if (hours === 2) {
            total = 849;
          }
          if (hours === 3) {
            total = 1349;
          }
          if (hours === 4) {
            total = 1749;
          }
          if (hours === 5) {
            total = 2149;
          }
          if (hours === 6) {
            total = 2549;
          }
          if (hours === 7) {
            total = 2949;
          }
          if (hours === 8) {
            total = 3349;
          }
        }
        if (noOfWeeks === 3) {
          if (hours === 1) {
            total = 649;
          }
          if (hours === 2) {
            total = 1249;
          }
          if (hours === 3) {
            total = 2049;
          }
          if (hours === 4) {
            total = 2649;
          }
          if (hours === 5) {
            total = 3249;
          }
          if (hours === 6) {
            total = 3849;
          }
          if (hours === 7) {
            total = 4449;
          }
          if (hours === 8) {
            total = 5049;
          }
        }
        if (noOfWeeks === 4) {
          if (hours === 1) {
            total = 949;
          }
          if (hours === 2) {
            total = 1749;
          }
          if (hours === 3) {
            total = 2749;
          }
          if (hours === 4) {
            total = 3549;
          }
          if (hours === 5) {
            total = 4349;
          }
          if (hours === 6) {
            total = 5149;
          }
          if (hours === 7) {
            total = 5949;
          }
          if (hours === 8) {
            total = 6749;
          }
        }
      }

      setTotalAmount(total);
    };

    getPriceHandler();
  }, [basics, formData, totalAmount]);

  const closeMessageModal = () => {
    setShowValidationMessage(null);
  };

  return (
    <>
      <ErrorModal
        isOpen={!!showValidationMessage}
        message={showValidationMessage}
        onClose={closeMessageModal}
      />
      <div className={classes.container}>
        <form onSubmit={(e) => formSubmitHandler(e)}>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="service" className={classes.label}>
                Choose a service
              </label>
            </div>
            <div className={classes["col-75"]}>
              <select
                className={classes.select}
                id="floatingSelectGrid"
                onChange={(e) => onChangeHandler("type", e.target.value)}
                required
              >
                <option value="none">Select Option</option>
                <option value="oneTime">One time</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
          </div>
          {formData.type === "oneTime" && (
            <div className={classes.row}>
              <div className={classes["col-25"]}>
                <label for="oneTimeMaali" className={classes.label}>
                  Maali Service For one time
                </label>
              </div>
              <div className={classes["col-75"]}>
                <select
                  className={classes.select}
                  onChange={(e) => onChangeHandler("basics", e.target.value)}
                  required
                >
                  <option value="none">Select an option</option>
                  <option value="1">For 1 hour </option>
                  <option value="2">For 2 hour </option>
                  <option value="3">For 3 hour </option>
                  <option value="4">For 4 hour </option>
                  <option value="5">For 5 hour </option>
                  <option value="6">For 6 hour </option>
                  <option value="7">For 7 hour </option>
                  <option value="8">For 8 hour </option>
                </select>
              </div>
            </div>
            // </div>
          )}
          {formData.type === "monthly" && (
            <div className={classes.row}>
              <div className={classes["col-25"]}>
                <label for="monthly" className={classes.label}>
                  Maali Service On Monthly Basis
                </label>
              </div>
              <div className={classes["col-75"]}>
                <select
                  className={classes.select}
                  onChange={(e) => onChangeHandler("basics", e.target.value)}
                  required
                >
                  <option value="none">Select Option</option>
                  <option value="32">32 hours</option>
                  <option value="64">64 hours</option>
                  <option value="95">95 hours</option>
                </select>
              </div>
            </div>
            // </div>
          )}
          {formData.type === "weekly" && (
            <>
              <div className={classes.row}>
                <div className={classes["col-25"]}>
                  <label for="weekly-basic" className={classes.label}>
                    Maali Service On Weekly Basis
                  </label>
                </div>
                <div className={classes["col-75"]}>
                  <select
                    className={classes.select}
                    onChange={(e) => onChangeHandler("basics", e.target.value)}
                    required
                  >
                    <option value="none">Select no. of hours/week</option>

                    <option value="1">For 1 hour </option>
                    <option value="2">For 2 hour </option>
                    <option value="3">For 3 hour </option>
                    <option value="4">For 4 hour </option>
                    <option value="5">For 5 hour </option>
                    <option value="6">For 6 hour </option>
                    <option value="7">For 7 hour </option>
                    <option value="8">For 8 hour </option>
                  </select>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes["col-25"]}>
                  <label for="weeks" className={classes.label}>
                    Select Number of weeks
                  </label>
                </div>
                <div className={classes["col-75"]}>
                  <select
                    className={classes.select}
                    onChange={(e) =>
                      onChangeHandler("noOfWeeks", e.target.value)
                    }
                    required
                  >
                    <option value="none">Select no. of weeks</option>

                    <option value="1">For 1 week </option>
                    <option value="2">For 2 week </option>
                    <option value="3">For 3 week </option>
                    <option value="4">For 4 week </option>
                  </select>
                </div>
              </div>
            </>
          )}

          {/* <div className="d-flex align-items-center justify-content-end py-3">
          <button
            type="button"
            className="btn btn-success"
            ref={getPriceBtnRef}
          >
            Get Price
          </button>
        </div> */}
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="username" className={classes.label}>
                Name
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="text"
                id="username"
                name="username"
                className={classes.text}
                placeholder="Enter your name.."
                onChange={(e) => onChangeHandler("name", e.target.value)}
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="phone" className={classes.label}>
                Phone
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="number"
                id="phone"
                name="phone"
                className={classes.text}
                placeholder="Enter your phone.."
                onChange={(e) => onChangeHandler("phone", e.target.value)}
                required
              />
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="date" className={classes.label}>
                Date
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="date"
                className={classes.text}
                id="date"
                min={minDate}
                name="date"
                onChange={(e) => onChangeHandler("date", e.target.value)}
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="time" className={classes.label}>
                Time
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="time"
                id="appt"
                name="appt"
                min="07:00"
                className={classes.text}
                max="19:00"
                required
                onChange={(e) => onChangeHandler("time", e.target.value)}
              />

              <small>Choose between 7am to 7pm</small>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="addressLine1" className={classes.label}>
                Address Line 1
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="text"
                id="addressLine1"
                name="addressLine1"
                className={classes.text}
                placeholder="address line 1.."
                onChange={(e) =>
                  onChangeHandler("addressLine1", e.target.value)
                }
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="addressLine2" className={classes.label}>
                Address Line 2
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="text"
                id="addressLine2"
                name="addressLine2"
                className={classes.text}
                placeholder="address line 2.."
                onChange={(e) =>
                  onChangeHandler("addressLine2", e.target.value)
                }
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="pin" className={classes.label}>
                Pincode
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="number"
                id="pin"
                name="pin"
                className={classes.text}
                placeholder="Enter your pin code"
                onChange={(e) => onChangeHandler("pincode", e.target.value)}
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="city" className={classes.label}>
                City
              </label>
            </div>
            <div className={classes["col-75"]}>
              <input
                type="text"
                id="city"
                name="city"
                className={classes.text}
                placeholder="city.."
                onChange={(e) => onChangeHandler("city", e.target.value)}
                required
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="state" className={classes.label}>
                State
              </label>
            </div>
            <div className={classes["col-75"]}>
              <select
                id="state"
                name="state"
                className={classes.select}
                onChange={(e) => onChangeHandler("state", e.target.value)}
                required
              >
                <option value="none">Select state</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Delhi">Delhi</option>
                <option value="Haryana">Haryana</option>
              </select>
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes["col-25"]}>
              <label for="description" className={classes.label}>
                Description (optional)
              </label>
            </div>
            <div className={classes["col-75"]}>
              <textarea
                id="description"
                name="description"
                placeholder="Write something.."
                className={classes.text}
                onChange={(e) => onChangeHandler("description", e.target.value)}
              ></textarea>
            </div>
          </div>
          {/* <div className={classes.row}>
          <h3>Total Amount: Rs {calculateTotalAmount()}</h3>
        </div> */}
          <div className={classes.row}>
            <input
              type="submit"
              value="Submit"
              className={classes["submit-btn"]}
            />
          </div>

          <div className="d-flex align-items-center justify-content-end py-3">
            <h4>
              Total Amount:{" "}
              <span style={{ color: "green", margin: "0 5px" }}>
                {" "}
                ₹{totalGSTAmount}
              </span>
              <b>(including 18% GST)</b>
            </h4>
          </div>
          {/* <div className="d-flex align-items-center justify-content-end py-3">
          <h5>
            {" "}
            <b>Total Price (including GST):</b>
            <span style={{ color: "green", margin: "0 5px" }}>
              ₹
              {Math.floor(
                ((totalAmount / 100) * 18 + totalAmount) * totalNoOfDays
              )}
            </span>
          </h5>
        </div> */}
        </form>
      </div>
    </>
  );
}

export default MaaliForm;
