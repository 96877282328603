import React from "react";
import Image from "../../assets/maali.png";
import classes from "./ServiceCommon.module.css";
import { Link } from "react-router-dom";

const MaaliService = () => {
  return (
    <div className={classes.parentContainer}>
      <div className={classes.infoContainer}>
        <h1>MAALI/Gardener AT your Door-step</h1>
        <p className={classes.startingPrice} style={{ margin: "15px 0" }}>
          Starting from &#8377; 249
        </p>
        <p>
          While indoor plants don’t demand walks and check-ups, they do require
          prudent care. First-time plant parents might feel flustered at the
          sight of a yellow leaf and grey twig, and that’s completely OKAY!
        </p>
        <p>
          {" "}
          Chaperone is here to provide you with the professional mali service in
          Delhi-NCR you need to keep your plants healthy AND happy.
        </p>
        <p>
          Hiring a maali/ Gardener in Delhi NCR means getting complete nurturing
          and care for - indoor plants, planters, potted plants, small gardens,
          kitchen gardens and office plants too!.
        </p>

        <ul>
          <li>
            Book maali service for your Delhi-NCR home garden or plants at any
            time from our website.
          </li>
          <li>
            From pruning to pest control, our malis are pros at keeping plants
            green!.
          </li>
          <li>
            Your plants will receive all the pampering they deserve including
            fertilization and repotting.
          </li>
        </ul>

        <div className={classes.actions_btns}>
          <Link to="/maali_form">
            <button className={classes["book-your-slot_btn"]}>
              Book your slot now
            </button>
          </Link>
        </div>
      </div>
      <div className={classes.mediaContainer}>
        <img src={Image} alt="" />
      </div>
    </div>
  );
};

export default MaaliService;
