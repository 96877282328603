import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../global-variables";
import { updateLength } from "../store/LoggedInUser";
import { updateLengthNonLoggedInUser } from "../store/NonLoggedInUser";

export const postApiRequest = async (url, data, headers) => {
  try {
    const response = await axios.post(url, data,headers);
    return response.data;
  } catch (err) {
    // throw err;
  }
};

export const modifyDate = (date) => {
  const inputDate = new Date(date);

  const day = inputDate.getDate().toString().padStart(2, "0");
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear();

  return `${day}-${month}-${year}`;
};

// to change date format from YYYY-MM-DD to DD-MM-YYYY

export function changeDateFormat(inputDate) {
  const parts = inputDate.split("-");
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  // Create a new date object with the parts in the desired order
  const newDate = new Date(`${month}-${day}-${year}`);

  // Get the day, month, and year from the new date object
  const newDay = newDate.getDate();
  const newMonth = newDate.getMonth() + 1; // Months are 0-indexed
  const newYear = newDate.getFullYear();

  // Pad the day and month with leading zeros if needed
  const formattedDay = String(newDay).padStart(2, "0");
  const formattedMonth = String(newMonth).padStart(2, "0");

  // Return the formatted date string
  return `${formattedDay}-${formattedMonth}-${newYear}`;
}

// get today+1 date

export function getNextDate() {
  const today = new Date();
  const nextDay = new Date(today);
  nextDay.setDate(today.getDate() + 1);

  let nextDayMonth = nextDay.getMonth() + 1;
  if (nextDayMonth < 10) nextDayMonth = `0${nextDayMonth}`;
  let nextDayDate = nextDay.getDate();
  if (nextDayDate < 10) nextDayDate = `0${nextDayDate}`;

  const nextDayFormatted = `${nextDay.getFullYear()}-${nextDayMonth}-${nextDayDate}`;

  return nextDayFormatted;
}

// toast

export const showCartToast = () =>
  toast.success("Added successfully!", {
    position: "bottom-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const onAddItemToCart = async (
  userId,
  isUserLoggedIn,
  dispatch,
  product,
  showToast = true,
  temp = "notcart"
) => {
  if (showToast) {
    showCartToast();
  }

  // for unloggedIn user

  if (!isUserLoggedIn) {
    const cartId = localStorage.getItem("cartId");
    // when cart does not exist, adding the first item
    if (!cartId) {
      let plantId = temp === "cart" ? product.plantId._id : product._id;
      const response = await postApiRequest(
        `${API_URL}/shop/add-plant-to-cart`,
        {
          plantId,
        }
      );
      console.log("getting cartId", response);

      localStorage.setItem("cartId", response.cart._id);
      dispatch(updateLengthNonLoggedInUser(response.cartlength));
    } else {
      let plantId = temp === "cart" ? product.plantId._id : product._id;
      const response = await postApiRequest(
        `${API_URL}/shop/add-plant-to-cart`,
        {
          plantId,
          cartId: cartId,
        }
      );

      console.log("adding item to existing cart in server", response);
      dispatch(updateLengthNonLoggedInUser(response.cartlength));
    }
  }

  // for loggedIn user
  if (isUserLoggedIn) {
    console.log("sending request to api to add plant to cart ");
    let plantId = temp === "cart" ? product.plantId._id : product._id;
    const response = await postApiRequest(`${API_URL}/shop/add-plant-to-cart`, {
      plantId: plantId,
      userId: userId,
    });

    console.log("response on add item", response);
    dispatch(updateLength(response.cartlength));
  }

  // console.log("user logged in", isUserLoggedIn);
  return isUserLoggedIn;
};

// export const postApiRequest = async (url, data, setError) => {
//   try {
//     const response = await axios.post(url, data);
//     return response.data;
//   } catch (err) {
//     console.log(
//       "-----------------------------------------------------",
//       err.message
//     );
//     if (err.response) {
//       setError(err.response.data.message);
//       // alert(err.response.data.message);
//       return;
//     } else {
//       // alert("Something went wrong!!");
//       setError("Something went wrong!");
//       return;
//     }
//   }
// };
