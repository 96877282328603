import React from "react";
import ReactDOM from "react-dom/client";

// redux configuration
import { configureStore } from "@reduxjs/toolkit";

import { Provider } from "react-redux";
import NonLoggedInUser from "./store/NonLoggedInUser";
import LoggedInUser from "./store/LoggedInUser";

import "./index.css";
import App from "./App";

const store = configureStore({
  reducer: {
    NonLoggedInUser,
    LoggedInUser,
  },
  // reducer: NonLoggedInUser,
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
