import React from "react";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

const Settings = () => {
  return (
    <AdminPanelWrapper>
      <div>Settings...</div>
    </AdminPanelWrapper>
  );
};

export default Settings;
