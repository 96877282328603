import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useSearchParams, Link, useParams } from "react-router-dom";

const ServiceSuccess = () => {
  const { total, serviceType } = useParams();
  console.log("type", serviceType);
  return (
    <Box boxShadow="dark-lg" p="6" rounded="md" bg="white">
      <VStack h="100vh" justifyContent={"center"}>
        <Heading textTransform={"uppercase"}>
          {serviceType === "maali"
            ? "Your maali has been booked successfully. Our team will get back to you shortly"
            : "Your Plant-pickup has been scheduled successfully. Our team will get back to you shortly"}
        </Heading>
        <h4>
          Total Amount: <span style={{ color: "green" }}>₹ {total}</span>
        </h4>

        <Link to="/">
          <button type="button" className="btn btn-success">
            Go Back To Home
          </button>
        </Link>
      </VStack>
    </Box>
  );
};

export default ServiceSuccess;
