import React,{useState,useEffect} from 'react';
import AdminPanelWrapper from './utils/AdminPanelWrapper'
import { useNavigate} from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../global-variables';

import classes from "./Common.module.css";
import styles from './Services.module.css'

const Payments = () => {
    const [payments,setPayments]=useState([])
  const navigate = useNavigate();


  // -----------------------------------------------------
    const ux = localStorage.getItem("ux");
  // get payments

  useEffect(()=>{
    const getPayments=async()=>{
      try {
                if (!ux) {
                  navigate("/admin/login");
                  return;
        }
                const headers = {
                  Authorization: ux,
                };
        
        const response= await axios.get(`${API_URL}/admin/get-payments`,{headers});


        console.log('P',response)
        setPayments(response.data.payment)

     
      }catch(err){
        console.log('ERROR',err);
        alert(err.message)
      }
    }
    getPayments()
  },[])


  return (

      <AdminPanelWrapper>
      <div className={styles.main_container}>
        <div className={classes["report-container"]}>
            <div className={classes["report-header"]}>
              <h1 className={classes["recent-Articles"]}>Recent Payments</h1>
              <button className={classes.view}>View All</button>
            </div>

            
            <div className={classes.services_data_container}>
            <table>
              <tr>
                <th>PaymentId</th>
                <th>UserId</th>
                <th>OrderId</th>
                <th>RazorPay Payment ID</th>


              </tr>

              {!payments && <h4>Loading...</h4>}

              {payments && payments.map((payment)=>(
                <tr>
                <td>{payment._id}</td>
                <td>{payment.user}</td>
                <td>{payment.order}</td>
                <td>{payment.razorpay_payment_id}</td>


              </tr>
              ))}
              



            </table>
            </div>
          </div>
      </div>
      </AdminPanelWrapper>

  )
}

export default Payments