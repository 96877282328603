import React, { useState, useRef } from "react";
import classes from "./Common.module.css";
import logo from "../assets/logo.png";

// import CancelOrderImg from "../../assets/cancelled.png";
import CancelOrderImg from "../assets/cancelled.png";
import { Link } from "react-router-dom";
import { modifyDate } from "../util/helper";

import { API_URL } from "../global-variables";
import useApi from "../hooks/useapi";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

function AdminMenu() {
  // -----------------------------------------------------
  // get orders

  const { data, isLoading } = useApi(`${API_URL}/admin/get-orders`,true);

  if (data.orders) {
    data.orders.forEach((order) => {
      order.date = modifyDate(order.createdAt);
    });
  }

  return (
    <>
 
      <AdminPanelWrapper>
        <div className={classes.main}>
          <div className={classes["box-container"]}>
            <div className={`${classes.box} ${classes.box1}`}>
              <div className={classes.text}>
                <h2 className={classes["topic-heading"]}>60.5k</h2>
                <h2 className={classes.topic}>Total Orders</h2>
              </div>

              <img
                src="https://media.geeksforgeeks.org/wp-content/uploads/20221210184645/Untitled-design-(31).png"
                alt="Views"
              />
            </div>

            <div className={`${classes.box} ${classes.box2}`}>
              <div className={classes.text}>
                <h2 className={classes["topic-heading"]}>150</h2>
                <h2 className={classes.topic}>Completed Orders</h2>
              </div>

              <img
                src="https://media.geeksforgeeks.org/wp-content/uploads/20221210185030/14.png"
                alt="likes"
              />
            </div>

            <div className={`${classes.box} ${classes.box3}`}>
              <div className={classes.text}>
                <h2 className={classes["topic-heading"]}>320</h2>
                <h2 className={classes.topic}>Pending Orders</h2>
              </div>

              <img
                src="https://media.geeksforgeeks.org/wp-content/uploads/20221210184645/Untitled-design-(32).png"
                alt="comments"
              />
            </div>

            <div className={`${classes.box} ${classes.box4}`}>
              <div className={classes.text}>
                <h2 className={classes["topic-heading"]}>70</h2>
                <h2 className={classes.topic}>Cancelled Orders</h2>
              </div>

              <img src={CancelOrderImg} alt="Completed" />
            </div>
          </div>

          <div className={classes["report-container"]}>
            <div className={classes["report-header"]}>
              <h1 className={classes["recent-Articles"]}>Recent Orders</h1>
              {/* <button className={classes.view}>View All</button> */}
            </div>

            <div className={classes.services_data_container}>
              <table>
                <tr>
                  <th>Order Id</th>
                  <th>Order Date</th>
                  <th>Order Value</th>
                  <th> Status</th>
                </tr>

                {isLoading && <h3>Loading...</h3>}
                {data.orders &&
                  data.orders.map((order) => (
                    <tr>
                      <td>{order._id}</td>
                      <td>{order.date}</td>

                      <td>{order.orderValue}</td>
                      <td>{order.status}</td>
                      <td>
                        <Link to={`orders/${order._id}`}>
                          <button type="button" className="btn btn-success">
                            View Order
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
        </div>
      </AdminPanelWrapper>
  
    </>
  );
}

export default AdminMenu;
