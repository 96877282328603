import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

import classes from "../Common.module.css";
const AdminHeader = (props) => {
  return (
    <header className={classes.header}>
      <div className={classes.logosec}>
        <Link to="/admin" className={classes.logo}>
          <img src={logo} alt="logo" />
        </Link>
        <img
          src="https://media.geeksforgeeks.org/wp-content/uploads/20221210182541/Untitled-design-(30).png"
          className={`${classes.icn} ${classes.menuicn}`}
          id="menuicn"
          alt="menu-icon"
          onClick={props.onMenuToggle}
        />
      </div>

      {/* <div className={classes.searchbar}>
          <input type="text" placeholder="Search" />
          <div className={classes.searchbtn}>
            <img
              src="https://media.geeksforgeeks.org/wp-content/uploads/20221210180758/Untitled-design-(28).png"
              className={`${classes.icn} ${classes.srchicn}`}
              alt="search-icon"
            />
          </div>
        </div> */}

      <div className={classes.message}>
        <div className={classes.circle}></div>
        <img
          src="https://media.geeksforgeeks.org/wp-content/uploads/20221210183322/8.png"
          className={classes.icn}
          alt=""
        />
        <div className={classes.dp}>
          <img
            src="https://media.geeksforgeeks.org/wp-content/uploads/20221210180014/profile-removebg-preview.png"
            className={classes.dpicn}
            alt="dp"
          />
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
