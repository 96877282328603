import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams,useNavigate } from "react-router-dom";
import { postApiRequest } from "../util/helper";
import { API_URL } from "../global-variables";
import AdminPanelWrapper from "./utils/AdminPanelWrapper";

import classes from "./Common.module.css";
const EditNuseryDetails = () => {
  const { nurseryId } = useParams();
  const navigate = useNavigate()

  const [updatedData, setUpdatedData] = useState({
    _id: "",
    name: "",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      pincode: "",
    },
    contactPerson: {
      name: "",
      email: "",
      phone: "",
    },
  });

  const ux = localStorage.getItem("ux");

  useEffect(() => {
    const getNursery = async () => {
      try {
        if (!ux) {
          navigate('/admin/login')
          return;
        }

        const headers = {
          "Authorization": ux,
        };
        const response = await axios.get(
          `${API_URL}/admin/get-nursery/${nurseryId}`,{headers}
        );

        setUpdatedData(response.data.nursery);
        console.log(updatedData);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    getNursery();
  }, [nurseryId]);

  const onChangeHandler = (type, val) => {
    if (type === "name") {
      setUpdatedData((prev) => {
        return {
          ...prev,
          name: val,
        };
      });
    }
    if (type === "phone") {
      setUpdatedData((prev) => {
        return {
          ...prev,
          phone: val,
        };
      });
    }
    if (type === "adressLine") {
      setUpdatedData((prev) => {
        const updateState = { ...prev };
        updateState.address.addressLine = val;
        return {
          ...updateState,
        };
      });
    }
    if (type === "state") {
      setUpdatedData((prev) => {
        const updateState = { ...prev };
        updateState.address.state = val;
        return {
          ...updateState,
        };
      });
    }
    if (type === "city") {
      setUpdatedData((prev) => {
        const updateState = { ...prev };
        updateState.address.city = val;
        return {
          ...updateState,
        };
      });
    }
    if (type === "pincode") {
      setUpdatedData((prev) => {
        const updateState = { ...prev };
        updateState.address.pincode = val;
        return {
          ...updateState,
        };
      });
    }
    if (type === "personName") {
      setUpdatedData((prev) => {
        const updateState = { ...prev };
        updateState.contactPerson.name = val;
        return {
          ...updateState,
        };
      });
    }
    if (type === "personEmail") {
      setUpdatedData((prev) => {
        const updateState = { ...prev };
        updateState.contactPerson.email = val;
        return {
          ...updateState,
        };
      });
    }
    if (type === "personPhone") {
      setUpdatedData((prev) => {
        const updateState = { ...prev };
        updateState.contactPerson.phone = val;
        return {
          ...updateState,
        };
      });
    }
  };

  const updateNurseryDetails = async () => {
    const data = {
      name: updatedData.name,
      phone: updatedData.phone,
      adressLine: updatedData.address.addressLine,
      state: updatedData.address.state,
      city: updatedData.address.city,
      pincode: updatedData.address.pincode,
      personName: updatedData.contactPerson.name,
      personPhone: updatedData.contactPerson.phone,
      personEmail: updatedData.contactPerson.email,

      nurseryId: updatedData._id,
    };
            if (!ux) {
              navigate("/admin/login");
              return;
    }
        const headers = {
          Authorization: ux,
        };
    const res = await postApiRequest(`${API_URL}/admin/edit-nursery`, data, {headers});
    console.log(res);
    if (res.nursery) {
      alert("Nursery updated successfully");
    }
  };

  return (
    <AdminPanelWrapper>
      <div className={classes.main}>
        {updatedData && (
          <div
            // style={{ border: "2px solid" }}
            className="p-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded"
            key={updatedData._id}
          >
            <div className="d-flex justify-content-evenly ">
              <div>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  value={updatedData.name}
                  id="name"
                  onChange={(e) => onChangeHandler("name", e.target.value)}
                />
              </div>

              <p> Id: {updatedData._id}</p>
            </div>
            <div className="d-flex justify-content-around m-5">
              <div>
                <h5>total Listed plants : {updatedData.totalListedPlants}</h5>
                <h5>total Listed pots : {updatedData.totalListedPots}</h5>
              </div>

              <div>
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  value={updatedData.phone}
                  id="phone"
                  onChange={(e) => onChangeHandler("phone", e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex justify-content-around m-5">
              <div>
                <h5>Address</h5>
                <label htmlFor="city">Address Line</label>
                <input
                  type="text"
                  value={updatedData.address.addressLine}
                  id="city"
                  onChange={(e) =>
                    onChangeHandler("adressLine", e.target.value)
                  }
                />
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  value={updatedData.address.city}
                  id="city"
                  onChange={(e) => onChangeHandler("city", e.target.value)}
                />
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  value={updatedData.address.state}
                  id="state"
                  onChange={(e) => onChangeHandler("state", e.target.value)}
                />
                <label htmlFor="pincode">Pincode</label>
                <input
                  type="text"
                  value={updatedData.address.pincode}
                  id="pincode"
                  onChange={(e) => onChangeHandler("pincode", e.target.value)}
                />
              </div>
              <div>
                <h5>Contact Person details</h5>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  value={updatedData.contactPerson.name}
                  id="name"
                  onChange={(e) =>
                    onChangeHandler("personName", e.target.value)
                  }
                />
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  value={updatedData.contactPerson.email}
                  id="email"
                  onChange={(e) =>
                    onChangeHandler("personEmail", e.target.value)
                  }
                />

                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  value={updatedData.contactPerson.phone}
                  id="phone"
                  onChange={(e) =>
                    onChangeHandler("personPhone", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="d-flex justify-content-around">
              <button
                type="button"
                className="btn btn-success btn-lg"
                onClick={updateNurseryDetails}
              >
                Update
              </button>
            </div>
          </div>
        )}
      </div>
    </AdminPanelWrapper>
  );
};

export default EditNuseryDetails;
