import React, { useState } from "react";
import classes from "./MaaliForm.module.css";

const RentPlantForm = () => {
  const [formData, setFormData] = useState({
    plant: "",
    date: "",
    noOfDays: "",
    locality: "",
    pincode: "",
    city: "",
    state: "",
    description: "",
  });

  const onChangeHandler = (type, value) => {
    if (type === "plant") {
      setFormData((prev) => {
        return {
          ...prev,
          plant: value,
        };
      });
    }
    if (type === "date") {
      setFormData((prev) => {
        return {
          ...prev,
          date: value,
        };
      });
    }
    if (type === "noOfDays") {
      setFormData((prev) => {
        return {
          ...prev,
          noOfDays: value,
        };
      });
    }
    if (type === "locality") {
      setFormData((prev) => {
        return {
          ...prev,
          locality: value,
        };
      });
    }
    if (type === "pincode") {
      setFormData((prev) => {
        return {
          ...prev,
          pincode: value,
        };
      });
    }
    if (type === "city") {
      setFormData((prev) => {
        return {
          ...prev,
          city: value,
        };
      });
    }
    if (type === "state") {
      setFormData((prev) => {
        return {
          ...prev,
          state: value,
        };
      });
    }
    if (type === "description") {
      setFormData((prev) => {
        return {
          ...prev,
          description: value,
        };
      });
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();

    console.log("form submitted", formData);
  };

  return (
    <div className={classes.container}>
      <form onSubmit={(e) => formSubmitHandler(e)}>
        <div className={classes.row}>
          <div className={classes["col-25"]}>
            <label for="plant" className={classes.label}>
              Choose a plant
            </label>
          </div>
          <div className={classes["col-75"]}>
            <select
              className={classes.select}
              id="floatingSelectGrid"
              onChange={(e) => onChangeHandler("plant", e.target.value)}
              required
            >
              <option value="none">Select Option</option>
              <option value="plant1">Plant1</option>
              <option value="plant2">Plant2</option>
              <option value="plant3">Plant3</option>
            </select>
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes["col-25"]}>
            <label for="date" className={classes.label}>
              Date
            </label>
          </div>
          <div className={classes["col-75"]}>
            <input
              type="date"
              className={classes.text}
              id="date"
              name="date"
              onChange={(e) => onChangeHandler("date", e.target.value)}
              required
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes["col-25"]}>
            <label for="noofdays" className={classes.label}>
              No of days
            </label>
          </div>
          <div className={classes["col-75"]}>
            <select
              id="noofdays"
              name="noofdays"
              className={classes.select}
              onChange={(e) => onChangeHandler("noOfDays", e.target.value)}
              required
            >
              <option value="none">Select Option</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes["col-25"]}>
            <label for="locality" className={classes.label}>
              Locality
            </label>
          </div>
          <div className={classes["col-75"]}>
            <input
              type="text"
              id="locality"
              name="locality"
              className={classes.text}
              onChange={(e) => onChangeHandler("locality", e.target.value)}
              placeholder="Locality.."
              required
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes["col-25"]}>
            <label for="pin" className={classes.label}>
              Pincode
            </label>
          </div>
          <div className={classes["col-75"]}>
            <input
              type="number"
              id="pin"
              name="pin"
              className={classes.text}
              placeholder="208020"
              onChange={(e) => onChangeHandler("pincode", e.target.value)}
              required
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes["col-25"]}>
            <label for="city" className={classes.label}>
              City
            </label>
          </div>
          <div className={classes["col-75"]}>
            <input
              type="text"
              id="city"
              name="city"
              className={classes.text}
              onChange={(e) => onChangeHandler("city", e.target.value)}
              placeholder="city.."
              required
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes["col-25"]}>
            <label for="state" className={classes.label}>
              State
            </label>
          </div>
          <div className={classes["col-75"]}>
            <select
              id="state"
              name="state"
              className={classes.select}
              onChange={(e) => onChangeHandler("state", e.target.value)}
              required
            >
              <option value="none">Select Option</option>
              <option value="up">Uttar Pradesh</option>
              <option value="ap">Andhra Pradesh</option>
            </select>
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes["col-25"]}>
            <label for="description" className={classes.label}>
              Description
            </label>
          </div>
          <div className={classes["col-75"]}>
            <textarea
              id="description"
              name="description"
              placeholder="Write something.."
              className={classes.text}
              onChange={(e) => onChangeHandler("description", e.target.value)}
              required
            ></textarea>
          </div>
        </div>
        {/* <div className={classes.row}>
          <h3>Total Amount: Rs {calculateTotalAmount()}</h3>
        </div> */}
        <div className={classes.row}>
          <input
            type="submit"
            value="Submit"
            className={classes["submit-btn"]}
          />
        </div>
      </form>
    </div>
  );
};

export default RentPlantForm;
